/**
 * 时间处理
 * @author wzwwu
 */
import moment from 'moment'

/**
 * 格式化时间, 适用于只选择日期，没有让用户选择时分秒的情况。
 * @param time 时间
 * @param cut 不传: 直接格式化; 'start': 当天开始; 'end': 当天结束
 */
export const formatTime = (time: moment.Moment, cut?: 'start' | 'end') => {
  if (!time) return undefined
  if (!cut) return moment(time).format('YYYY-MM-DD HH:mm:ss')
  if (cut === 'start') return moment(time).startOf('day').format('YYYY-MM-DD HH:mm:ss')
  if (cut === 'end') return moment(time).endOf('day').format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 获取不早于当前时刻的禁选时刻参数
 * @param date 当前选择的时间
 */
export const getBeforeDisabledTime = (date: moment.Moment) => {
  const current = moment()

  const curHour = current.hours()
  const curMinute = current.minutes()
  const curSecond = current.seconds()

  const isCurHour = date?.hours?.() === curHour
  const isCurMinute = date?.minutes?.() === curMinute

  return {
    disabledHours: () => moment(current)?.isBefore(date) ? [] : range(0, curHour),
    disabledMinutes: () => isCurHour
      ? range(0, curMinute)
      : [],
    disabledSeconds: () => isCurHour && isCurMinute
      ? range(0, curSecond)
      : [],
  }
}

/**
 * 用于获取禁用时、分、秒范围
 * @param start 起始数
 * @param end 结束数
 */
const range = (start: number, end: number): number[] => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

export interface Timezone {
  s: string,
  e: string

}

/**
 * 多个时间段是否重叠 https://blog.csdn.net/qq_29050279/article/details/105395844
 * 用法:

 let dateAr = [
 { s: '01:00', e: '14:08' },
 { s: '01:04', e: '02:05' },
 { s: '05:21', e: '08:00' },
 { s: '10:20', e: '12:08' },
 ]
 console.log(Fn()) //true的话表示没有重叠，false表示有重叠
 */
export function isOverlap (timezoneArr: Timezone[]): boolean {
  for (const k in timezoneArr) {
    if (!judege(k, timezoneArr)) {
      return false
    }
  }
  return true
}
function judege (idx, dateAr) {
  for (const k in dateAr) {
    if (idx !== k) {
      if (dateAr[k].s <= dateAr[idx].s && dateAr[k].e >= dateAr[idx].s) {
        return false
      }
      if (dateAr[k].s < dateAr[idx].e && dateAr[k].e >= dateAr[idx].e) {
        return false
      }
    }
  }
  return true
}
type ParseDurationType = Parameters<typeof moment.duration>

/**
 * 将一段时间转化为XX天XX分XX秒的格式
 * @param num 同moment.duration的第一个参数
 * @param unit 同moment.duration的第二个参数，它默认是毫秒
 */
export function parseDuration (num?: ParseDurationType[0], unit?: ParseDurationType[1]): string {
  // 这里配置的单位都可以随意增减。注意增减必须从首尾连续，不能只去掉中间的一个，否则实际时长会出错
  const arr = [
    // ['years', '年'],
    // ['months', '月'],
    ['days', '天'],
    ['hours', '小时'],
    ['minutes', '分'],
    ['seconds', '秒'],
  ] as const

  const duration = moment.duration(num, unit)
  return arr.reduce((preText, currValue) => {
    const [value, text] = currValue
    const temp = !preText ? Math.floor(duration.as(value)) : duration.get(value)
    if (temp === 0 && !preText) return preText
    return preText + temp + text
  }, '') || '0' + arr[arr.length - 1][1]
}

/**
 * 判断是否是今天
 * @param dateString 日期字符串
 * @returns 是否是今天
 */
export function isToday (dateString: string) {
  // 将入参转换为 Date 对象
  const inputDate = new Date(dateString)

  // 获取当前时间
  const now = new Date()

  // 比较年、月、日是否相同
  return (
    inputDate.getFullYear() === now.getFullYear()
    && inputDate.getMonth() === now.getMonth()
    && inputDate.getDate() === now.getDate()
  )
}
