/**
 * 任务管理接口
 * @author wzwwu
 */
import { isEmptyWithCustom, requestApi, getInterfaceName, requestApiV2 } from '@library'
import {
  TaskFilter,
  CommonList,
  UpdateNotHandleTasksPayload,
  AllTaskListItem,
  AllTaskFilter,
  TaskType,
  FollowType,
  GoalParam,
  GoalFilter,
  Goal,
  IndicatorFilter,
  Indicator,
  IndicatorCustomerFilter,
  IndicatorCustomer, TaskListByCidFilter, TaskListDetail, TaskNameListFilter, TaskNameItem, RenewStatisticsFilterType,
} from '@types'
import { get } from 'lodash'

// 获取任务列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001799559
export function getAllTaskList (filter: AllTaskFilter) {
  return requestApiV2<CommonList<AllTaskListItem>>(getInterfaceName('/trpc.tcc.tcc_main.Task/GetTaskList', filter), filter)
}

// 获取任务名称 http://opc-mock.woa.com/project/530/interface/api/5164
export function getTaskNameList (filter: TaskNameListFilter) {
  return requestApiV2<CommonList<TaskNameItem>>(getInterfaceName('/trpc.tcc.tcc_main.Task/GetTaskNameList', filter), filter)
}

// 设置目标 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002164411
export function setGoal (param: GoalParam) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Goal/SetGoal', param)
}

// 查询目标
export function getGoal (filter: GoalFilter) {
  const { relatedId } = filter
  return requestApiV2<CommonList<Goal>>('/trpc.tcc.tcc_main.Goal/GetGoalList', { ...filter, relatedId: relatedId && [relatedId] })
}

// 下载待处理任务列表
export function exportSalesNotHandleCustomerList (filter: TaskFilter) {
  return requestApi<{}>('ExportSalesNotHandleCustomerList', filter)
}

// 下载任务列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001801419
export function exportAllTaskList (filter: AllTaskFilter) {
  return requestApi<CommonList<{}>>('ExportTaskList', filter)
}

// 获取任务类型
export function getTaskType () {
  return requestApi<TaskType[]>('GetTaskType')
}

// 批量处理未完成任务
export function updateNotHandleTasks (payload: UpdateNotHandleTasksPayload) {
  return requestApi<{}>('UpdateNotHandleTasks', payload)
}

// 获取跟进方式 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002098701
export function getFollowType () {
  return requestApiV2<FollowType[]>('/trpc.tcc.tcc_main.FollowFormItem/GetFollowType')
}

// 重点线索指标（今日） http://opc-mock.woa.com/project/530/interface/api/3367
export function getLeadIndicatorNumToday (filter: IndicatorFilter) {
  return requestApiV2<(Indicator & {batchId: number})[]>('/trpc.tcc.tcc_main.Metric/GetLeadIndicatorNum', { ...filter, type: 'today' })
}

// 重点线索指标（历史） http://opc-mock.woa.com/project/530/interface/api/3367
export function getLeadIndicatorNumHistory (filter: IndicatorFilter) {
  return requestApiV2<(Indicator & {batchId: number})[]>('/trpc.tcc.tcc_main.Metric/GetLeadIndicatorNum', { ...filter, type: 'history' })
}

// 销售机会指标 http://opc-mock.woa.com/project/530/interface/api/3369
export function getOpportunityCustomerNum (filter: IndicatorFilter) {
  return requestApiV2<Indicator[]>('/trpc.tcc.tcc_main.Metric/GetOpportunityCustomerNum', filter)
}

// 待处理任务指标 http://opc-mock.woa.com/project/530/interface/api/3368
export function getNotHandleTaskCustomerNum (filter: IndicatorFilter) {
  return requestApiV2<Indicator[]>('/trpc.tcc.tcc_main.Metric/GetNotHandleTaskCustomerNum', filter)
}

// 待处理线索指标 http://opc-mock.woa.com/project/530/interface/api/3416
export function getNotHandleLeadCustomerNum (filter: IndicatorFilter) {
  return requestApiV2<Indicator[]>('/trpc.tcc.tcc_main.Metric/GetNotHandleLeadCustomerNum', filter)
}

// 新客户指标 http://opc-mock.woa.com/project/530/interface/api/3371
export function getSpecifiedBatchLeadNum (filter: IndicatorFilter) {
  return requestApiV2<Indicator[]>('/trpc.tcc.tcc_main.Metric/GetSpecifiedBatchLeadNum', filter)
}

// 复呼任务指标 http://opc-mock.woa.com/project/530/interface/api/3370
export function getPredictFollowCustomerNum (filter: IndicatorFilter) {
  return requestApiV2<Indicator[]>('/trpc.tcc.tcc_main.Metric/GetPredictFollowCustomerNum', filter)
}

// 获取座席工作台指标客户数据 http://opc-mock.woa.com/project/530/interface/api/3379
export function getIndicatorCustomerList (filter: IndicatorCustomerFilter) {
  return requestApiV2<CommonList<IndicatorCustomer>>(getInterfaceName('/trpc.tcc.tcc_main.Metric/GetIndicatorCustomerList', filter), filter)
}

// 下载座席工作台指标客户数据 http://opc-mock.woa.com/project/530/interface/api/3379
export function exportIndicatorCustomerList (filter: IndicatorCustomerFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Metric/ExportIndicatorCustomerList', filter)
}

// 获取客户任务 http://opc-mock.woa.com/project/530/interface/api/3381
export function getTaskListByCid (filter: TaskListByCidFilter) {
  return requestApiV2<CommonList<TaskListDetail>>(getInterfaceName('/trpc.tcc.tcc_main.Task/GetTaskListByCid', filter), filter)
}

/**
 *  检查cid，如果没有cid则不调用接口，这个通常是因为业务中使用useService等hooks，这些hooks没法做到 检查参数条件是否满足而跳过hooks更新
 *  */
export function getTaskListByCidWithCheck (...params: Parameters<typeof getTaskListByCid>) {
  if (isEmptyWithCustom(get(params, '[0].cid'))) {
    return Promise.resolve([[], null]) as ReturnType<typeof getTaskListByCid>
  }
  return getTaskListByCid(...params)
}

export function getCustomerRenewStatistics (filter: RenewStatisticsFilterType) {
  return requestApiV2<{renewAmt: number, renewCustomerNum: number}>('trpc.tcc.tcc_main.Metric/GetCustomerRenewStatistics', filter)
}
