/**
 * 类似于对接了OCS的上传组件。 后端会给前端一个临时签名，前端拿到签名之后配置cos对象，然后在上传
 * 临时签名是一次性的，每次都要重新new COS对象
 * 详情请参考文档： https://cloud.tencent.com/document/product/436/11459
 * @author tylerzzheng
 */
import React, { PropsWithChildren, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import { DraggerProps } from 'antd/lib/upload/Dragger'
import { RcFile } from 'antd/lib/upload/interface'
import { cosDeleteObjectUrl, cosGetObjectUrl, cosPutObject } from '../../library/cosUtils'
import downloadByATag from '../../library/downloadByATag'
import { isFunction } from 'lodash'

const { Dragger } = Upload

interface CosUploadProps extends Omit<DraggerProps, 'onChange'>{
  isShowUploadTemplate?: boolean
  // 文件大小， 单位mb
  size: number
  /**
   * 在upload组件o触发nChange的时候触发回调，得到文件在COS的key值列表以及所有的FIle列表
   * 注意这里的文件列表的状态都是上传完成的
   */
  onChange?: (keys: string[], files: File[]) => void
}

interface FileWithUid extends File{
  uid: string
}

const getFileKey = (file: FileWithUid): string => file?.uid + '/' + file?.name

const CosUpload: React.FC<PropsWithChildren<CosUploadProps>> = (props) => {
  const { size, onChange: onFileChange, children, isShowUploadTemplate = false, ...draggerProps } = props
  const [fileList, updateFileList] = useState([])

  const prop: DraggerProps = {
    multiple: true,
    ...draggerProps,
    onChange: async (info) => {
      console.log('onChange', info)

      const { file, fileList } = info
      const { status } = file
      if (status === 'uploading') {
        // message.info(`文件${file.name}上传中...`) // 上传时，会多次触发，并不需要多次提醒
      }
      if (status === 'done') {
        message.success(`文件${file.name}上传成功.`)
      }
      if (status === 'error') {
        message.error(`文件${file.name}上传失败`)
      }
      if (status === 'removed') {
        const err = await cosDeleteObjectUrl(getFileKey(file as RcFile))
        if (err) {
          message.error(`文件${file.name}删除失败`)
        } else {
          message.success(`文件${file.name}删除成功.`)
        }
      }
      updateFileList(fileList?.filter(f => !!f.status))
      const uploadedFile = fileList?.filter(f => f.status === 'done')
      if (isFunction(onFileChange)) onFileChange(uploadedFile.map(f => getFileKey(f as RcFile)), uploadedFile)
    },
    beforeUpload: (file: RcFile) => {
      if (file.size > size * 1024 * 1024) {
        message.error(`当前文件过大,请保证文件大小不超过${size}MB`)
        return false
      }
      return true
    },
    onDownload: async file => {
      const [url, err] = await cosGetObjectUrl(getFileKey(file as RcFile)) // 这里的key要和cosPutObject时的key匹配
      if (err) {
        message.error(`下载失败: ${err.message}`)
      } else {
        downloadByATag(file.name, url)
      }
    },
    customRequest: async (options) => {
      const { file, onProgress, onError, onSuccess } = options
      const [data, err] = await cosPutObject(file as RcFile, percent => onProgress({ percent }), getFileKey(file as RcFile))
      if (err) {
        message.error(`上传失败: ${err.message}`)
        onError(err)
      } else {
        onSuccess(data)
      }
    },
    fileList,
  }

  const downloadUploadTemplate = async () => {
    const [url, err] = await cosGetObjectUrl('/机器人批量上传创建名单模板.xlsx') // 这里的key要和cosPutObject时的key匹配
    if (err) {
      message.error(`下载失败: ${err.message}`)
    } else {
      downloadByATag('/机器人批量上传创建名单模板.xlsx', url)
    }
  }

  const renderChildren = () => {
    if (!children) {
      return (
        <>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">点击或拖拽文件到这里上传</p>
          <p className="ant-upload-hint">支持扩展{draggerProps.accept}</p>
          <p className="ant-upload-hint">单个文件最大上传不可超过{size}MB</p>
        </>)
    } else {
      return children
    }
  }
  return (
    <>
      {isShowUploadTemplate && (
        <>
          <a onClick={() => downloadUploadTemplate()}>下载客户名单模板</a>
        </>
      )}
      <Dragger {...prop}>
        {renderChildren()}
      </Dragger>
    </>
  )
}
export default CosUpload
