import React, { useEffect, useMemo } from 'react'
import Card from 'antd/lib/card/Card'
import { Row, Col, Radio, Statistic, Select, Tooltip } from 'antd'
import { CommonSelector } from '@components'
import { groupId, salesName } from 'src/components/CommonSelector/configs'
import { createForm, Form } from '@library'
import { Link } from 'react-router-dom'
import store from '@store'
import { CompanyInfo, RenewStatisticsFilterType } from '@types'
import { useService, useShowError } from '@hooks'
import { getCustomerRenewStatistics } from '@services/task'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'

const { Option } = Select

const formStore = createForm<RenewStatisticsFilterType>()
const { useValues, Field, withForm } = formStore

const CustomerBacklog = () => {
  const { userInfo, currCompanyList } = store.useSession()
  const { values, setValues, setInitialValues } = useValues()

  // 只在组件挂载时设置一次初始值
  useEffect(() => {
    setInitialValues({
      groupId: undefined,
      salesName: userInfo?.salesName,
      salesChannel: 1,
      dateRange: 'l7d',
      companyId: undefined,
    })
  }, []) // 空依赖数组，确保只执行一次

  const handleSubmit = (v: RenewStatisticsFilterType) => {
    setValues(v)
  }
  const [loading, renewRes, err] = useService(getCustomerRenewStatistics, values)
  useShowError('获取续费数据失败', err)
  // 获取公司数据
  const { authPointMap } = store.useGlobal()
  const companyList: CompanyInfo[] = useMemo(() => {
    // 选择公司： 有权限点，则可以选择当前模块下的所有公司。没有权限点，则可以选择负责的公司。都没有就看不到公司选择下拉框。
    const list = authPointMap.readAllReport?.result ? currCompanyList : currCompanyList?.filter(c => c.manager?.split(';')?.includes(userInfo.salesName))
    return list || []
  }, [authPointMap, userInfo.salesName])
  return (
    <Card title="续费待办" className="mt10">
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Row justify="start">
          {!isEmpty(companyList) && (
            <Col span={8}>
              <Field
                field="companyId" label="公司" onChange={companyId => {
                  handleSubmit({ ...values, companyId, groupId: undefined, salesName: undefined })
                }}
              >
                <Select allowClear>
                  {companyList?.map(company => {
                    return <Option key={company?.companyId} value={company?.companyId}>{company?.companyName}</Option>
                  })}
                </Select>
              </Field>
            </Col>)}
          <Col span={8}>
            <Field
              field="groupId" label="群组" onChange={groupId => {
                handleSubmit({ ...values, groupId, salesName: undefined })
              }}
            >
              <CommonSelector {...groupId} filter={{ companyId: values?.companyId ?? undefined }} />
            </Field>
          </Col>
          <Col span={8}>
            <Field
              field="salesName" label="员工" onChange={salesName => {
                handleSubmit({ ...values, salesName })
              }}
            >
              <CommonSelector {...salesName} filter={{ companyId: values?.companyId ?? undefined, groupId: values?.groupId ?? undefined }} />
            </Field>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={8}>
            <Field
              field="salesChannel" label="通路" onChange={salesChannel => {
                handleSubmit({ ...values, salesChannel })
              }}
            >
              <Radio.Group>
                <Radio.Button value={1}>电销</Radio.Button>
                <Radio.Button value={2}>官网非个人</Radio.Button>
              </Radio.Group>
            </Field>
          </Col>

          <Col span={8} offset={8}>
            <Field
              field="dateRange" label="应续费时间" onChange={dateRange => {
                handleSubmit({ ...values, dateRange })
              }}
            >
              <Radio.Group>
                <Radio.Button value="tmonth">当月</Radio.Button>
                <Radio.Button value="l7d">近7天</Radio.Button>
                <Radio.Button value="l15d">近15天</Radio.Button>
                <Radio.Button value="l30d">近30天</Radio.Button>
                <Radio.Button value="l60d">近60天</Radio.Button>
              </Radio.Group>
            </Field>
          </Col>
        </Row>
      </Form>
      <Row className="mt10">
        <Col span={12}>
          <Link to={`/customers/resource?dateRange=${values.dateRange}&renewSalesChannel=${values.salesChannel}&companyId=${values.companyId || ''}&groupId=${values.groupId || ''}&follower=${values.salesName || ''}&hasRenewProduct=1`}>
            <Statistic
              style={{ background: '#F7FAFE', marginRight: 20, padding: 10 }}
              title="应续费客户"
              value={loading ? '0' : renewRes?.renewCustomerNum}
            />
          </Link>
        </Col>
        <Col span={12}>
          <Link to={`/customers/resource?dateRange=${values.dateRange}&renewSalesChannel=${values.salesChannel}&companyId=${values.companyId || ''}&groupId=${values.groupId || ''}&follower=${values.salesName || ''}&hasRenewProduct=1`}>
            <Statistic
              style={{ background: '#F7FAFE', padding: 10 }}
              title={<>应续费金额(元)<Tooltip title='应续费金额，按照新购&续费订单"分摊结束时间"在统计时间范围内的订单，对应的订单业绩金额(现金+分成+有价代金券计收)'><QuestionCircleOutlined /></Tooltip></>}
              value={loading ? '0' : ((renewRes?.renewAmt || 0) / 100).toFixed(2)}
            />
          </Link>
        </Col>
      </Row>
    </Card>
  )
}
export default withForm(CustomerBacklog)
