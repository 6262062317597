export function injectTcccWebSDK (SdkURL: string, callback: Function) {
  // if (window.tccc) {
  //   console.warn('已经初始化SDK了，请确认是否重复执行初始化')
  //   return
  // }
  const script = document.createElement('script')
  script.setAttribute('crossorigin', 'anonymous')
  script.dataset.enableShared = 'true'
  script.dataset.disablePreloadMic = '1'
  script.src = SdkURL
  document.body.appendChild(script)
  script.addEventListener('load', () => {
    callback()
  })
}
