/**
 * 对 XLSX 文件的一些操作封装
 * @author Jason
 */
import { writeFile, utils } from 'xlsx'
import { get } from 'lodash'

export interface XlxsSheet<T = any> {
  // 表头配置
  headers: {
    title: string
    dataIndex: string
  }[]

  // 数据
  list: T[]

  // 宽度
  widthData: {wch: number}[]
}

/**
 * 导出数据到一个Excel文件中并提供下载
 * @param data
 * @param filename
 * @param sheet
 */
export function exportTable<T> (data: XlxsSheet<T>, filename = '新文档.xlsx', sheet = 'Sheet 1') {
  const { headers, list, widthData } = data

  // 表头
  const header = headers.map(cell => cell.title)

  // 处理数据
  const sheetData = list.map(line => headers.map(headCell => get(line, headCell.dataIndex)))

  const workBook = utils.book_new()
  const workSheet = utils.aoa_to_sheet([header, ...sheetData])
  workSheet['!cols'] = widthData
  utils.book_append_sheet(workBook, workSheet, sheet)

  writeFile(workBook, filename)
}
