/**
 * 登录
 * @author jasonelchen
 */
import React, { FunctionComponent, useEffect } from 'react'
import { message, Spin } from 'antd'
import { stringify } from 'qs'
import { useHistory } from 'react-router-dom'
import store from '@store'
import { moduleId, apiHostMap } from '@configs'
import { getStaffName } from '@library'
enum Environment {
  production = 'tcc-web-production', // 生产环境
  gray = 'tcc-web-gray', // 灰度环境
  pre = 'tcc-web-pre', // 预发布环境
  daily = 'tcc-web-daily', // 日发布环境
  local = 'tcc-web-local', // 本地环境
  dev = 'tcc-web-dev', // 开发环境
  others = 'tcc-web-others' // 其他环境
}
const envMap: {[key in keyof typeof apiHostMap]: Environment} = {
  // 'local.tcc.tencent.com': Environment.local,
  'local.tcc.tencent.com': Environment.local,
  'local.tcc.testsite.woa.com': Environment.local,
  'dev.tcc.tencent.com': Environment.dev,
  'tcc.testsite.woa.com': Environment.dev,
  'test.tcc.tencent.com': Environment.dev,
  'pre.tcc.tencent.com': Environment.pre,
  'tcc-pre.testsite.woa.com': Environment.pre,
  'tcc.tencent.com': Environment.production,
  'bak.tcc.tencent.com': Environment.production,
  'tcc-web-7gwart8i0811324b-1258344699.tcloudbaseapp.com': Environment.production,
}
export const Login: FunctionComponent<{ gettingAuth?: boolean }> = props => {
  const { gettingAuth = false, children } = props
  const { logged, userInfo, getUserInfo, initT3cSdk, getHotline400ConfigOnce, getTodayCallDuration } = store.useSession()
  const { push } = useHistory()

  // 拉取用户信息
  useEffect(() => {
    ;(async () => {
      try {
        const userInfo = await getUserInfo()
        try {
          const env = envMap[window.location.hostname] || Environment.production
          // eslint-disable-next-line no-unused-expressions
          window.XSY_TRACK?.init({
            uid: userInfo.id.toString(),
            islogined: true,
            env,
            staffname: getStaffName(userInfo.salesName, userInfo.salesNameCh),
          })
        } catch (e) {
          console.error('灯塔埋点初始化失败', e)
        }
        if (userInfo.useT3C) {
          getTodayCallDuration()
          await initT3cSdk({ refresh: false })
          // 如果是清洗-商机管理中台 则加载400热线相关配置
          if (userInfo.belongModule === moduleId.MID_LONG_TAIL_CLEAN_ID) {
            getHotline400ConfigOnce()
          }
        }
      } catch (e) {
        if (e.code === 401) {
          return push(`/login?${stringify({ callbackUrl: window.location.pathname + window.location.search })}`)
        }

        return message.error(`登录失败，请刷新页面重试(${e.message})`)
      }
    })()
  }, [])

  return (
    <>
      {logged && userInfo.salesName && !gettingAuth
        ? children
        : (
          <div className="main-app page-center">
            <div className="login-loading">
              <Spin spinning tip="加载中..." />
            </div>
          </div>
        )}
    </>
  )
}
