import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Row, Col, Card, InputNumber, Input, Button, Collapse, Space, message, Typography, Timeline, Select, Tag, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { v4 as uuid } from 'uuid'
import qs from 'qs'
import moment from 'moment'
import { GetQualityCheckResultAPI, AddQualityCheckResultAPI, GetQualityCheckPlanListAPI } from '@services/quality-inspection'
import { getT3CCallRecordList } from '@services/customer'
import { QualityCheckResultResType, CallRecordListFilter, QualityCheckPlanItemType, GetQualityCheckPlanListParamsType, AddQualityCheckResultParamsType } from '@types'
import { sStorage, getStaffName } from '@library'
import { useCallRecordDetail } from '../call-record/useCallRecordDetail'
import { getUserAuthPoint } from '@services/session'
import AUTH_POINT_MAP from '@configs/auth-point'
import './style.less'
import { TableIdEnum } from '@configs/table'
import { get, isEmpty, debounce, set, cloneDeep, sortBy } from 'lodash'

const { menuTaskCallScoreU } = AUTH_POINT_MAP
// audio中进度条左侧的长度 - 固定的，不会随着audio容器的宽度变化
const sliderLeftWidth = 132
// audio中进度条右侧的长度 - 固定的，不会随着audio容器的宽度变化
const sliderRightWidth = 90
const tabWidth = 76

const ScoreDetailPage: React.FC = () => {
  const {
    recordId,
    currentPage,
    status: qualityCheckStatus,
  } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const { callRecordDetail, setCallRecordDetail, getT3CCallRecordDetail, open, setOpen } = useCallRecordDetail()
  const [authPoint, setAuthPoint] = useState(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  // 质检方案详情
  const [qualityCheckRes, setQualityCheckRes] = useState<QualityCheckResultResType>()
  // 评语
  const [remark, setRemark] = useState('')
  const [previousBtn, setPreviousBtn] = useState(true)
  const [nextBtn, setNextBtn] = useState(true)
  const [loading, setLoading] = useState(false)
  const [audioCurrentTime, setAudioCurrentTime] = useState(0)
  // 选择的质检方案
  const [selectedPlan, setSelectedPlan] = useState<QualityCheckPlanItemType>()
  const [qualityCheckPlanStatus, setQualityCheckPlanStatus] = useState<'' | 'error' | 'warning'>('')
  // 质检方案列表
  const [qualityCheckList, setQualityCheckList] = useState([])
  const [count, setCount] = useState(0)
  const textListRef = useRef(null)
  const [selectKeyWord, setSelectKeyWord] = useState(null)
  const [textListHeight, setTextListHeight] = useState('400px')
  const getKeyWordTag = useCallback(() => {
    if (isEmpty(callRecordDetail) || !audioRef.current) return []
    const { customerKeywordList = [], salesKeywordList = [], callEventList = [] } = callRecordDetail
    let list = [...customerKeywordList, ...salesKeywordList]
    if (!list.length || !callEventList.length) return []
    list = sortBy(list, 'AppearanceTimestamp')
    let startTime = get(callEventList, '1.timestamp', 0)
    startTime = moment(Number(startTime) * 1000).format('YYYY-MM-DD HH:mm:ss')
    // 获取音频的最大秒数
    list = list.map(item => {
      // @ts-ignore
      const endTime = moment(Number(item.AppearanceTimestamp))
      const duration = moment.duration(endTime.diff(startTime))
      const timeInSeconds = Number(duration.hours()) * 3600 + Number(duration.minutes()) * 60 + Number(duration.seconds())
      const left = Number((timeInSeconds / callRecordDetail.audioMaxS).toFixed(2))
      const tagLeft = (audioRef.current.offsetWidth - (sliderLeftWidth + sliderRightWidth)) * left
      // @ts-ignore
      item.diffTime = [duration.hours(), duration.minutes(), duration.seconds()].map(item => `${item}`.padStart(2, '0')).join(':')
      // @ts-ignore
      item.left = `calc(${sliderLeftWidth}px + ${tagLeft}px - ${tabWidth / 2}px)`
      // @ts-ignore
      item.standardTime = endTime.format('YYYY-MM-DD HH:mm:ss')
      return item
    })
    return list
  }, [callRecordDetail, audioRef])
  const keyWordTag = useMemo(() => getKeyWordTag(), [getKeyWordTag])
  // 当前总分
  const currentTotalScore = useMemo(() => {
    if (isEmpty(qualityCheckRes)) return '-'
    const total = qualityCheckRes?.dimensionsScore?.reduce((pre, cur) => pre + (cur?.itemsTotalScore ?? 0), 0)
    return total ?? 0
  }, [qualityCheckRes])

  useEffect(() => {
    const audioElement = audioRef.current
    if (textListRef?.current) {
      const { top = 400 } = textListRef.current.getBoundingClientRect() || {}
      setTextListHeight(`calc(100vh - ${top}px - 60px - 72px - 46px)`)
    }
    if (!audioElement) return
    const handleLoadedMetadata = () => {
      if (audioElement.duration && !isNaN(audioElement.duration)) {
        setCallRecordDetail(v => ({ ...v, audioMaxS: Math.round(audioElement.duration) }))
      }
    }
    // 音频播放中
    const handleTimeUpdate = (e) => {
      const currentTime = parseInt(e?.target?.currentTime)
      setAudioCurrentTime(currentTime)
      const activeEl = textListRef?.current?.querySelector('.active')
      if (textListRef?.current && activeEl) {
        textListRef.current.scrollTo({ top: activeEl.offsetTop, behavior: 'smooth' })
      }
    }
    // 音频播放完
    const handleEnded = () => {
      setAudioCurrentTime(0)
      setSelectKeyWord(null)
      if (textListRef?.current) {
        textListRef.current.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
    audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    audioElement.addEventListener('timeupdate', handleTimeUpdate)
    audioElement.addEventListener('ended', handleEnded)

    return () => {
      audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      audioElement.removeEventListener('timeupdate', handleTimeUpdate)
      audioElement.removeEventListener('ended', handleEnded)
    }
  }, [textListRef, audioRef, callRecordDetail])

  /**
   * 获取权限点
   */
  const getAuthPoint = async () => {
    const [res, err] = await getUserAuthPoint([
      { object: 'tcc.menu.task.call.score', action: 'r' },
      { object: 'tcc.menu.task.call.score', action: 'u' },
    ])
    if (err) {
      setAuthPoint({})
      return
    }
    const pointList = get(res, 'authPoints', [])
    const obj = {}
    pointList.forEach(item => {
      const key = `${item.object}-${item.action}`
      obj[key] = item.result
    })
    // const obj1 = {
    //   "tcc.menu.task.call.score-r": true,
    //   "tcc.menu.task.call.score-u": false
    // }
    setAuthPoint(obj)
  }

  useEffect(() => {
    getAuthPoint()
  }, [])

  const getT3sCallRecordDetail = async () => {
    const filter: CallRecordListFilter = sStorage.get(`${TableIdEnum.TCCC_CALL_RECORD}_FILTER`)
    const page = Number(currentPage)
    const params = { ...filter, page, pageSize: 1 }
    const [res] = await getT3CCallRecordList(params)
    if (res) {
      const { recordId: newRecordId, customerName, salesName, salesNameCh } = get(res, 'list.0', {})
      const total = get(res, 'total', 0)
      setNextBtn(total === page)
      setCallRecordDetail({ customerName, salesName, salesNameCh, recordId: newRecordId })
    }
  }

  useEffect(() => {
    if (currentPage) {
      setPreviousBtn(Number(currentPage) === 1)
      !count && getT3sCallRecordDetail()
    }
  }, [currentPage, count])

  /**
   * 处理时间轴点击事件
   * @param time 时间
   */
  const handleTimelineOnClick = (time: string) => {
    if (!audioRef.current) return
    // 将时间字符串转换为秒数
    const [h, m, s] = time.split(':').map(Number)
    const timeInSeconds = (h * 3600) + (m * 60) + s
    audioRef.current.currentTime = timeInSeconds
    audioRef.current.play()
  }

  /**
   * 获取质检结果
   */
  const getQualityCheckResult = async (recordId) => {
    const [res, err] = await GetQualityCheckResultAPI(Number(recordId))
    if (err) {
      message.error(err.message)
      return
    }
    const result: QualityCheckResultResType = get(res, 'result', {})
    const dimensionsScore = get(result, 'dimensionsScore', [])
    setRemark(result.remark || '')
    dimensionsScore.forEach(item => {
      item.id = uuid()
      item.itemsTotalScoreFromAPI = item.items.reduce((pre, cur) => (pre + (cur.score ?? 0)), 0)
      item.itemsTotalScore = 0
      item.items.forEach(i => {
        i.subId = uuid()
        i.subScore = i.score
      })
    })
    getQualityCheckPlanList(1, 1, { id: result.qualityCheckPlanId }, result)
  }

  /**
   * 获取质检方案列表
   */
  const getQualityCheckPlanList = async (page: number = 1, pageSize: number = 5, options?: { name?: string, id?: number }, result?) => {
    const params: GetQualityCheckPlanListParamsType = { page, pageSize }
    Object.assign(params, options)
    const { id } = options || {}
    const [res, err] = await GetQualityCheckPlanListAPI(params)
    if (err) {
      message.error(err.message)
      return
    }
    const list = get(res, 'list', [])
    if (id) {
      const _result = get(list, '0', {})
      result = result || {}
      result.id = id
      result.name = _result.name
      result.qualifiedScore = _result.qualifiedScore
      if (result.dimensionsScore) {
        result.dimensionsScore.forEach((item, index) => {
          const _items = get(_result, `dimensions.${index}.items`, [])
          item.itemsTotalScoreFromAPI = _items.reduce((pre, cur) => (pre + (cur.score ?? 0)), 0)
          item.itemsTotalScore = item.items.reduce((pre, cur) => (pre + (cur.score ?? 0)), 0)
          item.items.forEach((subItem, subIndex) => {
            subItem.subScore = get(_result, `dimensions.${index}.items.${subIndex}.score`, 0)
          })
        })
      } else {
        const dimensionsScore = cloneDeep(_result.dimensions)
        dimensionsScore.forEach(item => {
          item.id = uuid()
          item.itemsTotalScoreFromAPI = item.items.reduce((pre, cur) => (pre + (cur.score ?? 0)), 0)
          item.itemsTotalScore = 0
          item.items.forEach(i => {
            i.subId = uuid()
            i.subScore = i.score
            i.score = 0
          })
        })
        result.dimensionsScore = dimensionsScore
      }
      setQualityCheckRes(result)
      setSelectedPlan(result)
      return
    }
    setQualityCheckList(list)
  }

  useEffect(() => {
    if (recordId && qualityCheckStatus && !count) {
      if (qualityCheckStatus === '1') {
        getQualityCheckResult(recordId)
      }
      setOpen(false)
      getT3CCallRecordDetail(recordId, {}, true)
    }
    getQualityCheckPlanList(1, 20)
  }, [recordId, qualityCheckStatus, count])

  /**
   * 处理上一条或下一条
   * @param type 处理类型 - previous 上一条，next 下一条
   */
  const handlePreviousOrNext = debounce(async (type: 'previous' | 'next') => {
    message.destroy()
    setLoading(true)
    setRemark('')
    setCount(v => v + 1)
    const currentUrl = new URL(window.location.href)
    const searchParams = new URLSearchParams(currentUrl.search)
    const _currentPage = Number(searchParams.get('currentPage') || '1')
    const page = type === 'previous' ? _currentPage - 1 : _currentPage + 1
    setPreviousBtn(page - 1 < 1)
    const filter: CallRecordListFilter = sStorage.get(`${TableIdEnum.TCCC_CALL_RECORD}_FILTER`)
    const params = { ...filter, page, pageSize: 1 }
    const [res, err] = await getT3CCallRecordList(params)
    setLoading(false)
    if (err) {
      message.error(err.message)
      return
    }
    const { recordId: newRecordId, qualityCheckStatus, customerName, salesName, salesNameCh } = get(res, 'list.0', {})
    const total = get(res, 'total', 0)
    setNextBtn(total === page)
    const obj = { customerName, salesName, salesNameCh, recordId: newRecordId }
    const status = String(qualityCheckStatus)
    setQualityCheckRes(null)
    setSelectedPlan(null)
    setOpen(false)
    const APIArr = [getT3CCallRecordDetail(newRecordId, obj, true)]
    // 已经质检的
    if (status === '1') {
      APIArr.push(getQualityCheckResult(newRecordId))
    }
    (APIArr.length && Promise.all(APIArr))
    searchParams.set('recordId', String(newRecordId))
    searchParams.set('currentPage', String(page))
    searchParams.set('status', status)
    currentUrl.search = (searchParams.toString())
    window.history.replaceState({}, '', currentUrl.toString())
  }, 500)

  /**
   * 评分项修改分值
   * @param path 修改分值对应在qualityCheckRes的路径
   * @param val
   */
  const handleScore = (path: string, val: number) => {
    setQualityCheckRes(v => {
      set(v, path, val)
      v.dimensionsScore.forEach(item => {
        item.itemsTotalScore = item.items.reduce((pre, cur) => (pre + (cur.score ?? 0)), 0)
      })
      return { ...v }
    })
  }

  /**
   * 搜索质检方案
   * @param val
   */
  const handleQualityCheckPlanSearch = debounce((name) => {
    // 搜所质检方案
    getQualityCheckPlanList(1, 20, { name })
  }, 1000)

  /**
   * 选择质检方案
   * @param id 方案ID
   * @param options 方案详情
   */
  const handleQualityCheckPlanChange = (id, options) => {
    setQualityCheckPlanStatus(id ? '' : 'error')
    setSelectedPlan(options)
    if (!id) {
      setSelectedPlan(null)
      setQualityCheckRes(null)
      setRemark('')
      message.error('请选择质检方案')
      return
    }
    // 获取质检方案详情 - 返回的数据是完整的
    getQualityCheckPlanList(1, 1, { id })
  }

  /**
   * 提交、提交并进入下一条
   * @param type 提交类型 - submit 提交，submit-next 提交并进入下一条
   */
  const handleSubmit = debounce(async (type: 'submit' | 'submit-next' = 'submit') => {
    if (!qualityCheckRes?.id) {
      message.error('请选择质检方案')
      setQualityCheckPlanStatus('error')
      return
    } else {
      setQualityCheckPlanStatus('')
    }
    setLoading(true)
    const dimensionsScore = cloneDeep(qualityCheckRes.dimensionsScore)
    /* eslint-disable */
    dimensionsScore?.forEach(item => {
      ['id', 'itemsTotalScoreFromAPI', 'itemsTotalScore'].forEach(key => {
        delete item[key]
      })
      item.items.forEach(subItem => {
        ['subId', 'subScore', 'requirement'].forEach(subKey => {
          delete subItem[subKey]
        })
      })
    })
    /* eslint-enable */
    const params: AddQualityCheckResultParamsType = {
      qualityCheckPlanId: qualityCheckRes.id,
      score: currentTotalScore as number,
      callRecordId: Number(recordId),
      remark,
      dimensionsScore,
    }
    // 删除不必要属性
    const [, err] = await AddQualityCheckResultAPI(params)
    setLoading(false)
    if (err) {
      message.error(err.message)
      return
    }
    message.success('提交成功')
    if (type === 'submit-next') {
      handlePreviousOrNext('next')
    }
  }, 500)

  return (
    <>
      <Card
        className="score-detail-page"
        title={
          <Link to="/task/call-record?tabKey=tccc">
            <ArrowLeftOutlined style={{ marginRight: 10 }} />
            <span>
              {`${(callRecordDetail && getStaffName(callRecordDetail?.salesName, callRecordDetail?.salesNameCh)) || '-'} 与 ${callRecordDetail?.customerName || '-'} 的通话`}
            </span>
          </Link>
        }
      >
        <Row>
          <Col span={11} className="left">
            <div className="top flex">
              <div className="quality-inspection-scheme">
                <p className="required-identification">选择质检方案</p>
                {authPoint?.[menuTaskCallScoreU] ? (
                  <Select
                    showSearch
                    allowClear
                    placeholder="请选择质检方案"
                    optionFilterProp="children"
                    onChange={handleQualityCheckPlanChange}
                    onSearch={handleQualityCheckPlanSearch}
                    value={selectedPlan?.id}
                    filterOption={false}
                    fieldNames={{ label: 'name', value: 'id' }}
                    options={qualityCheckList}
                    style={{ width: 300 }}
                    onBlur={debounce(() => getQualityCheckPlanList(1, 20), 500)}
                    status={qualityCheckPlanStatus}
                  />)
                  : <span>{qualityCheckList?.find(item => item.id === selectedPlan?.id)?.name || '-'}</span>}
              </div>
              <div className="total-score">
                <p>当前总分</p>
                <span style={{ fontSize: 24 }}>{currentTotalScore}</span>
              </div>
            </div>
            {!isEmpty(qualityCheckRes?.dimensionsScore) && (
              <div className="score-list">
                <p className="required-identification">评分项</p>
                <Collapse>
                  {qualityCheckRes?.dimensionsScore?.map((item, index) => (
                    <Collapse.Panel
                      header={
                        <>
                          <Typography.Text ellipsis={{ tooltip: item.name }}>{item.name}</Typography.Text>
                          <div className="score-container">{item.itemsTotalScore}/{item.itemsTotalScoreFromAPI}</div>
                        </>
                      }
                      key={item.id}
                    >
                      {item.items.map((subItem, subIndex) => (
                        <div className="score-item flex" key={subItem.subId}>
                          <Typography.Text ellipsis={{ tooltip: subItem.name }}>
                            {subItem.name}
                          </Typography.Text>
                          <Space size={authPoint?.[menuTaskCallScoreU] ? 4 : 0}>
                            {authPoint?.[menuTaskCallScoreU] ? (
                              <InputNumber
                                value={subItem.score}
                                onChange={val => handleScore(`dimensionsScore.${index}.items.${subIndex}.score`, val)}
                                precision={0}
                                min={0}
                                max={subItem.subScore}
                              />)
                              : <span>{subItem.score}</span>}
                            <span>/{subItem.subScore}</span>
                          </Space>
                        </div>
                      ))}
                    </Collapse.Panel>
                  ))}
                </Collapse>
                {authPoint?.[menuTaskCallScoreU] ? (
                  <Input.TextArea
                    showCount
                    value={remark}
                    onChange={e => setRemark(e.target.value)}
                    maxLength={500}
                    style={{ height: 120, marginTop: 40 }}
                    placeholder="请输入评语"
                  />)
                  : (
                    <>
                      <p style={{ marginTop: 26 }}>评语：</p>
                      <p style={{ textIndent: 28 }}>评语：{remark}</p>
                    </>)}
              </div>)}
          </Col>
          <Col span={11} offset={2} className="right">
            <p>通话录音</p>
            <div className="audio-container">
              {selectKeyWord && (
                // @ts-ignore
                <div className="tag" style={{ left: selectKeyWord.left }}>
                  {/* @ts-ignore */}
                  <Typography.Text ellipsis={{ tooltip: selectKeyWord.keyword }}>{selectKeyWord.keyword}</Typography.Text>
                </div>
              )}
              <audio ref={audioRef} key={callRecordDetail?.recordId || uuid()} src={callRecordDetail?.recordUrl} controls />
            </div>
            {open ? (
              <>
                {Boolean(keyWordTag.length) && (
                  <div className="key-word-container">
                    <p>匹配关键词</p>
                    <div className="tag-container">
                      {keyWordTag.map((item, index) => (
                        <Tag
                          key={index}
                          color="default"
                          onClick={() => {
                            // @ts-ignore
                            setSelectKeyWord(item)
                            // @ts-ignore
                            handleTimelineOnClick(item.diffTime)
                          }}>
                          {/* @ts-ignore */}
                          <Typography.Text ellipsis={{ tooltip: item.keyword }}>{item.keyword}</Typography.Text>
                        </Tag>
                      ))}
                    </div>
                  </div>
                )}
                <div className="call-timeline">
                  {Boolean(callRecordDetail?.callEventList?.length) && (
                    <div>
                      <p>通话时间线</p>
                      <Timeline mode="left">
                        {callRecordDetail?.callEventList?.map((item, index) => (
                          <Timeline.Item key={index} label={<Typography.Text type="secondary">{item.standardTime}</Typography.Text>}>
                            <Typography.Text ellipsis={{ tooltip: item.eventText }}>{item.eventText}</Typography.Text>
                            {/* <Button type="link" style={{ width: '100%' }} onClick={() => handleTimelineOnClick(item.diffTime)}>
                            </Button> */}
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  )}
                  <div className="offline-speech-recognition">
                    {Boolean(callRecordDetail?.speechTextList?.length) && (
                      <>
                        {callRecordDetail?.callId && (
                          <>
                            <p>离线语音识别</p>
                            <p className="session-id-container">
                              <span className="id-label">Session ID：</span>
                              <Typography.Paragraph className="copy-text-container" copyable={{ text: callRecordDetail?.callId }}>
                                <Typography.Text type="secondary" className="session-id" ellipsis={{ tooltip: callRecordDetail?.callId }}>{callRecordDetail?.callId}</Typography.Text>
                              </Typography.Paragraph>
                            </p>
                          </>
                        )}
                        <div className="text-list" style={{ height: textListHeight }} ref={textListRef}>
                          {callRecordDetail?.speechTextList?.map(item => (
                            <div
                              key={`${item.Start}-${item.End}`}
                              className={`text-list-item flex ${item.isCustomerService ? 'customer-service' : 'client'} ${(audioCurrentTime >= item.startDiffSeconds && audioCurrentTime < item.endDiffSeconds) && 'active'}`}
                            >
                              {(!item.isCustomerService && callRecordDetail.customerName) && (
                                <div className="name">
                                  <div>{callRecordDetail.customerName}</div>
                                  <div className="time">{item.startStandardTime}</div>
                                </div>
                              )}
                              {(item.isCustomerService && callRecordDetail?.salesName) && (
                                <div className="name">
                                  <div>{getStaffName(callRecordDetail?.salesName, callRecordDetail?.salesNameCh)}</div>
                                  <div className="time">{item.startStandardTime}</div>
                                </div>
                              )}
                              <div className="content">
                                {item.Message}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <Skeleton active className="key-word" paragraph={{ rows: 1, width: '100%' }} />
                <Skeleton active paragraph={{ rows: 1, width: '100%' }} />
                <Skeleton active paragraph={{ rows: 1, width: '100%' }} />
              </>
            )}
          </Col>
        </Row>
        <div className="btn-container">
          <Space size={12}>
            {authPoint?.[menuTaskCallScoreU] && (
              <>
                <Button loading={loading} disabled={loading || nextBtn} type="primary" onClick={() => handleSubmit('submit-next')}>提交并进入下一条</Button>
                <Button loading={loading} disabled={loading} type="default" onClick={() => handleSubmit('submit')}>提交</Button>
              </>
            )}
            <Button loading={loading} disabled={loading || previousBtn} onClick={() => handlePreviousOrNext('previous')}>上一条</Button>
            <Button loading={loading} disabled={loading || nextBtn} onClick={() => handlePreviousOrNext('next')}>下一条</Button>
          </Space>
        </div>
      </Card>
    </>
  )
}

export default ScoreDetailPage
