/**
 * 应用主体框架
 * @author jasonelchen
 */
import React, { FunctionComponent, useMemo, Suspense, useEffect } from 'react'
import { BrowserRouter, Switch, Route, matchPath, useLocation } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import { BackTop, ConfigProvider, Spin } from 'antd'
import classNames from 'classnames'
import zhCN from 'antd/es/locale-provider/zh_CN'

import { SiderBar, Login, ErrorBoundary, ConfigInfo } from '@components'
import { Provider } from '@store'
import { useAppConfigs } from '@hooks'
import InjectAxiosInterceptors from './library/InjectAxiosInterceptors'
import { TableIdEnum } from '@configs/table'
import { sStorage } from '@library'

const Frame: FunctionComponent<{}> = () => {
  const location = useLocation()
  const { loading, menu, routes } = useAppConfigs()

  useEffect(() => {
    // 清除对应列表页缓存的筛选数据
    // 应用场景：
    //   部分页面需要进入详情页，需要保留筛选条件
    //   当缓存了筛选数据之后，除了切换对应页面之外，切换到其他页面，需清除筛选值
    const needClearSessionPath = {
      [TableIdEnum.TCCC_CALL_RECORD]: ['/task/call-record', '/task/score-detail'],
    }
    Object.keys(needClearSessionPath).forEach(key => {
      if (location?.pathname && !needClearSessionPath?.[key]?.includes(location.pathname)) {
        // 筛选组件【CommonFilters】应用这个
        sStorage.remove(`${key}_FILTER_VALUES`)
        // 这个如果详情页涉及【上一条/下一条】会用到
        sStorage.remove(`${key}_FILTER`)
      }
    })
  }, [location])

  const children = useMemo(() => (
    <Switch>
      {routes.map(route => (
        <Route key={route.key} strict {...route} />
      ))}
    </Switch>
  ), [routes])

  const matchedRoute = useMemo(() => routes.find(route => matchPath(location.pathname, route)), [routes, location])
  const { key, standalone, needLogin } = matchedRoute || {}

  if (!matchedRoute) {
    return (
      <Login gettingAuth={loading}>
        <div id="main-app" className={classNames('main-app', 'page-not-found')}>
          {children}
        </div>
      </Login>
    )
  }

  const $page = (
    <>
      {!standalone && <SiderBar menus={menu} />}
      <div id="main-app" className={classNames('main-app', `page-${key}`)}>
        <BackTop target={() => document.getElementById('main-app')} style={{ bottom: 20 }} />
        <Suspense fallback={<div className="login-loading"><Spin spinning tip="加载中..." /></div>}>
          {children}
        </Suspense>
      </div>
    </>
  )

  return needLogin ? (
    <Login>
      {$page}
    </Login>
  ) : $page
}

const App: FunctionComponent<{}> = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter basename="/">
        <Provider>
          <ErrorBoundary>
            <InjectAxiosInterceptors />
            <Frame />
            <ConfigInfo />
          </ErrorBoundary>
        </Provider>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default hot(App)
