import React, { useMemo, useState, useEffect, useRef } from 'react'
import Card from 'antd/lib/card/Card'
import { CommonFilters, TableDownload, UserAvatar, ReplayRecord } from '@components'
import { t3cCallRecordFilterItems } from 'src/components/CommonFilters/configs'
import { ConfigTableHeaderSort } from 'src/components/ConfigTableHeaderSort'
import { TableIdEnum, getTableXwidth, getSortOrder } from '@configs/table'
import { exportT3CCallRecordList, ExportT3CCallRecordWithQCResultListAPI, getT3CCallRecordList } from '@services/customer'
import { Table, Tooltip, TableProps, Space, Button, Typography } from 'antd'
import { useTableColumn, TableColumnProps } from '@hooks'
import { antdSortMap } from '@configs/map'
import { Link, useHistory } from 'react-router-dom'
import { trimStr, handleT3CCallRecord, ExpandCallRecordDataType, sStorage } from '@library'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useTableService } from '@hooks/useTableService'
import { CallRecordListFilter, needTotalEnum, sortTypeEnum, CallRecordListItem, T3CCallEndReasonEnum } from '@types'
import { getUserAuthPoint } from '@services/session'
import T3CTimeLineBtn from './T3CTimeLineBtn'
import { FormValues } from '@tencent/meta-form'
import AUTH_POINT_MAP from '@configs/auth-point'
import moment from 'moment'
import { isEmpty, get, cloneDeep } from 'lodash'
const {
  menuTaskCallScoreR,
  menuTaskCallScoreU,
} = AUTH_POINT_MAP
type CallRecordData = CallRecordListItem & ExpandCallRecordDataType
const initFilter: CallRecordListFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.LIST,
  sort: 'createTime',
  sortType: sortTypeEnum.DESC,
}
export default function TCCCCallRecord () {
  const history = useHistory()
  const [authPoint, setAuthPoint] = useState(null)
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
    reload,
  } = useTableService(getT3CCallRecordList, initFilter, '获取t3c通话记录失败')
  const t3cCallRecordList = useMemo<CallRecordData[]>(() => handleT3CCallRecord(list), [list])
  const columns: TableColumnProps<CallRecordData>[] = useMemo(() => [
    {
      title: '通话开始时间',
      dataIndex: 'callBeginTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callBeginTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '跟进人', dataIndex: 'salesName', width: 260, render: value => <UserAvatar salesName={value} /> },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 280,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '外呼类型', dataIndex: 'customerId', width: 180, render: (_, record) => record.customerId ? '点击外呼' : '拨号外呼' },
    { title: '被叫号码', dataIndex: 'phone', width: 180 },
    {
      title: (<>通话时长<Tooltip title="指从通话接通到通话结束的时间"><QuestionCircleOutlined /></Tooltip></>),
      _title: '通话时长',
      dataIndex: 'durationText',
      width: 120,
    },
    { title: '通话结果', dataIndex: 'failReasonText', width: 180 },
    {
      title: '命中关键词',
      dataIndex: 'keyWord',
      width: 280,
      render: (val, record) => {
        const { salesKeywordList = [], customerKeywordList = [] } = record
        return (
          <>
            {!isEmpty(salesKeywordList)
              && <>
                <Typography.Text
                  style={{ width: 280 }}
                  ellipsis={{ tooltip: salesKeywordList.join('、') }}
                >
                  客服关键词：{salesKeywordList.join('、')}；
                </Typography.Text>
                <br />
                {/* eslint-disable-next-line */}
              </>}
            {!isEmpty(customerKeywordList)
              && <Typography.Text
                style={{ width: 280 }}
                ellipsis={{ tooltip: customerKeywordList.join('、') }}
              >
                客户关键词：{customerKeywordList.join('、')}；
                {/* eslint-disable-next-line */}
              </Typography.Text>}
          </>
        )
      },
    },
    { title: '满意度', dataIndex: 'satisfactionResultText', width: 120 },
    {
      title: '质检人',
      dataIndex: 'qualityCheckSales',
      width: 260,
      render: value => <UserAvatar salesName={value} />,
    },
    { title: '质检时间', dataIndex: 'qualityCheckTime', width: 180 },
    { title: '分数', dataIndex: 'qualityCheckScore', width: 120 },
    {
      title: '记录生成时间',
      dataIndex: 'createTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '主叫号码', dataIndex: 'callNumber', width: 180 },
    {
      title: '通话结束时间',
      dataIndex: 'callEndTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callEndTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 220,
      render: (formItem, item, index) => {
        return (
          <Space>
            {authPoint?.[menuTaskCallScoreU] && <Button disabled={item.disabled} type="link" style={{ padding: 0 }} onClick={() => handleToScoreDetail(item, index)}>评分</Button>}
            {(!authPoint?.[menuTaskCallScoreU] && authPoint?.[menuTaskCallScoreR] && item?.qualityCheckStatus === 1)
              && <Button type="link" style={{ padding: 0 }} onClick={() => handleToScoreDetail(item, index)}>查看评分</Button>}
            <ReplayRecord
              disabled={item.disabled}
              playTips={item.playTips}
              logId={`t3c-${item.recordId}`}
              title={`与${item?.customerName}的通话录音`}
              key={item?.callId}
              btnText="播放"
            />
            <T3CTimeLineBtn disabled={item.disabled} playTips={item.playTips} recordId={item.recordId} />
          </Space>
        )
      },
    },
  ], [filter.sort, filter.sortType, authPoint])
  const onTableChange: TableProps<CallRecordListItem>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination
    const page = current
    const key = `${TableIdEnum.TCCC_CALL_RECORD}_FILTER`
    const val: FormValues = sStorage.get(key)
    sStorage.set(key, { ...val, page, pageSize: 1, _pageSize: pageSize })
    const sort = getSortOrder<CallRecordListFilter>(sorter)
    setFilter(sort)
  }
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.TCCC_CALL_RECORD, columns, ['customerName', 'actions'], ['createTime', 'callNumber', 'callEndTime'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])
  const filterRef = useRef(null)

  /**
   * 获取权限点
   */
  const getAuthPoint = async () => {
    const [res, err] = await getUserAuthPoint([
      { object: 'tcc.menu.task.call.score', action: 'r' },
      { object: 'tcc.menu.task.call.score', action: 'u' },
    ])
    const obj = {}
    if (err) {
      setAuthPoint({})
      return
    }
    const pointList = get(res, 'authPoints', [])
    pointList.forEach(item => {
      const key = `${item.object}-${item.action}`
      obj[key] = item.result
    })
    setAuthPoint(obj)
  }

  useEffect(() => {
    getAuthPoint()
  }, [])

  // 将缓存的筛选数据转换为筛选组件需要的数据
  useEffect(() => {
    const val: FormValues = sStorage.get(`${TableIdEnum.TCCC_CALL_RECORD}_FILTER_VALUES`)
    if (filterRef?.current && val) {
      const vals = cloneDeep(val)
      // 通话开始时间
      if (val.callBeginTimeFrom || val.callBeginTimeTo) {
        const start = val.callBeginTimeFrom ? moment(val.callBeginTimeFrom) : undefined
        const end = val.callBeginTimeTo ? moment(val.callBeginTimeTo) : undefined
        vals.callBeginTime = [start, end]
      }
      // 通话时长
      if (val.durationFrom || val.durationTo) {
        vals.duration = [val.durationFrom || undefined, val.durationTo || undefined]
      }
      // 记录生成时间
      if (val.createTimeFrom || val.createTimeTo) {
        const start = val.createTimeFrom ? moment(val.createTimeFrom) : undefined
        const end = val.createTimeTo ? moment(val.createTimeTo) : undefined
        vals.createTime = [start, end]
      }
      // 通话结果
      if (val.failReasonList?.length) {
        const error = [
          T3CCallEndReasonEnum.ERROR,
          T3CCallEndReasonEnum.TOOMANYREQUEST,
          T3CCallEndReasonEnum.RESTRICTEDAREA,
          T3CCallEndReasonEnum.RESTRICTEDTIME,
          T3CCallEndReasonEnum.UNKNOWN,
          T3CCallEndReasonEnum.CLIENTERROR,
        ]
        const hangUp = [
          T3CCallEndReasonEnum.IVRGIVEUP,
          T3CCallEndReasonEnum.WAITINGGIVEUP,
          T3CCallEndReasonEnum.RINGINGGIVEUP,
        ]
        const failReasonList = [...(val.failReasonList || [])]
        for (let i = 0; i < failReasonList.length; i++) {
          if (error.includes(failReasonList[i])) {
            failReasonList.splice(i, 1, 'error')
            continue
          }
          if (hangUp.includes(failReasonList[i])) {
            failReasonList.splice(i, 1, 'hangUp')
            continue
          }
        }
        vals.failReasonList = Array.from(new Set(failReasonList))
      }
      filterRef.current.setValues(vals)
      setFilter(val)
    }
  }, [filterRef])

  /**
   * 跳转评分页
   * @param recordId 通话记录ID
   * @param index 当前行索引
   */
  const handleToScoreDetail = async (item, index) => {
    const { recordId, qualityCheckStatus } = item
    const val: FormValues = sStorage.get(`${TableIdEnum.TCCC_CALL_RECORD}_FILTER`) || {}
    val.page = (((val?.page ?? 1) - 1) * (val?._pageSize || 0)) + index + 1
    const createTime = moment().format('YYYY-MM-DD HH:mm:ss')
    sStorage.set(`${TableIdEnum.TCCC_CALL_RECORD}_FILTER`, { ...filter, ...val, createTime, needTotal: 1 })
    history.push(`/task/score-detail?recordId=${recordId}&currentPage=${val.page}&status=${qualityCheckStatus}`)
  }

  return (
    <>
      <Card className="mt20 mb20">
        <CommonFilters
          cRef={filterRef}
          className="custom-Label"
          itemList={t3cCallRecordFilterItems}
          onSubmit={filter => {
            const val = { ...filter, page: 1 }
            const newVal = Object.fromEntries(
              Object.entries({ ...val }).filter((item: [string, any]) => {
                const [, value] = item
                if (value === null || value === undefined) return false
                if (typeof value === 'object') {
                  return Object.keys(value).length !== 0
                }
                return value !== ''
              }),
            )
            sStorage.set(`${TableIdEnum.TCCC_CALL_RECORD}_FILTER`, newVal)
            setFilter(val)
            reload()
          }}
          onReset={() => {
            sStorage.remove(`${TableIdEnum.TCCC_CALL_RECORD}_FILTER`)
            resetFilter(initFilter)
          }}
          tableId={TableIdEnum.TCCC_CALL_RECORD}
          standstill={10}
        />
      </Card>
      <Card>
        <ConfigTableHeaderSort columnConfigs={columnConfigs} tableId={TableIdEnum.TCCC_CALL_RECORD} onSuccess={forceRequest} />
        <TableDownload className="ml20" exportMethod={exportT3CCallRecordList} filter={filter} />
        {authPoint?.[menuTaskCallScoreU] && <TableDownload className="ml20" exportMethod={ExportT3CCallRecordWithQCResultListAPI} filter={filter} btnText="下载质检评分列表" />}
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={t3cCallRecordList}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
