/**
 * @author tylerzzheng
 */

import { isEmpty, isArray } from 'lodash'

export type Merge<T, D> = T & Partial<D>

/**
 * 合并两个数组，根据两个数组都有的id合并，或者根据一个方法，当这个方法返回true时合并
 * 是把arr2合并到arr1，所以合并后的长度是arr1和arr2的id的交集,arr2的字段会覆盖arr1
 * @param arr1
 * @param arr2
 * @param id 字段id, 或者是一个函数
 */
export function mergeArrayWith<T, D> (arr1: Array<T>, arr2: Array<D>, id: string): Array<Merge<T, D>>
export function mergeArrayWith<T, D> (arr1: Array<T>, arr2: Array<D>, fn: (obj1: T, obj2: D) => boolean): Array<Merge<T, D>>
export function mergeArrayWith<T, D> (arr1: Array<T>, arr2: Array<D>, fnOrId: string | ((obj1: T, obj2: D) => boolean)): Array<Merge<T, D>> {
  if (isEmpty(arr1)) return []
  if (!isArray(arr1)) return null
  if (isEmpty(arr1) || !isArray(arr2)) return arr1

  const compactor = typeof fnOrId === 'string' ? (val1: T, val2: D) => val1[fnOrId] === val2[fnOrId] : (val1: T, val2: D) => fnOrId(val1, val2)

  const temp: Merge<T, D>[] = []
  arr1.forEach(val1 => {
    arr2.find(val2 => {
      if (compactor(val1, val2)) {
        temp.push({ ...val1, ...val2 })
        return true
      }
    })
  })
  return temp
}
