import { BindingRecordFormEnum } from '@types'
import { moduleId } from '@configs'

export const dateRangeOptions = [
  { label: '当月', value: 'tmonth' },
  { label: '近7天', value: 'l7d' },
  { label: '近15天', value: 'l15d' },
  { label: '近30天', value: 'l30d' },
  { label: '近60天', value: 'l60d' },
]

export const bindingRecordFormTextMapping: Record<BindingRecordFormEnum, string> = {
  [BindingRecordFormEnum.PRIVATE_AREA]: '私域',
  [BindingRecordFormEnum.SMS]: '短信',
}

export const moduleNameMapping = {
  [moduleId.MID_LONG_TAIL_ID]: '销售转化一云电销',
  [moduleId.COC_TURN_ID]: '销售转化-官网个人转化',
}
