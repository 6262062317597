import React, { useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import { Button, Row, Col, Radio, DatePicker, Select, Card } from 'antd'
import { CompanyInfo, TimeTypeEnum } from '@types'
import { CommonSelector } from '@components'
import moment from 'moment'
import store from '@store'
import { groupId, salesName } from '../../../../components/CommonSelector/configs'
import { createForm, Form } from '@library'
import { RangePickerProps } from 'antd/lib/date-picker'
import { isEmpty } from 'lodash'

const { RangePicker } = DatePicker
const { Option } = Select

const formStore = createForm<FormValueType>()
const { useValues, useErrors, useValidate, Field, withForm } = formStore

const rangePickerPropsMap: Record<TimeTypeEnum, RangePickerProps> = {
  hour: { showTime: { format: 'HH' } },
  day: {},
  month: { format: 'YYYY-MM' },
}

export interface FormValueType {
  timeType?: TimeTypeEnum
  rangeTime?: moment.Moment[]
  salesName?: string // 否 string 销售名字
  groupId?: number // 否 int 小组ID
  companyId?: number // 否 int 销售名字
}

interface CommonInlineFilterProps {
  initValues?: FormValueType
  ref?: React.Ref<any>
  cRef?: React.Ref<any>
  onSubmit: (filter: FormValueType) => void,
}

function CF (props: CommonInlineFilterProps) {
  const { initValues, cRef, onSubmit } = props

  const { validate } = useValidate()
  const { values, setValues, setInitialValues, resetValues } = useValues()
  const { setError, resetErrors } = useErrors()
  const { timeType, rangeTime } = values

  // 设置初始值
  useEffect(() => setInitialValues(initValues || {}), [initValues])
  // 暴露重置value方法
  useImperativeHandle(cRef, () => ({
    resetValues: (values: FormValueType = {}) => {
      resetValues(values)
    },
  }))

  // 获取公司数据
  const { userInfo, currCompanyList } = store.useSession()
  const { authPointMap } = store.useGlobal()
  const companyList: CompanyInfo[] = useMemo(() => {
    // 选择公司： 有权限点，则可以选择当前模块下的所有公司。没有权限点，则可以选择负责的公司。都没有就看不到公司选择下拉框。
    const list = authPointMap.readAllReport?.result ? currCompanyList : currCompanyList?.filter(c => c.manager?.split(';')?.includes(userInfo.salesName))
    return list || []
  }, [authPointMap, userInfo.salesName])

  const rangePickerProps = useMemo(() => rangePickerPropsMap[timeType] || {}, [timeType])
  const setRangeTime = useCallback((range: moment.Moment[] = rangeTime) => {
    /*
     这里根据时间颗粒度，选择的时间都format成整小时整日整月
     就是说YYYY-MM-DD HH:mm:ss，
      当hour的时候， mm:ss 固定为00：00，
      当month的时候， DD HH：MM：SS固定为 01 00：00：00
       当day的时候， HH:MM:SS 固定为 00:00：00
     */
    const formattedRange = range?.map(m => moment(m)?.startOf(timeType || 'day'))
    setValues('rangeTime', formattedRange)
  }, [timeType])

  // 提交过滤条件
  const handleSubmit = useCallback(async () => {
    resetErrors()
    const validateRes = await validate()
    if (!validateRes.isPass) {
      return
    }
    const { companyId, groupId, salesName } = values
    if (!companyId && !groupId && !salesName) {
      setError('companyId', '必须选择公司、员工和群组的一个')
      setError('groupId', '必须选择公司、员工和群组的一个')
      setError('salesName', '必须选择公司、员工和群组的一个')
      return
    }
    onSubmit(values)
  }, [onSubmit, values])

  return (
    <Card className="mt20">
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Row>
          <Col span={8}>
            <Field
              field="timeType"
              label="时间颗粒度"
              required
              onChange={timeType => {
                setValues({ timeType })
                setRangeTime() // 当时间颗粒度改变时，重新format时间
              }}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="hour">小时报</Radio.Button>
                <Radio.Button value="day">日报</Radio.Button>
                <Radio.Button value="month">月报</Radio.Button>
              </Radio.Group>
            </Field>
          </Col>
          <Col span={8}>
            <Field
              field="rangeTime"
              label="起止日期"
              required
              onChange={setRangeTime}
            >
              <RangePicker {...rangePickerProps} />
            </Field>
          </Col>
          {!isEmpty(companyList) && (
            <Col span={8}>
              <Field field="companyId" label="公司" onChange={companyId => setValues({ companyId, groupId: undefined, salesName: undefined })}>
                <Select allowClear>
                  {companyList?.map(company => {
                    return <Option key={company?.companyId} value={company?.companyId}>{company?.companyName}</Option>
                  })}
                </Select>
              </Field>
            </Col>)}
          <Col span={8}>
            <Field field="groupId" label="群组" onChange={groupId => setValues({ groupId, salesName: undefined })}>
              <CommonSelector {...groupId} filter={{ companyId: values?.companyId ?? undefined }} />
            </Field>
          </Col>
          <Col span={8}>
            <Field field="salesName" label="员工" onChange={salesName => setValues({ salesName })}>
              <CommonSelector {...salesName} filter={{ companyId: values?.companyId ?? undefined, groupId: values?.groupId ?? undefined }} />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col span={4} className="mt3">
            <Button className="mr15" type="primary" onClick={handleSubmit}>查询</Button>
            <Button type="default" onClick={() => { onSubmit(initValues); resetValues(initValues) }}>重置</Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default withForm(CF)
