/**
 * 展示发送的短信信息
 * @author tylerzzheng
 */
import React, { Children, cloneElement, PropsWithChildren, useCallback, useState } from 'react'
import { message, Popover, Tooltip } from 'antd'
import { MailOutlined, LoadingOutlined } from '@ant-design/icons'
import { getSMSRecord } from '@services/customer'
import { isEmptyWithCustom } from '@library'
import { get, isEmpty } from 'lodash'

interface ShowSMSProps {
  logId?: string
  sms?: string // 短信原文，有这个值的时候将略过用logID去请求短信的过程。logId参数会被忽略
  className?: string
}

/**
 * 展示发送的短信信息,
 * 此组件功能，会显示一个play的icon(可以被children覆盖，自由扩展)，点击之后popover出短信原文。
 * 如果有子元素，请确保子元素能接受onClick事件。因为coClick事件和sms短信内容两个参数会被传递给子组件。
 * @param props
 * @constructor
 */
export function ShowSMS (props: PropsWithChildren<ShowSMSProps>) {
  const { className, sms = null, logId, children } = props

  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState<string>(sms)

  // 当logid变化的时候，清空缓存
  useCallback(() => { setContent(content) }, [logId])

  const getSmsContent = useCallback(async (): Promise<string> => {
    if (isEmptyWithCustom(logId)) return null

    setLoading(true)
    const [res, err] = await getSMSRecord(logId)
    setLoading(false)
    if (err) {
      message.error(`获取短信失败(${err.message})`)
      return null
    }
    return get(res, 'smsContent', null)
  }, [logId])

  const showSms = useCallback(async () => {
    const sms = isEmpty(content) ? await getSmsContent() : content
    if (isEmpty(sms)) {
      message.error('短信内容不存在')
      return
    }
    setContent(sms)
  }, [content])

  const renderChildren = () => {
    const icon = loading ? <LoadingOutlined onClick={showSms} style={{ cursor: 'pointer', color: '#1890ff' }} className={className} /> : <MailOutlined onClick={showSms} style={{ cursor: 'pointer', color: '#1890ff' }} className={className} />
    if (!children) {
      return isEmpty(content) ? (<Tooltip title="查看短信">{icon}</Tooltip>) : <Popover defaultVisible content={content}>{icon}</Popover>
    } else {
      // 只能有一个子元素
      Children.only(children)
      return cloneElement(children as React.ReactElement, { onClick: showSms, sms: content })
    }
  }

  return (
    <>
      {renderChildren()}
    </>
  )
}
