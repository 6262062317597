/**
 * 规则分类管理
 * @author tylerzzheng
 */
import React, { FunctionComponent, useDebugValue, useMemo, useState } from 'react'
import { Card, Col, Input, message, Modal, Popconfirm, Row, Table, Button } from 'antd'
import { PageHeader, UserAvatar } from '@components'
import { CommonError, IEditRuleTypePayload, IRuleType, RuleTypePayload } from '@types'
import { ColumnProps } from 'antd/lib/table'
import { addRuleType, deleteRuleType, editRuleType, getRuleTypes } from '@services/customer'
import { cache } from '@library'
import { getTableXwidth } from '@configs/table'
import { useTableService } from '@hooks/useTableService'
import { isEmpty } from 'lodash'

enum ActionType {
  ADD = '新增',
  EDIT = '编辑'
}

interface IFormValue {
  ruleType?: number
  ruleTypeName?: string
}

const initFilter: RuleTypePayload = {
  page: 1,
  pageSize: 10,
}

const ConfigRuleTypePage: FunctionComponent = () => {
  useDebugValue('ConfigRuleTypePage')

  const {
    loading,
    list,
    reload,
    pagination,
    rowKey,
  } = useTableService(getRuleTypes, initFilter, '获取规则分类列表失败')

  const columns: ColumnProps<IRuleType>[] = useMemo(() => [
    { title: 'id', dataIndex: 'ruleType', width: 80 },
    { title: '分类名称', dataIndex: 'ruleTypeName', width: 120 },
    { title: '创建人', dataIndex: 'lastModifier', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '创建时间', dataIndex: 'createTime', width: 120 },
    {
      title: '操作',
      width: 120,
      render: (value, ruleType) => {
        return (
          <>
            <a onClick={() => openModal(ActionType.EDIT, ruleType)}>编辑</a>
            <Popconfirm className="ml10" title="确认删除吗？" onConfirm={() => handleDeleteRuleType(ruleType?.ruleType)}>
              <a>删除</a>
            </Popconfirm>
          </>
        )
      },
    },
  ], [])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  /* 弹窗相关 */
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [formValue, setFormValue] = useState<IFormValue>({})
  const [actionType, setActionType] = useState<ActionType>(ActionType.ADD)

  function openModal (type: ActionType, ruleType?: IRuleType) {
    if (type === ActionType.EDIT) {
      setFormValue({ ruleType: ruleType?.ruleType, ruleTypeName: ruleType?.ruleTypeName })
    }
    if (type === ActionType.ADD) {
      setFormValue({})
    }
    setActionType(type)
    setModalOpen(true)
  }
  async function handleDeleteRuleType (id: number) {
    const [, err] = await deleteRuleType(id)
    if (err) {
      message.error('删除失败！' + err.message)
      return
    }
    message.success('删除成功！')
    reload()
  }
  const submitForm = async () => {
    let commonErr: CommonError
    if (actionType === ActionType.ADD) {
      const [, err] = await addRuleType({ ruleTypeName: formValue?.ruleTypeName })
      commonErr = err
    }
    if (actionType === ActionType.EDIT) {
      const [, err] = await editRuleType(formValue as IEditRuleTypePayload)
      commonErr = err
    }
    if (commonErr) {
      message.error(`${actionType}规则分类失败！${commonErr.message}`)
    } else {
      message.success(`${actionType}规则分类成功！`)
      setModalOpen(false)
      reload()
    }
  }

  // 当分类名称未修改或为空时，禁止掉提交按钮
  const disableSubmitButton: boolean = useMemo(() => {
    const { ruleType: id, ruleTypeName } = formValue
    if (isEmpty(ruleTypeName)) return true
    if (id) {
      const name = cache.get(`ConfigRuleTypePage.ruleType.${id}`) || list?.find(item => item.ruleType === id)?.ruleTypeName
      cache.set(`ConfigRuleTypePage.ruleType.${id}`, name)
      return name === ruleTypeName
    }
  }, [formValue, list])

  return (
    <>
      <PageHeader title="规则分类管理" />
      <Card className="mt20">
        <Button type="primary" onClick={() => openModal(ActionType.ADD)}>新增</Button>
        <Table
          className="mt20"
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
        />
      </Card>
      {modalOpen && (
        <Modal
          open
          width={750}
          title={`${actionType}规则分类`}
          onCancel={() => setModalOpen(false)}
          onOk={submitForm}
          okText="提交"
          okButtonProps={{ disabled: disableSubmitButton }}
        >
          <Row className="mt15">
            <Col span={4}>分类名称:</Col>
            <Col span={20}>
              <Input value={formValue?.ruleTypeName} onChange={e => setFormValue({ ...formValue, ruleTypeName: e.target.value })} />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  )
}
export default ConfigRuleTypePage
