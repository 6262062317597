import React, { useCallback, useMemo, useState } from 'react'
import { AutoComplete, AutoCompleteProps, message, Spin } from 'antd'
import { getResList, isEmptyArray, isFunction, ServiceType } from '@library'
import { usePropState } from '@hooks'
import { debounce } from 'lodash'

export interface CommonAutoCompleteProps<F extends object, T extends object> extends Omit<AutoCompleteProps<string| number>, 'onChange' | 'options' | 'children'> {
  // 组件名字，请求数据报错时会用到。
  name?: string
  // 查询参数,
  filter?: Partial<F>
  children?: AutoCompleteProps['children'] | ((loading: boolean, inputVal: string | number, fetchData: (inputVal?: string | number) => void) => AutoCompleteProps['children'])
  // 数据请求接口, 如果是静态数据，可以包装成service，类似于省市地区
  service: ServiceType<F, T>
  // 当输入超过指定长度的时候才开始自动补全，默认为2. 如果不想自动触发补全拉取数据，可以把值设置成负数或0。
  startSearch?: number
  // 覆盖原有的onChange
  onChange?: (inputVal: number | string) => void
  // value对应的值字段名 - 要求T[valueKey]的值是string|number
  valueKey: keyof T
  // value对应显示的text
  labelKey: keyof T | ((item: T, index: number) => React.ReactNode)
  // filter对应的值字段名 - 要求F[searchKey]的值是string|number
  searchKey: keyof F
  // 搜索的时候的placeholder
  placeholderOnSearching?: string
}

/**
 * 公用自动补全
 * 注意： children只能用来自定义输入框，不能设置options，这是和原antd不同的地方
 */
export function CommonAutoComplete<F extends object, T extends object> (props: CommonAutoCompleteProps<F, T>) {
  const {
    name, filter, service, onChange, startSearch, onDropdownVisibleChange, placeholderOnSearching, placeholder, labelKey, valueKey, searchKey, dropdownRender, children, ...otherProps
  } = props

  const [options, setOptions] = useState<AutoCompleteProps['options']>([])

  const [inputVal, setInputVal] = usePropState<string | number>(props.value)
  const onSearch: AutoCompleteProps['onSearch'] = useCallback(async inputVal => {
    if (startSearch <= 0) {
      setOptions([])
      return
    }
    if ((inputVal + '').length <= startSearch) {
      setOptions([])
      return
    }
    await fetchData(inputVal)
  }, [startSearch, setOptions])
  const onSearchDebounce = useMemo(() => debounce(onSearch, 1500, { trailing: true }), [])

  const [open, setOpen] = useState(false)
  const placeholderText = useMemo(() => open ? placeholderOnSearching : placeholder, [placeholderOnSearching, placeholder, open])

  const [fetchLoading, setFetchLoading] = useState(false)
  const fetchData = async (val: string | number = inputVal) => {
    const realFilter = { ...filter, [searchKey]: val } as F
    setFetchLoading(true)
    const [res, err] = await service(realFilter)
    setFetchLoading(false)
    if (err) {
      console.log('err', err)
      message.error(`搜索${name}失败${err?.message}`)
      setOptions([])
      return
    }
    const options: AutoCompleteProps['options'] = getResList(res, [])[0]
      .map((item, index) => ({ label: isFunction(labelKey) ? labelKey(item, index) : item[labelKey], value: item[valueKey] as unknown as string }))

    if (isEmptyArray(options)) message.info(`搜索${name}为空`)
    setOptions(options)
  }

  // children这里不会被渲染成options，只会渲染成自定义输入框
  const realChildren = useMemo(() => isFunction(children) ? children(fetchLoading, inputVal, fetchData) : children, [children, fetchData, fetchLoading, inputVal])

  return (
    <AutoComplete
      {...otherProps}
      options={options}
      onSearch={onSearchDebounce}
      onChange={inputVal => {
        setInputVal(inputVal)
        onChange(inputVal)
      }}
      children={realChildren}
      dropdownRender={originRender => {
        const render = isFunction(dropdownRender) ? dropdownRender(originRender) : originRender
        return <Spin size="small" spinning={fetchLoading}>{render}</Spin>
      }}
      onDropdownVisibleChange={open => {
        setOpen(open)
        if (isFunction(onDropdownVisibleChange)) onDropdownVisibleChange(open)
      }}
      placeholder={placeholderText}
    />
  )
}

CommonAutoComplete.defaultProps = {
  name: '',
  style: { minWidth: '100px' },
  filter: {},
  placeholderOnSearching: '',
  /* origin */
  allowClear: true,
  startSearch: 2,
}
