import { parseDuration } from '@library'
import { CallEndReasonMap, satisfactionResultMap, T3CCallEndReasonMap } from '@configs/map'
import { clone } from 'lodash'

export interface ExpandCallRecordDataType {
  // 是否可以播放录音
  disabled?: boolean
  // 播放录音的按钮上的提示信息
  playTips?: string
  // 通话时长转义
  durationText?: string
  // 通话结果转义
  failReasonText?: string
  // 满意度转义
  satisfactionResultText?: string
}

// 通话时长为-1时表示话单还在生成中
const GENERATING_CALLDATA = -1

export const handleCallRecord = (list: any[]) => list?.map(item => {
  const callRecordData: any = clone(item)
  const isGenerating = item?.duration === GENERATING_CALLDATA

  // 通话时间为-1时，通话时长 展示为空
  callRecordData.durationText = isGenerating ? '' : parseDuration(item?.duration, 's')
  // -1和0 播放按钮隐藏置灰 ，提示文案分别是：“话单生成中，请稍后再试”，“未拨通，无录音”
  if (isGenerating) {
    callRecordData.playTips = '话单生成中，请稍后再试'
    callRecordData.disabled = true
  }
  if (item?.duration === 0) {
    callRecordData.playTips = '未拨通，无录音'
    callRecordData.disabled = true
  }
  // 字段翻译
  callRecordData.failReasonText = CallEndReasonMap[item?.failReason] || ''
  callRecordData.satisfactionResultText = satisfactionResultMap[item?.satisfactionResult] || ''

  return callRecordData
})

export const handleT3CCallRecord = (list: any[]) => list?.map(item => {
  const callRecordData: any = clone(item)
  const isGenerating = item?.duration === GENERATING_CALLDATA

  // 通话时间为-1时，通话时长 展示为空
  callRecordData.durationText = isGenerating ? '话单生成中' : parseDuration(item?.duration, 's')
  // -1和0 播放按钮隐藏置灰 ，提示文案分别是：“话单生成中，请稍后再试”，“未拨通，无录音”
  if (isGenerating) {
    callRecordData.playTips = '话单生成中，请稍后再试'
    callRecordData.disabled = true
  }
  if (item?.duration === 0) {
    callRecordData.playTips = '未拨通，无录音'
    callRecordData.disabled = true
  }
  // 字段翻译
  callRecordData.failReasonText = T3CCallEndReasonMap[item?.failReason] || ''
  callRecordData.satisfactionResultText = satisfactionResultMap[item?.satisfactionResult] || ''

  return callRecordData
})
