/**
 * Session 登录态
 * @author jasonelchen
 */
import { useCallback, useMemo, useState, useEffect } from 'react'
import { message } from 'antd'
import { stringify } from 'qs'
import { useHistory } from 'react-router-dom'
import { useObject, useService } from '@hooks'
import { CompanyInfo, ModuleInfo, StaffisAdmin, StaffisAssign, StaffStatus, UserInfo } from '@types'
import { clearTccToken, getModuleCompanyMapNoSession, getUserInfo as getUserInfoApi, login } from '@services/session'
import { remove } from 'js-cookie'
import { ORIGIN_TCC_TOKEN } from '@configs'
import { injectTcccWebSDK } from 'src/library/t3c'
import { getT3cSdkUrl, getCallDuration } from '@services/t3c'
import { getHotline400Config } from '@services/lead/hotline'
import moment from 'moment'
import { uniqBy } from 'lodash'

export function useSession () {
  const [logging, setLogging] = useState(true)
  const { push } = useHistory()

  const [, moduleRes, , forceRequestModuleCompanyMap] = useService(getModuleCompanyMapNoSession)
  // 这里转换一下数据结构，得到所有的模块列表和公司列表
  const companyList: CompanyInfo[] = useMemo(() => Array.from(moduleRes?.list || []), [moduleRes])
  const moduleList: ModuleInfo[] = useMemo(() => uniqBy(companyList.map(company => {
    const { belongModule, belongModuleName } = company
    return { belongModule, belongModuleName }
  }), 'belongModule'), [companyList])

  const [userInfo, setUserInfo] = useObject<UserInfo>({
    salesName: '',
    salesNameCh: '',
    avatar: '',
    isAdmin: StaffisAdmin.NOTADMIN,
    isAssign: StaffisAssign.NO,
    belongModule: -1,
    companyId: -1,
    createTime: '',
    id: -1,
    lastModifier: '',
    leader: '',
    openid: '-1',
    poolNum: -1,
    qidianWorkNumber: '-1',
    status: StaffStatus.OFFDUTY,
    updateTime: '',
    salesPhone: '',
    weworkShortChain: '',
    useT3C: false,
    useMeeting: false,
  })

  // 当前模块下所有公司
  const currCompanyList: CompanyInfo[] = useMemo(() => companyList.filter(company => company.belongModule === userInfo.belongModule), [companyList, userInfo.belongModule])

  const [t3cReady, setT3cReady] = useState(false)
  const [t3cErrorMsg, setT3cErrorMsg] = useState('')
  const [t3cfailed, setT3cFailed] = useState(false)
  const [curTabUUID, setCurTabUUID] = useState('')
  const [hotline400Config, setHotline400Config] = useState({ list: [] })
  const [callDuration, setCallDuration] = useObject<{ callDuration: string; callTime: number }>({ callDuration: '00:00', callTime: 0 })
  const getTodayCallDuration = async () => {
    const [res, err] = await getCallDuration()
    if (err) {
      message.error(err.message)
      return
    }
    const duration = moment.utc(res?.callDuration * 1000).format(res?.callDuration < 3600 ? 'mm:ss' : 'HH:mm:ss')
    setCallDuration({
      callDuration: duration,
      callTime: res.callTime,
    })
  }
  // 拉取用户信息
  const getUserInfo = useCallback(async () => {
    const [res, err] = await getUserInfoApi()

    if (err) {
      throw err
    }

    setLogging(false)
    setUserInfo(res)
    return res
  }, [])

  // 登录
  const loginWithCode = useCallback(async (code: string, moduleId: number, appId: string, agentId: number, tccCompany: number) => {
    // 清理可能遗留的上次模拟登录的cookie
    remove(ORIGIN_TCC_TOKEN)

    const [, err] = await login(code, moduleId, appId, agentId, tccCompany)

    if (err) {
      throw err
    }

    // 拉取用户信息
    return getUserInfo()
  }, [])

  // 跳转到企业微信登录页面
  const jumpToWorkWechat = useCallback((appId: string, agentId: string, callbackUrl?: string) => {
    const { origin } = window.location
    const query = stringify({
      appid: appId,
      agentid: agentId,
      redirect_uri: `${origin}/login`,
      state: stringify({ callbackUrl }),
    })

    window.location.href = `https://open.work.weixin.qq.com/wwopen/sso/qrConnect?${query}`
  }, [])

  // 登出
  const logout = useCallback(async () => {
    // 静默失败
    await clearTccToken()
    message.success('退出登录成功')
    return push(`/login?${stringify({ callbackUrl: window.location.pathname + window.location.search })}`)
  }, [])

  const initT3cSdk = async (params?: { refresh: boolean }) => {
    setT3cReady(false)
    const [sdkUrl, err] = await getT3cSdkUrl(params)
    if (sdkUrl) {
      injectTcccWebSDK(sdkUrl.sdkUrl, () => {
        // 加载JS SDK文件成功，此时可使用全局变量"tccc"
        window.tccc.UI.hidefloatButton()
        window.tccc.on(window.tccc.events.ready, ({ tabUUID }) => {
          setCurTabUUID(tabUUID)
          setT3cReady(true)
          setT3cFailed(false)
          setT3cErrorMsg('')
          console.log('T3C SDK 初始化成功，当前客服状态：', window.tccc.Agent.getStatus(), 'tabUUID:', tabUUID)
        })
        window.tccc.on(window.tccc.events.tokenExpired, ({ message: errMsg, tabUUID }) => {
          console.error('初始化失败:', errMsg)
          message.error(`TCCC error: ${errMsg}`)
          setT3cErrorMsg(errMsg)
          setT3cReady(true)
          setT3cFailed(true)
          console.log('T3C SDK 初始化失败. tabUUID:', tabUUID)
        })
        try {
          window.tccc.on(window.tccc.events.kickedOut, () => {
            message.error('当前坐席在其他地方登录')
            console.log('当前坐席在其他地方登录')
          })
        } catch (error) {
          console.log('T3C SDK kickedOut error:', error)
        }
      })
    } else {
      message.error(err.message)
    }
  }
  const getHotline400ConfigOnce = async () => {
    const [config] = await getHotline400Config()
    setHotline400Config(config)
  }

  useEffect(() => {
    if (t3cReady) {
      window.tccc.on(window.tccc.events.userAccessed, (res) => {
        console.log('来电显示', res, 'hotline400Config:', hotline400Config)
        const result = hotline400Config?.list.find(item => item.hotline === res.calleePhoneNumber)
        // 如果来源是乐享或电子签，跳转到通用400
        if (res.tabUUID === curTabUUID && result && result.redirect === 1) {
          window.open(`/lead/hotline/clue-collect/400?qd_tel=${res.callerPhoneNumber}&qd_calll=t3c-${res.sessionId}&qd_kfext=${userInfo.email}&from=${result.from}`)
        }
      })
      window.tccc.on(window.tccc.events.sessionEnded, () => {
        console.log('通话结束')
        setTimeout(() => {
          getTodayCallDuration()
        }, 2000)
        setTimeout(() => {
          getTodayCallDuration()
        }, 30000)
      })
    }
  }, [t3cReady, userInfo])
  // 判断当前用户所属模块是否是参数所属模块
  const isPermissableBelongModule = useCallback((moduleIds: number[]) => {
    return moduleIds.some(moduleId => moduleId === userInfo.belongModule)
  }, [userInfo])
  return {
    logging,
    logged: !logging,
    userInfo,
    setUserInfo,
    moduleList, // 所有模块列表
    companyList, // 所有公司列表，注意，不是当前
    currCompanyList, // 当前所有公司列表
    loginWithCode,
    logout,
    jumpToWorkWechat,
    getUserInfo,
    forceRequestModuleCompanyMap, // 强制刷新moduleRes
    initT3cSdk,
    t3cReady,
    hotline400Config,
    setHotline400Config,
    getHotline400ConfigOnce,
    t3cfailed,
    t3cErrorMsg,
    callDuration,
    getTodayCallDuration,
    isPermissableBelongModule,
  }
}
