import React, { useState } from 'react'
import {
  Card,
  Typography,
  Tag,
  Space,
  Button,
  Divider,
  Modal,
  Row,
  Col,
  Input,
  Empty,
  message,
} from 'antd'
import { TagOutlined } from '@ant-design/icons'
import { AddKeyWordAPI, DeleteKeyWordAPI } from '@services/quality-inspection'
import { KeyWordItemType, KeyWordTypeEnum } from '@types'

interface PropsType {
  onUpdate: () => void
  keyWordList: KeyWordItemType[]
  type: KeyWordTypeEnum
  maxKeyWordCnt: number
};

const titleMap = {
  [KeyWordTypeEnum.client]: '客户关键词',
  [KeyWordTypeEnum.customerService]: '客服关键词',
}

const defOkButtonProps = { loading: false, disabled: false }

const KeyWordItem: React.FC<PropsType> = (props) => {
  const { onUpdate, keyWordList = [], type, maxKeyWordCnt = 50 } = props

  const [isOpen, setIsOpen] = useState(false)
  const [keyWord, setKeyWord] = useState('')
  const [okButtonProps, setOkButtonProps] = useState(defOkButtonProps)

  /**
   * 更新关键词
   * @param id 关键词id(新增为空)
   * @param uType 新增 / 删除
   */
  const handleUpdateKeyWord = async (uType: 'add' | 'del', id?: number) => {
    if (uType === 'add' && !keyWord) {
      message.error(`${titleMap[type]}不能为空！`)
      return
    }
    let err
    setOkButtonProps({ loading: true, disabled: true })
    if (uType === 'add') {
      [, err] = await AddKeyWordAPI({ value: keyWord, type })
    } else {
      [, err] = await DeleteKeyWordAPI(id)
    }
    setOkButtonProps(defOkButtonProps)
    if (err) {
      message.error(err.message)
    } else {
      message.success(`${uType === 'add' ? '新增' : '删除'}${titleMap[type]}成功`)
      handleCancel()
      onUpdate()
    }
  }

  /**
   * 关闭新增弹窗 - 重置数据
   */
  const handleCancel = () => {
    setIsOpen(false)
    setKeyWord('')
    setOkButtonProps(defOkButtonProps)
  }

  return (
    <div className="key-word-item">
      <Card
        title={
          <Space size="large">
            <Typography.Text strong style={{ fontSize: 14 }}>{titleMap[type]}</Typography.Text>
            <Button type="primary" disabled={keyWordList.length === maxKeyWordCnt} onClick={() => setIsOpen(true)}>新建{`（${keyWordList.length}/${maxKeyWordCnt}）`}</Button>
          </Space>
        }
        bordered={false}
      >
        {
          keyWordList.length
            ? keyWordList.map(({ value, id }) => {
              return (
                <Tag
                  className="key-word-tag"
                  icon={<TagOutlined rotate={-90} />}
                  color="#ececec"
                  key={id}
                  closable
                  onClose={() => handleUpdateKeyWord('del', id)}
                >
                  <Typography.Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: value }}>{value}</Typography.Text>
                </Tag>
              )
            })
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </Card>
      <Divider />
      <Modal
        maskClosable={false}
        keyboard={false}
        title={`新建${titleMap[type]}`}
        open={isOpen}
        onOk={() => handleUpdateKeyWord('add')}
        okButtonProps={okButtonProps}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={8}>关键词名称：</Col>
          <Col span={16}>
            <Input
              value={keyWord}
              onChange={e => setKeyWord(e.target.value.trim())}
              placeholder="请输入关键词名称"
              maxLength={128}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default KeyWordItem
