/**
 * 通话记录
 * @author fredhaochen
 */
import React, { useMemo, useState, useEffect } from 'react'
import { PageHeader } from '@components'
import { Tabs } from 'antd'
import store from '@store'
import QidianCallRecord from './components/QidianCallRecord'
import TCCCCallRecord from './components/TCCCCallRecord'
import MeetingCallRecord from './components/MeetingCallRecord'
import qs from 'qs'

export default function CallRecordPage () {
  const { userInfo } = store.useSession()
  const { tabKey } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [activeKey, setActiveKey] = useState('qidian')

  // 使用useMemo优化，只有当userInfo.useT3C或userInfo.useMeeting变化时才重新计算tabItems
  const tabItems = useMemo(() => {
    // 准备Tabs项目，始终包含企点通话记录
    const items = [
      {
        key: 'qidian',
        label: '企点通话记录',
        children: <QidianCallRecord />,
      },
    ]

    // 当useT3C为true时，添加TCCC通话记录Tab
    if (userInfo.useT3C) {
      items.push({
        key: 'tccc',
        label: 'TCCC通话记录',
        children: <TCCCCallRecord />,
      })
    }

    // 当useMeeting为true时，添加腾讯会议记录Tab
    if (userInfo.useMeeting) {
      items.push({
        key: 'meeting',
        label: '腾会通话记录',
        children: <MeetingCallRecord />,
      })
    }

    return items
  }, [userInfo.useT3C, userInfo.useMeeting])

  useEffect(() => {
    if (tabKey) {
      const tabKeyList = tabItems.map(item => item.key)
      const flag = tabKeyList.includes(tabKey as string)
      setActiveKey(flag ? tabKey as string : 'qidian')
      const urlObj = new URL(location.href)
      urlObj.searchParams.delete('tabKey')
      window.history.replaceState({}, '', urlObj.toString())
    }
  }, [tabKey, tabItems])

  return (
    <PageHeader title="通话记录" footer={(
      <Tabs
        activeKey={activeKey}
        items={tabItems}
        onChange={key => {
          setActiveKey(key)
        }}
      />
    )} />
  )
}
