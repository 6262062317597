/** 获取质检方案[列表/详情]接口参数type */
export interface GetQualityCheckPlanListParamsType {
  page: number,
  pageSize: number,
  /** 质检方案id */
  id?: number,
  /** 质检方案名称 */
  name?: string,
}

/** 质检项 */
export interface DimensionsItemsItemType {
  /** 质检项名称 */
  name: string,
  /** 要求 - 非必填 */
  requirement: string,
  /** 分数 */
  score: number | string,
  /** 质检项ID - 根据 uuid 生成 - 方便新增/编辑质检方案 */
  subId?: string,
}

/** 质检维度 */
export interface DimensionsItemType {
  /** 质检维度名称 */
  name: string,
  /** 质检项列表 */
  items: DimensionsItemsItemType[],
  /**  质检维度ID - 根据 uuid 生成 - 方便新增/编辑质检方案 */
  id?: string,
  /** 质检项分值总和 - 方便新增/编辑质检方案 */
  itemsTotalScore?: number,
}

/** 质检方案列表 */
export interface QualityCheckPlanItemType {
  createTime: string,
  creator: string,
  /** 评分项 */
  dimensions: DimensionsItemType[],
  id: number,
  /** 方案名称 */
  name: string,
  /** 合格分数标准 */
  qualifiedScore: number,
  /** 备注 */
  remark:string,
  status: number,
  updateTime: string,
  updator: string,
}

/** 创建/质检方案接口参数type */
export interface CreateOrUpdateualityCheckPlanParamsType {
  /** 方案名称 */
  name: string,
  /** 合格分数标准 */
  qualifiedScore: number | string,
  /** 评分项 */
  dimensions: {
    name: string,
    items: Omit<DimensionsItemsItemType, 'subId'>[]
  }[],
  /** 方案ID，更新时必传 */
  id?: number,
  /** 备注 */
  remark?: string,
}
export enum KeyWordTypeEnum {
  /** 客服关键词 / 坐席 */
  customerService = 1,
  /** 客户关键词 */
  client = 2,
}

/** 添加关键词 */
export interface AddKeyWordParamsType {
  /** 关键词 */
  value: string,
  /** 类型 1 客服关键词 2 客户关键词 */
  type: KeyWordTypeEnum,
}

export interface KeyWordItemType {
  /** 关键词 */
  value: string,
  /** 类型 1 客服关键词 2 客户关键词 */
  type: 1 | 2,
  /** 关键词ID */
  id: number,
}

/** 根据通话记录ID查询质检结果 接口参数type */
export interface QualityCheckResultResType {
  id?: number,
  callRecordId: number,
  name: string,
  dimensionsScore: {
    name: string,
    /** 前端生成 */
    id: string,
    /** 评分项分值总和 - 接口返回的 */
    itemsTotalScoreFromAPI: number,
    /** 评分项分值总和 - 用户输入评分后计算的 */
    itemsTotalScore: number,
    items: {
      name: string,
      score: number,
      /** 前端生成 */
      subId: string,
      /** 前端生成 - 将接口返回的score赋值给subScore，将用户填写的数值同步到score - 方便提交处理 */
      subScore: number
    }[],
  }[],
  qualityCheckPlanId: number,
  remark: string,
  qualifiedScore: number,
  score: number,
}

/** 提交质检结果 接口参数type */
export interface AddQualityCheckResultParamsType {
  /** 质检方案ID */
  qualityCheckPlanId: number,
  /** 分值 */
  score: number,
  /** 评分项列表 */
  dimensionsScore: {
    /** 质检维度名称 */
    name: string,
    /** 质检项列表 */
    items: {
      /** 质检项名称 */
      name: string
      /** 分值 */
      score: number
    }[],
  }[],
  /** 通话记录ID */
  callRecordId: number,
  /** 备注 */
  remark: string
}

export enum CallEventEnum {
  Start = 'Start',
  ClientUserRinging = 'ClientUserRinging',
  ClientUserAccepted = 'ClientUserAccepted',
  StaffEnded = 'StaffEnded',
  End = 'StaffEnded',
}

export enum CallEventTextEnum {
  Start = '呼出',
  ClientUserRinging = '振铃',
  ClientUserAccepted = '被叫接入',
  StaffEnded = '挂机',
  End = '系统挂机',
}

/** 音频转文本 */
export interface SpeechTextItemType {
  /** 结束时间 - 毫秒 */
  End: number,
  Message: string,
  /** 开始时间 - 毫秒 */
  Start: number,
  /** 标准开始时间 */
  standardTime?: string,
  /** 标准开始时间 */
  startStandardTime?: string,
  /** 标准结束时间 */
  endStandardTime?: string,
  User: string,
  /** 和通话开始时间的时间差 HH:mm:ss */
  diffTime?: string,
  /** 和通话开始时间的时间差 - 秒 */
  diffSeconds?: number,
  /** 文本开始时间 */
  startDiffSeconds?: number,
  /** 文本结束时间 */
  endDiffSeconds?: number,
  /** 是否为坐席/客服 */
  isCustomerService: boolean,
}

/** T3C通话详情 */
export interface T3CCallRecordDetailType {
  /** 通话音频最长时间 */
  audioMaxS?: number,
  /** 通话语音转文本地址 */
  asrUrl: string,
  belongModule: number,
  blackListType: number,
  callBeginTime: string,
  callEndTime: string,
  /** 时间轴 */
  callEventList: {
    /** 事件 */
    event: CallEventEnum,
    /** 事件文本 */
    eventText: string,
    /** 时间戳 - 单位 秒 */
    timestamp: string,
    /** 标准时间 - 前端做数据处理 */
    standardTime: string,
    /** 时间差值 */
    diffTime: string,
    startDiffSeconds: number,
    endDiffSeconds: number,
  }[],
  callId: string,
  callNumber: string,
  cid: string,
  createTime: string,
  customerId: number,
  customerKeywordList: Array< unknown >,
  customerName: string,
  duration: number,
  /** 通话是否成功 1 - 成功 */
  failReason: number,
  keywordMatchStatus: number,
  phone: string,
  recordId: number,
  /** 通话录音地址 */
  recordUrl: string,
  salesKeywordList: Array< unknown >,
  salesName: string,
  salesNameCh: string,
  satisfactionResult: number,
  /** 语音文本列表 */
  speechTextList: SpeechTextItemType[]
}

export interface KayWordFilter {}
