/**
 * 本地缓存
 * @author jasonelchen
 */
import { isEmptyWithCustom } from './isTypeOf'

export const sStorage = {
  // 存储
  set (key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  // 取出数据
  get<T> (key: string): T {
    try {
      const value = sessionStorage.getItem(key)
      if (value === null || value === undefined || value === '') {
        return null
      }
      return JSON.parse(sessionStorage.getItem(key))
    } catch (err) {
      return null
    }
  },
  // 删除数据
  remove (key: string) {
    sessionStorage.removeItem(key)
  },
}

export const lStorage = {
  // 存储
  set (key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  // 取出数据
  get<T> (key: string): T {
    try {
      const value = localStorage.getItem(key)
      if (isEmptyWithCustom(value)) {
        return null
      }
      return JSON.parse(localStorage.getItem(key))
    } catch (err) {
      return null
    }
  },
  // 删除数据
  remove (key: string) {
    localStorage.removeItem(key)
  },
}
