import React, { Children, cloneElement, PropsWithChildren, useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { SettingOutlined, CloseCircleFilled, MenuOutlined } from '@ant-design/icons'
import { Alert, Checkbox, Col, message, Modal, Row, Table, Button, Typography } from 'antd'
import { ColumnHeader, TableIdEnum } from '@configs/table'
import { UpdateTableFieldsInfo } from '@services/session'
import { usePropState } from '@hooks'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { sStorage } from '@library'
import store from '@store'
import './style.less'
import { cloneDeep } from 'lodash'

interface ConfigTableHeaderProps<T> {
  className?: string
  tableId: TableIdEnum
  columnConfigs: ColumnHeader[]
  onSuccess: Function // 更新之后的回调，一般用作去拉新的数据
}

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = 'DraggableBodyRow'

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  )
}

/**
 * 设置表头
* @param props
* @constructor
*/
export function ConfigTableHeaderSort<T> (props: PropsWithChildren<ConfigTableHeaderProps<T>>) {
  const { className, columnConfigs = [], tableId, onSuccess, children, ...otherProps } = props
  const { userInfo } = store.useSession()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [configs, setConfigs] = usePropState(columnConfigs)
  /** 已选字段 */
  const selectedField = useMemo(() => configs?.filter(c => !c.hide), [configs])
  /** [缓存表头对应key, 缓存表头] */
  const [sessionKey, defHeaders] = useMemo(() => {
    const key = `${userInfo.belongModule}.${userInfo.companyId}.${userInfo.salesName}.${tableId}`
    const sessionHeaders = (sStorage.get<ColumnHeader[]>(key) ?? configs)?.sort((a, b) => a.index - b.index)
    const defHeaders = sessionHeaders?.map(item => ({ ...item, hide: item.defHide }))
    return [key, defHeaders]
  }, [tableId, userInfo])

  const onChange = useCallback((index: number, checked: boolean) => {
    setConfigs(prevHeaders => {
      const temp = cloneDeep(prevHeaders)
      temp.splice(index, 1, { ...prevHeaders[index], hide: !checked })
      return temp
    })
  }, [setConfigs])

  useEffect(() => {
    setShowWarning(configs.filter(c => !c.hide)?.length <= 3)
  }, [configs])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    const fields = configs?.filter(header => header.hide)?.map(ch => ({ key: ch.dataIndex }))
    const [, err] = await UpdateTableFieldsInfo({ fieldsUsage: tableId, fields })
    setLoading(false)
    if (err) {
      message.error('设置列表表头显示字段失败，:' + err.message)
    } else {
      sStorage.set(`${sessionKey}_SORT`, configs.map(i => i.dataIndex))
      setOpen(false)
      onSuccess()
    }
  }, [configs, onSuccess])

  /**
   * 拖拽排序
   */
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = selectedField[dragIndex]
      dragIndex = configs.findIndex(item => item.dataIndex === dragRow.dataIndex)
      setConfigs(v => {
        return update(v, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      })
    },
    [selectedField, configs],
  )

  const renderChildren = () => {
    if (!children) {
      return (
        <SettingOutlined
          onClick={() => setOpen(true)}
          style={{ cursor: 'pointer' }}
          className={className}
        />
      )
    } else {
      // 只能有一个子元素
      Children.only(children)

      return cloneElement(children as React.ReactElement, { ...otherProps, onClick: () => setOpen(true) })
    }
  }

  /**
   * 关闭弹窗，重置configs数据
   */
  const onCancel = () => {
    setOpen(false)
    setConfigs(columnConfigs)
  }

  /** 全选/取消全选 */
  const handleCheckAll = () => {
    const isCheckAll = selectedField?.length === configs?.length
    setConfigs(v => {
      v.forEach(item => {
        // 对禁用列不进行操作
        item.hide = !item.disabled && isCheckAll
      })
      return [...v]
    })
  }

  /** 恢复默认 */
  const handleRestoreDef = () => {
    setConfigs(defHeaders)
  }

  return (
    <>
      {renderChildren()}
      <Modal
        open={open}
        width={800}
        title="自定义列表字段"
        onCancel={onCancel}
        onOk={onSubmit}
        okButtonProps={{ loading }}
        className="customer-header-modal"
      >
        <div className="customer-header-modal-body">
          <div className="table-container">
            <Typography.Text strong>已选择段（{selectedField?.length}）</Typography.Text>
            <div className="table-container-content">
              <DndProvider backend={HTML5Backend}>
                <Table
                  pagination={false}
                  dataSource={selectedField}
                  showHeader={false}
                  rowKey="index"
                  columns={[
                    {
                      title: '表头',
                      dataIndex: 'title',
                      render: (title, record) => (
                        <div className="sort-header">
                          <span>
                            <MenuOutlined style={{ marginRight: 10 }} />
                            <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: title }}>{title}</Typography.Text>
                          </span>
                          {!record.disabled && <CloseCircleFilled onClick={() => console.log(record)} />}
                        </div>
                      ),
                    },
                  ]}
                  components={{
                    body: {
                      row: DraggableBodyRow,
                    },
                  }}
                  onRow={(_, index) => {
                    const attr = { index, moveRow }
                    return attr as React.HTMLAttributes<any>
                  }}
                />
              </DndProvider>
            </div>
          </div>
          <div className="checkbox-container">
            <div className="title">
              <Typography.Text strong>可选列表字段</Typography.Text>
              <div>
                <Button type="link" onClick={handleCheckAll}>{`${selectedField?.length === configs?.length ? '取消' : ''}全选`}</Button>
                <Button className="restore-default" type="link" onClick={handleRestoreDef}>恢复默认选项</Button>
              </div>
            </div>
            <div className="checkbox-container-content">
              <Row>
                {configs?.map((header, index) => (
                  <Col span={8} key={index}>
                    <Checkbox
                      key={header.dataIndex}
                      onChange={e => onChange(index, e.target.checked)}
                      disabled={header.disabled}
                      checked={!header.hide}
                    >
                      {header.title}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
        {showWarning && <Alert className="mt10" message="当展示列数特别少时，列表显示可能会出现渲染错位问题" type="warning" showIcon closable />}
      </Modal>
    </>
  )
}
