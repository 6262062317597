import { Pagination } from './common'

export enum RobotTaskStatusEnum {
  WAITING = 0, // 待执行
  RUNNING = 1, // 筛选中
  SUCCEED = 2, // 创建成功
  FAIL = 3, // 创建失败
  DONE = 4, // 执行成功
}

export interface RobotItem {
  concurrentCount: number // 并发数
  id: string // 机器人ID
  name: string // 机器人名称
  status: number // 机器人状态
}

export interface RobotTaskItem {
  executeType: number
  concurrentCount: number // 并发数
  taskId: string // 机器人任务ID
  taskName: string // 机器人任务名称
  status: number // 机器人任务状态
}

export enum RobotFilterTypeEnum {
  LEAD = 1, // 线索
  CUSTOMER = 2, // 客户
  BATCH_UPLOAD = 3, // 批量上传
}

export interface CreateRobotCallRollParams {
  excludeNamedCustomer: boolean // 是否过滤有效跟进人
  taskId: string // 机器人Id
  rollTitle: string // 任务名称
  rollDescription?: string // 任务描述
  filterType: RobotFilterTypeEnum // 过滤类型，1线索、2客户
  filterCondition: string // 过滤条件，json string，线索列表的过滤条件或者客户列表的过滤条件
  filterRestriction: {
    callOutDay: number,
    answeredDay: number,
    publicTags: number[]
  }
}

export interface ListCallRollTitleFilter extends Pagination {
  rollTitle?: string // 搜索名称
}

export interface ListCallRollTitleItem {
  id: number,
  title: string
}

export interface ListRobotCallRollFilter {
  page: number // 页码 required
  pageSize: number // 页大小 required
  creator?: string // 创建人
  id?: number // 任务id
  status?: RobotTaskStatusEnum // 任务状态
}

export interface ListRobotCallRollItem {
  filterCondition: string
  sourceType: any
  failCidFilePath(failCidFilePath: any): void
  createTime: string // 创建时间
  creator: string // 创建人
  description: string // 名单描述
  id: number // 名单ID
  title: string // 名单名称
  remark: string // 名单详情
  status: RobotTaskStatusEnum // 名单状态
}

export interface ListCallTagFilter {
  page: number // 页码 required
  pageSize: number // 页大小 required
  tag?: string // 搜索标签
}

export interface ListRobotCallRecordFilter {
  page: number // 页码 required
  pageSize: number // 页大小 required
  callNumber?: string // 主叫号码
  cid?: string[] // cid
  createTimeFrom?: string // 起始创建时间
  createTimeTo?: string // 结束创建时间
  durationFrom?: number // 起始通话时长
  durationTo?: number // 结束通话时长
  phone?: string // 被叫电话
  robotId?: string // 机器人ID
  satisfactionResult?: number // 满意度
  tags?: string[] // 标签
  taskId?: number // 任务ID
  failReason?: number // 通话结果
}

export interface RobotCallRecordItem {
  belongModule: number // 所属模块
  callBeginTime: string // 通话开始时间
  callEndTime: string // 通话结束时间
  callNumber: string // 外呼主号
  cid: string // cid
  createTime: string // 创建时间
  customerName: string // 客户名称
  duration: number // 通话时长
  failReason: number // 接通状态
  phone: string // 被叫号码
  qidianCallId: string // 企点callId
  recordId: number // 呼叫ID
  robotId: string // 机器人ID
  robotName: string // 机器人名称
  satisfactionResult: number // 满意度
  tags: string[] // 标签
  taskId: number // 任务ID
  taskName: string // 任务名称
  customerId: number // 客户ID
  callCount: number // 呼叫次数
}

export type SaleProductItem = {
  name: string; // 'iPhone 13',
  cnName: string; // '苹果手机13',
  minPrice: number; // 5000
};

export type MarketingWordsItem = {
  content: string; // 'iPhone 13',
  id?: number;
  createTime: string; // 话术创建日期
};

export interface ActivityListWithMarketingWordsRecordItem {
  name: string; // '测试',
  tradeName: string; // '测试活动',
  pageUrl: string; //  'https://www.tmall.com/activity/123456',
  programPageUrl: string; // 'https://www.tmall.com/activity/detail/123456',
  pageType: number; // 1,
  activityId: number;
  startTime: string; // '2023-11-01 00:00:00',
  endTime: string; // '2023-11-11 23:59:59',
  description: string; // '双11大促活动，全场商品五折优惠',
  saleProduct: SaleProductItem[];
  marketingWords: MarketingWordsItem[];
}
