import { requestApiV2 } from '@library'
import {
  CommonList,
  QualityCheckPlanItemType,
  GetQualityCheckPlanListParamsType,
  CreateOrUpdateualityCheckPlanParamsType,
  AddKeyWordParamsType,
  KeyWordItemType,
  QualityCheckResultResType,
  AddQualityCheckResultParamsType,
  T3CCallRecordDetailType,
} from '@types'

/**
 * 获取质检列表
 * 有id则获取单个质检方案详情
 */
export const GetQualityCheckPlanListAPI = (params: GetQualityCheckPlanListParamsType) => (
  requestApiV2<CommonList<QualityCheckPlanItemType>>('trpc.tcc.tcc_main.QualityCheck/GetQualityCheckPlanList', params)
)

/** 删除质检方案 */
export const DeleteQualityCheckPlanAPI = (id: number) => (
  requestApiV2('trpc.tcc.tcc_main.QualityCheck/DeleteQualityCheckPlan', { id })
)

/** 创建质检方案 */
export const CreateQualityCheckPlanAPI = (params: CreateOrUpdateualityCheckPlanParamsType) => (
  requestApiV2('trpc.tcc.tcc_main.QualityCheck/CreateQualityCheckPlan', params)
)

/** 编辑质检方案 */
export const UpdateQualityCheckPlanAPI = (params: CreateOrUpdateualityCheckPlanParamsType) => (
  requestApiV2('trpc.tcc.tcc_main.QualityCheck/UpdateQualityCheckPlan', params)
)

/** 获取关键词列表 */
export const GetKeyWordListAPI = () => (
  requestApiV2<CommonList<KeyWordItemType>>('trpc.tcc.tcc_main.QualityCheck/GetKeyWordList')
)

/** 添加关键词 */
export const AddKeyWordAPI = (params: AddKeyWordParamsType) => (
  requestApiV2('trpc.tcc.tcc_main.QualityCheck/AddKeyWord', params)
)

/** 删除关键词 */
export const DeleteKeyWordAPI = (id: number) => (
  requestApiV2('trpc.tcc.tcc_main.QualityCheck/DeleteKeyWord', { id })
)

/** 查询质检结果 */
export const GetQualityCheckResultAPI = (callRecordId: number) => (
  requestApiV2<CommonList<{ result: QualityCheckResultResType }>>('trpc.tcc.tcc_main.QualityCheck/GetQualityCheckResult', { callRecordId })
)

/** 提交质检结果 */
export const AddQualityCheckResultAPI = (params: AddQualityCheckResultParamsType) => (
  requestApiV2('trpc.tcc.tcc_main.QualityCheck/AddQualityCheckResult', params)
)

/** 获取T3C通话详情 */
export const GetT3CCallRecordDetailAPI = (recordId: number) => (
  requestApiV2<T3CCallRecordDetailType>('trpc.tcc.tcc_main.Call/GetT3CCallRecordDetail', { recordId })
)
