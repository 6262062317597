import React from 'react'
import { useCallRecordDetail } from '../useCallRecordDetail'
import { Tooltip, Typography, Button, Timeline, Popover, Empty } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import '../style.less'

interface PropsType {
  disabled: boolean
  playTips: string
  recordId: string | number
}

const T3CTimeLineBtn: React.FC<PropsType> = (props) => {
  const { disabled, playTips, recordId } = props
  const { callRecordDetail, getT3CCallRecordDetail, open, setOpen, loading } = useCallRecordDetail()

  return (
    <>
      {disabled ? (
        <Tooltip title={playTips}>
          <Button
            disabled={disabled}
            style={{ padding: 0 }}
            type="link"
          >
            时间线
          </Button>
        </Tooltip>
      ) : (
        <Popover
          open={open}
          onOpenChange={() => setOpen(false)}
          overlayClassName="call-record-time-line-pop"
          content={
            <>
              {callRecordDetail?.callEventList?.length ? (
                <Timeline mode="left">
                  {callRecordDetail?.callEventList?.map((item, index) => (
                    <Timeline.Item key={index} label={item.standardTime}>
                      <Typography.Text ellipsis={{ tooltip: item.eventText }}>
                        {item.eventText}
                      </Typography.Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (<Empty />)}
            </>
          }
          title={null}
          trigger="click"
          arrowPointAtCenter
        >
          {loading ? (
            <LoadingOutlined style={{ cursor: 'pointer', color: '#1890ff' }} />
          ) : (
            <Button
              type="link"
              disabled={disabled}
              style={{ padding: 0 }}
              onClick={() => getT3CCallRecordDetail(recordId)}
            >
              时间线
            </Button>)}
        </Popover>
      )}
    </>
  )
}

export default T3CTimeLineBtn
