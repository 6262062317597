import React, { useState, useEffect, useMemo } from 'react'
import { Tabs, Popover, Input, Button, Form, message, Spin, Tree } from 'antd'
import './style.less'
import { getCustomerPhoneNumber, getSkillGroupNumberList, dismissSalesMeeting } from '@services/customer'
import { DataNode } from 'antd/lib/tree'
import { PhoneOutlined } from '@ant-design/icons'
import store from '@store'
import './ExternalCall.less'
import { getStaffName } from '@library'
import { moduleNameMapping } from '@configs/constants'

interface CustomTreeNode extends DataNode {
  parentSkillGroupId?: number;
}
const ExternalCall = () => {
  const { t3cReady, t3cErrorMsg, t3cfailed, initT3cSdk } = store.useSession()
  const { userInfo } = store.useSession()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState<string>('external-call')
  const [phoneData, setPhoneData] = useState<{
    lastCaller: string;
    list: Array<{
      number: string[];
      skillGroupId: number;
    }>;
  }>()
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])
  const [selectedPhone, setSelectedPhone] = useState<{
    skillGroupId: number | null
    phone: string | null
  }>({
    skillGroupId: null,
    phone: null,
  })
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState<string>('')

  useEffect(() => {
    setSelectedKeys([])
    setSelectedPhone({
      skillGroupId: null,
      phone: null,
    })
    setSearchText('') // 重置搜索文本
  }, [visible])
  const handleT3CCall = async (values: any, extraCallParam = {}) => {
    if (!t3cReady) {
      message.error('tccc未就绪,请稍后再试')
      return
    }
    if (t3cfailed) {
      message.error(`T3C 失败: ${t3cErrorMsg}`)
      return
    }
    console.log('当前坐席状态:', window.tccc?.Agent?.getStatus())
    if (window.tccc?.Agent?.getStatus() === 'busy') {
      message.error('正在呼叫中，请稍后再试')
      return
    }
    if (window.tccc?.Agent?.getStatus() === 'offline' && t3cReady) {
      initT3cSdk({ refresh: true })
      message.success('当前坐席已在其他地方登录，正在重新登录，请稍后再试')
      console.log('当前坐席已在其他地方登录，正在重新登录，请稍后再试')
      // message.error('已在 T3C（https://tccc.qcloud.com/ ）登录，请在 T3C 退出登录后，刷新当前页面重新尝试')
      return
    }
    const [, dismissErr] = await dismissSalesMeeting()
    if (dismissErr) {
      message.error(`结束当前会议失败(${dismissErr.message})`)
      return
    }

    const [res, err] = await getCustomerPhoneNumber({ phone: values.phone })
    if (err) {
      message.error(err.message)
      return
    }

    try {
      console.log('呼叫参数:', {
        phoneNumber: res?.phone,
        uui: JSON.stringify({ recordId: res?.recordId?.toString() }),
        ...extraCallParam,
      })
      // eslint-disable-next-line no-unused-expressions
        window.XSY_TRACK?.trackClick('tccc_call', {
          content: {
            belong_module: userInfo?.belongModule,
            belong_module_name: moduleNameMapping[userInfo?.belongModule],
            follower: getStaffName(userInfo.salesName, userInfo.salesNameCh),
            tccc_call_tabUUID: window?.tccc?.uuid,
            tccc_call_recordId: res?.recordId?.toString(),
            tccc_call_phone: res?.phone,
            tccc_call_from: 'workspace_external_call',
          },
        })
        await window.tccc?.Call?.startOutboundCall({
          phoneNumber: res?.phone,
          uui: JSON.stringify({ recordId: res?.recordId?.toString() }),
          ...extraCallParam,
        })
        setVisible(false)
        form.resetFields()
        setSelectedKeys([])
        setPhoneData(null)
    } catch (e) {
      message.error(`T3C呼叫客户失败(${e?.message})`)
      console.log('T3C呼叫客户失败', e)
    }
  }

  const handleCall = () => {
    form.validateFields().then(values => handleT3CCall(values))
  }

  const handleSpecifiedCall = () => {
    form.validateFields().then(values => {
      const skillGroup = selectedPhone.skillGroupId
      const phone = selectedPhone.phone
      handleT3CCall(values, {
        callerPhoneNumber: phone,
        skillGroupId: skillGroup?.toString(),
      })
      setSearchText('') // 重置搜索文本
    })
  }

  const treeData: DataNode[] = useMemo(() => {
    if (!phoneData) return []

    return phoneData.list.map(item => {
      // 过滤符合搜索条件的电话号码
      const filteredNumbers = searchText
        ? item.number.filter(num => num.includes(searchText))
        : item.number

      // 如果该技能组下没有符合条件的号码，则不显示该技能组
      if (filteredNumbers.length === 0) {
        return null
      }

      return {
        title: `技能组${item.skillGroupId}`,
        key: item.skillGroupId,
        selectable: false,
        children: filteredNumbers.map(num => ({
          title: num,
          key: `${item.skillGroupId}-${num}`,
          parentSkillGroupId: item.skillGroupId,
          isLeaf: true,
          selectable: true,
        })),
      }
    }).filter(Boolean) // 过滤掉null值
  }, [phoneData, searchText])

  const loadPhoneData = async () => {
    setLoading(true)
    const [res, err] = await getSkillGroupNumberList()
    setLoading(false)

    if (err) {
      message.error(`获取电话数据失败: ${err.message}`)
      return
    }

    setPhoneData(res)
    setSearchText('') // 重置搜索文本
  }
  const phoneContent = (
    <div style={{ width: 250 }}>
      <Tabs
        activeKey={activeTabKey}
        onTabClick={(key) => {
          setActiveTabKey(key)
          if (key === 'specified-call') {
            loadPhoneData()
          } else {
            setSearchText('') // 切换到其他Tab时重置搜索文本
          }
        }}
      >
        <Tabs.TabPane tab="外呼" key="external-call">
          <div>
            <Form form={form} validateTrigger={[]}>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: '请输入电话号码' },
                ]}
                className="text-center"
              >
                <Input
                  size="large"
                  bordered={false}
                  placeholder="请输入外呼号码"
                  style={{ textAlign: 'center' }}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Form.Item
                  noStyle
                  shouldUpdate
                >
                  {({ getFieldValue, getFieldError }) => {
                    const phone = getFieldValue('phone')
                    const hasError = getFieldError('phone').length > 0
                    const isValid = !!phone

                    return (
                      <Button
                        type="primary"
                        onClick={handleCall}
                        shape="round"
                        disabled={!isValid || hasError}
                      >
                        拨打电话
                      </Button>
                    )
                  }}
                </Form.Item>
              </Form.Item>
            </Form>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="指定号码外呼" key="specified-call">
          <div>
            <Form form={form} validateTrigger={[]}>
              <Form.Item
                style={{ marginBottom: 10, borderBottom: '2px solid rgb(101 87 87 / 85%)' }}
                name="phone"
                rules={[
                  { required: true, message: '请输入电话号码' },
                ]}
                className="text-center"
              >
                <Input
                  size="large"
                  bordered={false}
                  placeholder="请输入外呼号码"
                  style={{ textAlign: 'center' }}
                />
              </Form.Item>
              <p>上次外呼号码：{phoneData?.lastCaller}</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Input
                  placeholder="搜索指定主叫号码"
                  allowClear
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{ width: 200 }}
                  className="phone-search-input"
                />
              </div>
              <div style={{ maxHeight: 160, overflowY: 'auto' }}>
                <Spin spinning={loading}>
                  {treeData.length > 0 && (
                    <Tree
                      defaultExpandAll
                      treeData={treeData}
                      selectable
                      selectedKeys={selectedKeys}
                      onSelect={(selectedKeys, info: { node: CustomTreeNode }) => {
                        setSelectedKeys(selectedKeys)
                        setSelectedPhone({
                          skillGroupId: info.node?.parentSkillGroupId,
                          phone: info.node?.title as string,
                        })
                      }}
                    />
                  )}
                </Spin>
              </div>
              <Form.Item style={{ textAlign: 'center', marginTop: 16 }}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, getFieldError }) => {
                    const phone = getFieldValue('phone')
                    const hasError = getFieldError('phone').length > 0
                    const isValid = !!phone
                    const hasSelectedNumber = selectedKeys.length > 0

                    return (
                      <Button
                        type="primary"
                        onClick={handleSpecifiedCall}
                        shape="round"
                        disabled={!isValid || hasError || !hasSelectedNumber}
                      >
                        拨打电话
                      </Button>
                    )
                  }}
                </Form.Item>
              </Form.Item>
            </Form>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
  return (
    <Popover
      getPopupContainer={triggerNode => triggerNode.parentElement}
      content={phoneContent}
      trigger="click"
      visible={visible}
      onVisibleChange={(v) => {
        setVisible(v)
        if (v) {
          setActiveTabKey('external-call')
        }
      }}
    >
      <PhoneOutlined
        className="phone-icon ml10 text-primary fs18"
      />
    </Popover>
  )
}

export default ExternalCall
