/**
 * 全局渲染错误 catch
 * @author tylerzzheng
 */
import React from 'react'
import { captureException as aegisCaptureException, crmCaptureException } from '../../library/rum'
import { notification } from 'antd'
import ErrorDescription from './ErrorDescription'

interface ErrorBoundaryState {
  error: Error
  isDetailExpanded: boolean
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      isDetailExpanded: false,
    }

    // 初始化sentry
    // initSentry()
  }

  componentDidCatch (error: Error) {
    this.setState({ error })
    // rum 上报
    aegisCaptureException(error)
    crmCaptureException(`${error}; stack: ${error.stack}`)

    const key = 'error-notification'
    const updateNotification = (show: boolean) => {
      notification.open({
        key,
        placement: 'bottomRight',
        message: <span>抱歉，页面处理有误，您可以尝试刷新本页面重试，或联系 <a href="wxwork://message?uin=8444253413321081">CSIG_OMP 助手</a>反馈问题</span>,
        description: <ErrorDescription
          detail={`${error}; stack: ${error.stack}`}
          onToggle={(show) => {
            this.setState({ isDetailExpanded: show }, () => {
              updateNotification(show)
            })
          }}
        />,
        style: {
          height: show ? 400 : 150,
          overflow: 'auto',
        },
      })
    }

    updateNotification(false)
  }

  render () {
    return this.props.children
    // return (
    //   <div className="main-app">
    //     <Result
    //       status="error"
    //       title="抱歉，页面渲染错误"
    //       subTitle={(
    //         <p>
    //           您可以尝试刷新本页面重试，或联系 <a href="wxwork://message?uin=8444253413321081">CSIG_OMP 助手</a>反馈问题
    //         </p>
    //       )}
    //       extra={error.stack}
    //     />
    //   </div>
    // )
  }
}
