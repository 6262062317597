/**
 * 切换模块
 * @author tylerzzheng
 */
import React, { useCallback, useMemo, useState } from 'react'
import { Alert, Col, message, Modal, Row, Select } from 'antd'
import store from '@store'
import { isEmptyWithCustom } from '@library'
import { getUserAuthPoint, switchBelongModule } from '@services/session'
import { useService, useShowError } from '@hooks'
import AUTH_POINT_MAP from '@configs/auth-point'
import { get } from 'lodash'

const { Option } = Select

interface SwitchModuleProps {
  open: boolean
  onCancel: () => void
  onSuccess?: () => void
}

export default function SwitchModule (props: SwitchModuleProps) {
  const { open, onCancel, onSuccess } = props

  const { moduleList, userInfo } = store.useSession()

  const [module, setModule] = useState<number>()

  const params = useMemo<Parameters<typeof getUserAuthPoint>>(() => {
    const [object, action] = AUTH_POINT_MAP.switchModule.split('-')
    const belongModule = moduleList.map(module => module.belongModule)
    return [[{ object, action }], belongModule]
  }, [moduleList])

  const [loading, res, err] = useService(getUserAuthPoint, ...params)
  useShowError('获取模块权限失败， 请刷新重试', err)
  const goodToSwitchModuleList = useMemo<number[]>(() => get(res, 'authPoints[0].belongModule', []), [res])

  const [confirmLoading, setConfirmLoading] = useState(false)
  const submitSwitchModule = useCallback(async () => {
    setConfirmLoading(true)
    const [, err] = await switchBelongModule(module)
    setConfirmLoading(false)
    if (err) {
      message.error(`切换模块失败${err.message}`)
      return
    }
    onSuccess && onSuccess()
    message.success('切换模块成功，请等待页面自动刷新')
    setTimeout(() => window.location.reload(), 1000)
  }, [module])

  return (
    <Modal
      open={open}
      width={650}
      title="切换模块"
      okButtonProps={{ disabled: isEmptyWithCustom(module) }}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => submitSwitchModule()}
    >
      <Alert message="此功能可以让你切换不同的业务模块，目标模块与当前模块都有切换权限才可以切换。" type="info" showIcon />
      <Row className="mt15">
        <Col span={8}>请选择要切换的模块:</Col>
        <Col span={12}>
          <Select loading={loading} allowClear onChange={(val) => setModule(val as number)}>
            {moduleList.map((item) => {
              return (
                <Option
                  key={item.belongModule}
                  disabled={!goodToSwitchModuleList?.includes?.(item.belongModule) || item.belongModule === userInfo.belongModule}
                  value={item.belongModule}
                >
                  {item.belongModuleName}{item.belongModule === userInfo.belongModule && '(当前模块)'}
                </Option>
              )
            })}
          </Select>
        </Col>
      </Row>
    </Modal>
  )
}
