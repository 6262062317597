import React, { FunctionComponent, useMemo, useCallback } from 'react'
import { PageHeader, CommonFilters, UserAvatar } from '@components'
import { Button, Card, Select, Spin, Table } from 'antd'
import { OperationLogFilter, OperationLog } from '@types'
import { ColumnProps } from 'antd/lib/table'
import { useTableService } from '@hooks/useTableService'
import { getOperationLog } from '@services/operation-log'
import { getTableXwidth, PAGESIZE_OPTIONS } from '@configs/table'
import { operationLogFilterItems } from '../../../components/CommonFilters/configs'
import { isEmptyArray } from '@library'
import { operationLogActionMap } from '@configs/map'
import { last } from 'lodash'

const BASE_ID_VALUE = 0

const initFilter: OperationLogFilter = {
  baseId: BASE_ID_VALUE, // 初始默认第一页是0
  pageTurnType: 'next',
  pageSize: 10,
}

const ConfigOperationLogPage: FunctionComponent = () => {
  /* 数据源 */
  const {
    list,
    filter,
    loading,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getOperationLog, initFilter, '获取操作日志列表失败')

  /* 列 */
  const columns: ColumnProps<OperationLog>[] = useMemo(() => [
    { title: '操作时间', dataIndex: 'createTime', width: 120 },
    { title: '用户名', dataIndex: 'operator', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '模拟者', dataIndex: 'originStaffname', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '操作模块', dataIndex: 'moduleOne', width: 80 },
    { title: '操作类型', dataIndex: 'action', width: 80, render: action => operationLogActionMap[action] || '-' },
    { title: '操作内容', dataIndex: 'content', width: 120 },
  ], [])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  /* 翻页 */
  const lastBaseId = useMemo(() => last(list)?.id || BASE_ID_VALUE, [list])
  const disableNext = useMemo(() => isEmptyArray(list) || list?.length < filter.pageSize, [list, filter])

  const footer = useCallback(() => (
    <Spin spinning={loading}>
      <Select value={filter.pageSize} onChange={value => setFilter('pageSize', +value)}>
        {PAGESIZE_OPTIONS.map(pageSize => {
          return <Select.Option key={pageSize} value={+pageSize}>{pageSize} 条/页</Select.Option>
        })}
      </Select>
      <Button
        className="ml10"
        disabled={filter?.baseId === BASE_ID_VALUE}
        onClick={() => setFilter({ baseId: lastBaseId, pageTurnType: 'prev' })}
      >上一页
      </Button>
      <Button
        className="ml10"
        disabled={disableNext}
        onClick={() => setFilter({ baseId: lastBaseId, pageTurnType: 'next' })}
      >下一页
      </Button>
    </Spin>
  ), [setFilter, filter, lastBaseId, loading])

  return (
    <>
      <PageHeader title="操作日志" />
      <Card className="mt20">
        <CommonFilters
          itemList={operationLogFilterItems}
          onSubmit={filter => setFilter({ ...filter, baseId: BASE_ID_VALUE })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={false}
          footer={footer}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
        />
      </Card>
    </>
  )
}
export default ConfigOperationLogPage
