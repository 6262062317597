import React, { useCallback, useMemo } from 'react'
import { Button, Card, message, Table } from 'antd'
import { IndicatorCustomerFilter, IndicatorCustomer as IndicatorCustomerType } from '@types'
import { CommonFilters, TableDownload, UserAvatar } from '@components'
import { useTableService } from '@hooks/useTableService'
import { exportIndicatorCustomerList, getIndicatorCustomerList } from '@services/task'
import { filterMap } from '@pages/task/Workspace/config'
import { getSortOrder, getTableXwidth } from '@configs/table'
import { TableColumnProps } from '@hooks'
import { Link } from 'react-router-dom'
import { antdSortMap, customerTypeMap } from '@configs/map'
import { TableProps } from 'antd/lib/table'
import { notEmptyArray, trimStr } from '@library'
import { storeCustomerQueue } from '@pages/customer/detail/utils'
import { moduleId } from '@configs'
import store from '@store'
import { get } from 'lodash'

export interface IndicatorCustomerProp {
  filterKey: keyof typeof filterMap
  initFilter: Partial<IndicatorCustomerFilter>
}

const IndicatorCustomer = (prop: IndicatorCustomerProp) => {
  const { userInfo } = store.useSession()
  const { filterKey, initFilter } = prop

  const multiInitFilter = moduleId.COC_TURN_ID === userInfo?.belongModule ? {
    ...initFilter, sort: 'portraitTagWeight', sortType: 'DESC',
  } as any : initFilter

  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    indexList,
    rowSelection,
    selectedRowText,
    rowKey,
  } = useTableService(getIndicatorCustomerList, multiInitFilter, '获取指标客户列表失败')
  const columns: TableColumnProps<IndicatorCustomerType>[] = useMemo(() => [
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 180,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '客户类型', dataIndex: 'customerType', width: 120, render: customerType => customerTypeMap[customerType] || '' },
    { title: 'CID', dataIndex: 'cid', width: 200 },
    {
      title: '标签权重',
      dataIndex: 'portraitTagWeight',
      width: 120,
      sorter: true,
      sortOrder: filter.sort === 'portraitTagWeight' ? antdSortMap[filter.sortType] : undefined,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '首次认证时间',
      dataIndex: 'firstAuthTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'firstAuthTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '当前跟进人', dataIndex: 'follower', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '当前所属组', dataIndex: 'groupName', width: 220 },
    { title: '当前所属公司', dataIndex: 'companyName', width: 150 },
    { title: '最近跟进人', dataIndex: 'lastFollower', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '最近跟进时间',
      dataIndex: 'lastFollowTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'lastFollowTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '下次跟进时间',
      dataIndex: 'predictFollowTime',
      width: 180,
    },
    { title: '公共标签', dataIndex: 'publicTagName', width: 220 },
    { title: '个人标签', dataIndex: 'privateTagName', width: 220 },
  ], [filter.sort, filter.sortType])

  const onTableChange: TableProps<IndicatorCustomerType>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<IndicatorCustomerFilter>(sorter)
    setFilter(sort)
  }
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  // 开始跟进
  const handleFollow = useCallback(async () => {
    // 如果有勾选，则认为只针对所勾选的items进行跟进。没有则是当前筛选条件下的全部
    const isSelectOnly = notEmptyArray(indexList)
    const customerIds = isSelectOnly ? indexList.map(index => get(list, `[${index}].customerId`)) : list?.map(item => item?.customerId)

    const customerId = customerIds?.[0]
    if (!customerId) {
      message.warn('当前无可跟进客户')
      return
    }

    const queue = storeCustomerQueue({
      customerIds: customerIds,
      filter: isSelectOnly ? undefined : filter,
      serviceName: isSelectOnly ? undefined : 'getIndicatorCustomerList',
    })
    window.open(`/customer/${customerId}?from=${encodeURIComponent(window.location.pathname)}&queue=${queue}`, '_blank')
  }, [indexList, list, filter])

  return (
    <>
      <Card>
        <CommonFilters
          className="custom-Label"
          itemList={filterMap[filterKey]}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <span>{selectedRowText}</span>
        <Button className="ml10" type="primary" onClick={handleFollow}>开始跟进</Button>
        <TableDownload className="ml10" exportMethod={exportIndicatorCustomerList} filter={filter} />
        <Table
          style={{ maxWidth: 50 + tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          rowSelection={rowSelection}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={
            moduleId.COC_TURN_ID === userInfo?.belongModule
              ? columns
              : columns?.filter((o: any) => o?.dataIndex !== 'portraitTagWeight')
          }
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}

export default IndicatorCustomer
