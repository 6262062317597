/**
 * 前端性能监控
 * 文档见 https://cloud.tencent.com/document/product/1464
 * Aegis官网; http://aegis.oa.com/sdk/web.html#beforerequest
 * 总览见 https://console.cloud.tencent.com/rum/web
 * @author tylerzzheng
 */

// 引入后进行初始化
import Aegis from 'aegis-web-sdk'
import { UserInfo } from '@types'
import { isNullOrUndefined } from './isTypeOf'
import { API_HOST, apiHostMap, codeList, unauthorizedAPI } from '@configs'
import { createCrmReportSdk } from '@tencent/crm-report-sdk'
import { endsWith, set, get } from 'lodash'

// https://cloud.tencent.com/document/product/1464/65865
export enum Environment {
  production = 'production', // 生产环境
  gray = 'gray', // 灰度环境
  pre = 'pre', // 预发布环境
  daily = 'daily', // 日发布环境
  local = 'local', // 本地环境
  test = 'test', // 测试环境
  others = 'others' // 其他环境
}

export const envMap: {[key in keyof typeof apiHostMap]: Environment} = {
  // 'local.tcc.tencent.com': Environment.local,
  'local.tcc.tencent.com': Environment.local,
  'local.tcc.testsite.woa.com': Environment.local,
  'dev.tcc.tencent.com': Environment.local,
  'tcc.testsite.woa.com': Environment.local,
  'test.tcc.tencent.com': Environment.test,
  'pre.tcc.tencent.com': Environment.pre,
  'tcc-pre.testsite.woa.com': Environment.pre,
  'tcc.tencent.com': Environment.production,
  'bak.tcc.tencent.com': Environment.production,
  'tcc-web-7gwart8i0811324b-1258344699.tcloudbaseapp.com': Environment.production,
}

let aegis: Aegis
let setUserNameFn
let crmCaptureExceptionFunc
export const initAegis = () => {
  if (!isNullOrUndefined(aegis)) return
  const env = envMap[window.location.hostname] || Environment.production
  const version = env === Environment.production ? process.env.BUILD_DATE : env + '.' + process.env.BUILD_DATE

  aegis = new Aegis({
    id: 'gQ7Y2cgj0zqx6nwKnK', // 上报 id
    version,
    env,
    // uin: 'xxx', // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    api: {
      apiDetail: true, // Aegis SDK 在错误发生的时候，不会主动收集接口请求参数和返回信息，如果需要对进口信息进行上报，可以使用 API 参数里面的 apiDetail 进行开启。
      // retCodeHandler配置参见这里 https://cloud.tencent.com/document/product/1464/58560#exp1
      retCodeHandler (dataStr, url) {
        if (!url.includes?.(API_HOST)) { // 非业务接口直接返回
          return { isErr: false, code: 0 }
        }

        let data
        try {
          data = JSON.parse(dataStr)
        } catch (e) {
          console.error('retCodeHandler解析data出错', e)
        }
        const code = data?.returnCode
        let isErr = !codeList.ok.includes(data?.returnCode)

        // 登录态失效的报错认为是正常的
        if (codeList.redirectToLogin.includes(code) && unauthorizedAPI.some(api => endsWith(url, api))) {
          isErr = false
        }

        return { isErr, code }
      },
    },
    // 假如您的页面 URL 是 restful 风格的，例如：/www.example.com/user/1000 www.example.com/user/1001 在上报页面测速时需要将这些页面地址聚合：
    pagePerformance: {
      urlHandler () {
        if ((/\/customer\/\d*\?|$/).test(window.location.href)) {
          return window.location.origin + '/customer/:customerId'
        }
      },
      firstScreenInfo: false, // 因为type类型限制，这个必须要写上
    },
    // 这里的目的让上报的接口不聚合 http://aegis.oa.com/sdk/web.html#beforerequest 或者 https://cloud.tencent.com/document/product/1464/58557
    beforeRequest (data) { // 入参data的数据结构：{logs: {…}, logType: "log"}
      // 当 logType 为 'log' 时，logs 数据类型为 {msg: "日志详情", level: '4', ext1: '', ext2: '', ext3: '', trace: ''}。
      if (data?.logType === 'log') {
        // 因为每天都会有大批量的登录失效报错，和后端约定后，按此来忽略，不必每天大量上报。
        if (data?.logs?.msg.includes?.('res retcode: 1009')) {
          return false
        }
        /**
       * 去除 antd的ResizeObserver loop limit exceeded错误上报，该上报可忽略。
       * issue见：https://github.com/ant-design/ant-design/issues/26621
       *
       * 关于JS报错：ResizeObserver loop limit exceeded
       Q:报错影响范围，严重程度？ A:无影响，属于可以忽略的报错
       Q:报错的来源？ A:nfes-antd 引用的 Ant Design 组件库报的错，我们监控了全局报错, 并发送至clog
       Q:什么情况下会触发 A: 报价页切换tab 和 某些交互，比如下拉框筛选
       Q:根本原因 A:此错误意味着ResizeObserver无法在单个动画帧中提供所有观察值。这是良性的.在重新渲染反应期间可能会触发ResizeObserver loop limit exceeded（多发于组件第一次注册和动态元素)
       Q:为什么可以忽略，A: 该报错Ant Design官方处理方式是忽略 https://github.com/ant-design/ant-design/blob/a51439cbbabef454e35218864fddf0da96e4801e/site/theme/template/Layout/index.jsx#L46
       */
        if (data?.logs?.msg.includes?.('ResizeObserver loop')) {
          // console.log('错误忽略了', data)
          return false
        }
      }
      // 当 logType 为 'speed' 时，logs 数据类型为 {connectTime: 0, domainLookup: 0, duration: 508.2, isHttps: true, method: "get", status: 200, type: "static", url: "https://xxxxxx", urlQuery: "max_age=1296000"}。
      if (/api\.tcc\.tencent\.com\/api\/interface/.test(data?.logs?.url) && data?.logType === 'speed') {
        // ？后面的会被忽略。导致请求的接口名全都一样了，所以把？替换成/
        set(data, 'logs.url', get(data, 'logs.payload.data.aegisUrl')?.replace('api.tcc.tencent.com/api/interface?', 'api.tcc.tencent.com/api/interface/'))
      }
      // if (data?.logType === 'performance') {
      // callback && callback(data)
      // }
      return data
    },
  })
  const robotInfo = env === 'production' ? {} : { robotKey: 'fake', chartId: 'fake' }
  const { initSentry, setUserName, captureException } = createCrmReportSdk({ // setUserName
    system: 'tcc',
    env,
    module: 'tcc系统',
    //   staffname: salesName || '',
    config: {
      maxLcp: 8000,
    },
    useAegis: () => {
      return aegis
    },
    ...robotInfo,
    responseRtx: ['easonmma', 'simonyma'], // 值班人 可以覆盖七彩石配置
    dutyRtx: ['easonmma'], // 可以覆盖七彩石配置
    // pvId: 'x',
  })
  initSentry()
  setUserNameFn = setUserName
  crmCaptureExceptionFunc = captureException
  console.info(`[Aegis] initiated. version： ${version}, env: ${env}.`)
  // return aegis
}

export const configAegis = (userInfo: UserInfo) => {
  aegis.setConfig({ uin: userInfo.salesName })
  setUserNameFn && setUserNameFn(userInfo.salesName)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const reportEvent = (eventId: string, eventType: string, other?: string) => {
  // aegis.reportEvent({ name: eventId, ext1: eventType, ext2: other })
}

export const captureException = (error: Error) => {
  console.info('[Aegis] exception captured.')
  aegis.error({
    ...error, msg: error.message, trace: error.stack,
  })
}

export const crmCaptureException = (error: any) => {
  return crmCaptureExceptionFunc(error)
}
