import { useState, useEffect } from 'react'
import { message } from 'antd'
import { GetKeyWordListAPI } from '@services/quality-inspection'
import { KeyWordItemType, KeyWordTypeEnum } from '@types'
import { get, groupBy } from 'lodash'

const customerService = KeyWordTypeEnum.customerService
const client = KeyWordTypeEnum.client

interface KeyWordListType {
  [customerService]: KeyWordItemType[],
  [client]: KeyWordItemType[]
};

const defKeyWordList: KeyWordListType = {
  [customerService]: [],
  [client]: [],
}

export const useKeyWordsList = () => {
  const [keyWordsList, setKeyWordsList] = useState(defKeyWordList)
  const [maxKeyWordCnt, setMaxKeyWordCnt] = useState(50)
  /**
   * 获取客户关键词或客服关键词
   */
  const getKeyWordList = async () => {
    const [res, err] = await GetKeyWordListAPI()
    if (err) {
      message.error('获取关键词列表失败！')
    }
    const list = get(res, 'list', [])
    const newList = groupBy(list, 'type') as unknown as KeyWordListType
    setKeyWordsList(newList)
    setMaxKeyWordCnt(get(res, 'maxKeyWordCnt', 50))
  }

  useEffect(() => {
    getKeyWordList()
  }, [])

  return { keyWordsList, defKeyWordList, getKeyWordList, maxKeyWordCnt }
}
