/**
 * 选择器的配置项
 * @author tylerzzheng
 */
import { CommonSelectorProps, getThumbAvatar, ReplayRecord } from '@components'
import { getAgentList, getOrgGroupList, getStaffList } from '@services/organazition'
import {
  AccountType,
  Agent,
  AgentFilter,
  BatchInfoItem,
  BatchInfoListPayload,
  CallRecordListFilter,
  CallRecordListItem,
  FollowType,
  IOrgGroup,
  IRule,
  IRuleType,
  IStaff,
  needTotalEnum,
  OpportunityScene,
  OrgGroupFilter,
  PrimaryIndustryIncome,
  ReceiveInfoEnum,
  ResourceTmp,
  ResourceTmpFilter,
  RuleNameList,
  RuleNameListFilter,
  RuleTypePayload,
  SMSRecordListFilter,
  SMSRecordListItem,
  StaffFilter,
  Tag,
  TagFilter,
  TaskType,
  TouchStatus,
  TouchStatusFilter,
  CustomerNameFilter,
  ICustomerName,
  ILeadPoolItem,
  IndustryProduct,
  LeadTagsItem,
  SearchedCustomerFilter,
  SearchedCustomer,
  LeadIntentionLevel,
  PostOrderProductCode,
  TaskNameListFilter,
  TaskNameItem,
  RobotItem,
  RobotTaskItem,
  ListCallRollTitleFilter,
  ListCallRollTitleItem,
  ListCallTagFilter,
  OpportunityTypeItem,
  StageInProgressTypeItem,
} from '@types'
import { getPrivateTagList, getPublicTagList } from '@services/tags'
import { getFollowType, getTaskNameList, getTaskType } from '@services/task'
import {
  getCallRecordList,
  getPrimaryIndustryIncomeNoSession,
  getSMSRecordList,
  getCustomerNameList,
  getSalesChannelEnum,
  getIndustryProduct, searchCustomerName, getRuleTypes, getPostOrderProductList,
  getValidPrimaryIndustryIncome,
  getOpportunityType,
  getOpportunityStageInProgress,
  querySpuCodeList,
  getT3CCallRecordList,
} from '@services/customer'
import { getRuleNameList } from '@services/lead-rule'
import { getLeadListByCid, getTouchStatus, getLeadTags, getLeadIntentionLevel } from '@services/lead'
import { getRuleListNoSession } from '@services/sms'
import { getResourceTmpList } from '@services/retain-resource'
import React from 'react'
import { getBatchInfoList } from '@services/batch-info'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { handleNumber, isUndefined, getStaffName } from '@library'
import { getOpportunityScene } from '@services/sale-opportunity'
import { AreaItem, areaTree } from '@configs/area'
import { Avatar, Typography } from 'antd'
import { getListRobot, getListRobotTask, listCallRollTitle, listCallTag } from '@services/robot'
import { ProductStatus } from '@configs'
import { get, omit, flatten } from 'lodash'

/**
 * 线索标签
 */
export const leadTags: CommonSelectorProps<{}, LeadTagsItem> = {
  id: 'leadTags',
  name: '线索标签',
  service: getLeadTags,
  valueKey: 'tagCode',
  labelKey: 'tagName',
  showSearch: true,
  placeholderOnSearching: '按标签名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 模糊匹配客户名称
 */
export const customersName: CommonSelectorProps<CustomerNameFilter, ICustomerName> = {
  id: 'customerName',
  name: '客户名称',
  service: getCustomerNameList,
  filter: { needTotal: needTotalEnum.LIST },
  valueKey: 'name',
  labelKey: 'name',
  showSearch: true,
  searchKey: 'customerName',
  placeholderOnSearching: '按客户名称模糊搜索...',
}

/**
 * 客户通路枚举值查询
 */
export const getSalesChannel = {
  id: 'salesChannel',
  name: 'CID通路',
  service: getSalesChannelEnum,
  valueKey: 'saleChannelID',
  labelKey: 'salesChannelName',
  // showSearch: true,
}

/**
 * 模糊匹配任务名称
 */
export const taskName: CommonSelectorProps<TaskNameListFilter, TaskNameItem> = {
  id: 'taskName',
  name: '任务名称',
  service: getTaskNameList,
  filter: { needTotal: needTotalEnum.LIST },
  valueKey: 'taskName',
  labelKey: 'taskName',
  showSearch: true,
  searchKey: 'taskName',
  placeholderOnSearching: '按任务名称模糊搜索...',
}

/**
 * 员工 - 有登录态的情况下才能调用
 * 拉取全部员工
 */
export const salesName: CommonSelectorProps<StaffFilter, IStaff> = {
  id: 'salesName',
  name: '员工',
  service: getStaffList,
  valueKey: 'salesName',
  labelKey: item => <><Avatar src={getThumbAvatar(item.avatar)} children={item.type === AccountType.VIRTUAL ? '虚' : item?.salesName?.charAt?.(0)?.toUpperCase()} size={20} className="mr5" />{getStaffName(item?.salesName, item?.salesNameCh)}</>,
  fetchMore: 'salesNames',
  showSearch: true,
  searchKey: searchVal => {
    const key = RegExp(/[a-zA-Z]/).test(searchVal) ? 'salesName' : 'salesNameCh'
    return { [key]: searchVal }
  },
  placeholderOnSearching: '按中文或英文名模糊搜索...',
}

/**
 * 员工 - 只拉取真实员工
 */
export const salesNameNoVirtual: CommonSelectorProps<StaffFilter, IStaff> = {
  ...salesName,
  filter: { type: AccountType.NORMAL },
  // labelKey: item => <><Avatar src={getThumbAvatar(item.avatar)} size={20} />{getStaffName(item?.salesName, item?.salesNameCh)}</>,
}

/**
 * 员工 - 非登录态就可以直接调用
 */
// export const salesNameNoSession: CommonSelectorProps<StaffFilter, IStaff> = {
//   id: 'salesNameNoSession',
//   name: '员工',
//   service: getStaffListNoSession,
//   filter: { sortOrder: 'salesName' },
//   valueKey: 'salesName',
//   labelKey: item => `${item?.salesName}(${item?.salesNameCh})`,
//   fetchMore: 'salesNames',
//   searchKey: searchVal => {
//     const key = RegExp(/[a-zA-Z]/).test(searchVal) ? 'salesName' : 'salesNameCh'
//     return { [key]: searchVal }
//   },
//   placeholderOnSearching: '按中文或英文名模糊搜索...',
// }

/**
 * 群组 - 有登录态的情况喜爱才能调用
 */
export const groupId: CommonSelectorProps<OrgGroupFilter, IOrgGroup> = {
  id: 'groupId',
  name: '群组',
  service: getOrgGroupList,
  valueKey: 'groupId',
  labelKey: item => `${item.groupName || ''}-${item.companyName}`,
  fetchMore: 'groupIds',
  showSearch: true,
  searchKey: 'groupName',
  placeholderOnSearching: '按群组名称搜索...',
}

/**
 * 群组（value 为 name） - 有登录态的情况喜爱才能调用
 */
export const groupName: CommonSelectorProps<OrgGroupFilter, IOrgGroup> = {
  id: 'groupName',
  name: '群组',
  service: getOrgGroupList,
  valueKey: 'groupName',
  labelKey: item => `${item.groupName || ''}-${item.companyName}`,
  // fetchMore: 'groupIds',
  showSearch: true,
  searchKey: 'groupName',
  placeholderOnSearching: '按群组名称搜索...',
}

/**
 * 群组 - 非登录态就可以直接调用
 */
// export const groupIdNoSession: CommonSelectorProps<(Parameters<typeof getOrgGroupListNoSession>[0]), IOrgGroup> = {
//   id: 'groupIdNoSession',
//   name: '群组',
//   service: getOrgGroupListNoSession,
//   valueKey: 'groupId',
//   labelKey: item => `${item.groupName || ''}-${item.companyName}`,
//   fetchMore: 'groupIds',
//   searchKey: 'groupName',
//   placeholderOnSearching: '按群组名称搜索...',
// }

/**
 * 个人标签
 */
export const privateTag: CommonSelectorProps<TagFilter, Tag> = {
  id: 'privateTag',
  name: '个人标签',
  service: getPrivateTagList,
  valueKey: 'tagId',
  labelKey: 'tagName',
  fetchMore: 'tagId',
  showSearch: true,
  searchKey: 'tagName',
  placeholderOnSearching: '按标签名称搜索...',
}

/**
 * 公共标签
 */
export const publicTag: CommonSelectorProps<TagFilter, Tag> = {
  id: 'publicTag',
  name: '公共标签',
  service: getPublicTagList,
  valueKey: 'tagId',
  labelKey: 'tagName',
  fetchMore: 'tagIds',
  showSearch: true,
  searchKey: 'tagName',
  placeholderOnSearching: '按标签名称搜索...',
}

// 产品名称
export const saasProducts: CommonSelectorProps<{}, any> = {
  id: 'saasProducts',
  name: '产品',
  service: querySpuCodeList,
  valueKey: 'spuCode',
  labelKey: 'spuName',
}

/**
 * 获取跟进记录的跟进方式
 */
export const followType: CommonSelectorProps<{}, FollowType> = {
  id: 'followType',
  name: '跟进方式',
  service: getFollowType,
  valueKey: 'key',
  labelKey: 'value',
}

/**
 * 任务类型
 */
export const taskType: CommonSelectorProps<{}, TaskType> = {
  id: 'taskType',
  name: '任务类型',
  service: getTaskType,
  valueKey: 'value',
  labelKey: 'key',
}

/**
 * 短信记录
 */
export const msgLogs: CommonSelectorProps<SMSRecordListFilter, SMSRecordListItem> = {
  id: 'msgLogs',
  name: '短信记录',
  service: getSMSRecordList,
  filter: { needTotal: needTotalEnum.LIST },
  valueKey: 'smsId',
  labelKey: 'content',
  fetchMore: 'smsId',
  showSearch: true,
  searchKey: 'content',
  placeholderOnSearching: '按短信内容搜索...',
}

/**
 * 通话记录
 */
export const phoneLogs = (useT3C: boolean):CommonSelectorProps<CallRecordListFilter, CallRecordListItem> => {
  const getListApi = useT3C ? getT3CCallRecordList : getCallRecordList
  const valueKey = useT3C ? 'recordId' : 'callId'
  return {
    id: 'phoneLogs',
    name: '通话记录',
    service: getListApi,
    filter: { needTotal: needTotalEnum.LIST },
    valueKey: valueKey,
    labelKey: item => <><ReplayRecord className="mr5" logId={useT3C ? `t3c-${item.recordId.toString()}` : item.callId} title={`与${item.customerName}的通话录音`} />{item.createTime}</>,
    fetchMore: 'callId',
    // searchKey: 'phone',
    // placeholderOnSearching: '按被叫电话搜索...',
  }
}

/**
 * 规则类型
 */
export const ruleTypesNoSession: CommonSelectorProps<RuleTypePayload, IRuleType> = {
  id: 'ruleTypesNoSession',
  name: '规则类型',
  service: getRuleTypes,
  valueKey: 'ruleType',
  labelKey: 'ruleTypeName',
  fetchMore: 'ruleType',
  showSearch: true,
  searchKey: 'ruleTypeName',
  placeholderOnSearching: '按规则类型模糊搜索...',
}

/**
 * 规则名称
 */
export const ruleId: CommonSelectorProps<RuleNameListFilter, RuleNameList> = {
  id: 'ruleId',
  name: '规则名称',
  service: getRuleNameList,
  valueKey: 'ruleId',
  labelKey: 'ruleName',
  fetchMore: 'ruleId',
  showSearch: true,
  searchKey: 'ruleName',
  placeholderOnSearching: '按规则名称模糊搜索...',
}

/**
 * 批次号
 */
export const batchId: CommonSelectorProps<BatchInfoListPayload, BatchInfoItem> = {
  id: 'batchId',
  name: '批次号',
  service: getBatchInfoList,
  valueKey: 'batchId',
  fetchMore: 'batchIds',
  extraList: [{ value: 0, children: <><StopOutlined className="mr5" />无批次</>, title: '无批次', label: '无批次' }],
  labelKey: item => `(${item.batchId || ''}) ${item.batchName}`,
  showSearch: true,
  searchKey: searchVal => {
    const val = handleNumber(searchVal)
    return { batchName: isUndefined(val) ? searchVal : undefined, batchIds: isUndefined(val) ? undefined : [val] }
  },
  placeholderOnSearching: '按批次Id或名称搜索...',
}

/**
 * 代理商
 */
export const agentId: CommonSelectorProps<AgentFilter, Agent> = {
  id: 'agentId',
  name: '代理商',
  service: getAgentList,
  valueKey: 'agentId',
  fetchMore: (idS) => ({ agentId: get(idS, '[0]') }),
  labelKey: item => {
    const icon = item.receiveInfo === ReceiveInfoEnum.YES ? <CheckCircleOutlined className="mr5 text-success" /> : <CloseCircleOutlined className="mr5" />
    return <>{icon}{item.agentName}({item.agentId}) - {item.agentBusiness?.map?.(business => <Typography.Text ellipsis key={business.key} code>{business.value}</Typography.Text>)}</>
  },
  optionTitle: (item) => `${item.receiveInfo === ReceiveInfoEnum.NO ? '不' : ''}接受情报。身份: ${item.agentBusiness?.map?.(business => business.value)?.join?.(';')}`,
  showSearch: true,
  searchKey: searchVal => {
    const val = handleNumber(searchVal)
    return { agentName: isUndefined(val) ? searchVal : undefined, agentId: val?.toString?.() } // ˙这里写成val?.toString?.() 的原因是val本身是undefined或者number类型，需要转成string
  },
  placeholderOnSearching: '按所属代理商UIN或名称搜索...',
}

/**
 * 触达状态
 */
export const touchStatus: CommonSelectorProps<TouchStatusFilter, TouchStatus> = {
  id: 'touchStatus',
  name: '触达状态',
  service: getTouchStatus,
  valueKey: 'key',
  labelKey: 'value',
}
/**
 * 搜索企业名称 （一般使用commonAutocomplete，本接口收费，频繁调用太贵了），并且需要name参数
 */
export const searchCustomer: CommonSelectorProps<SearchedCustomerFilter, SearchedCustomer> = {
  id: 'searchCustomer',
  name: '搜索企业名称',
  service: searchCustomerName,
  showSearch: true,
  searchKey: 'name',
  valueKey: 'name',
  labelKey: 'name',
}

/**
 * 规则列表
 */
export const ruleListNoSession: CommonSelectorProps<(Parameters<typeof getRuleListNoSession>[0]), IRule> = {
  id: 'ruleListNoSession',
  name: '规则列表',
  service: getRuleListNoSession,
  filter: { status: 1 },
  valueKey: 'ruleId',
  labelKey: 'ruleName',
  fetchMore: 'ruleId',
  showSearch: true,
  searchKey: 'ruleName',
  placeholderOnSearching: '按规则名称模糊搜索...',
}

/**
 * 留资表单
 */
export const extraFormId: CommonSelectorProps<ResourceTmpFilter, ResourceTmp> = {
  id: 'extraFormId',
  name: '留资表单',
  service: getResourceTmpList,
  valueKey: 'id',
  labelKey: 'name',
  showSearch: true,
  searchKey: 'name',
  placeholderOnSearching: '按模版名称模糊搜索...',
}

/**
 * 线索列表， 需再传递filter={{ cid }}参数
 */
export const leadListByCid: CommonSelectorProps<{cid: string}, ILeadPoolItem> = {
  id: 'leadListByCid',
  name: '线索列表',
  service: getLeadListByCid,
  valueKey: 'leadId',
  labelKey: item => item.batchName + '(' + item.leadCreateTime + ')',
}

/**
 * 磐石一级行业
 */
export const primaryIndustryIncome: CommonSelectorProps<{}, PrimaryIndustryIncome> = {
  id: 'primaryIndustryIncome',
  name: '磐石一级行业',
  service: getPrimaryIndustryIncomeNoSession,
  valueKey: 'primaryIndustryIncomeId',
  labelKey: 'primaryIndustryIncomeName',
  showSearch: true,
  placeholderOnSearching: '按一级行业名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 有效磐石一级行业
 */
export const validPrimaryIndustryIncome: CommonSelectorProps<{}, PrimaryIndustryIncome> = {
  id: 'primaryIndustryIncome',
  name: '磐石一级行业',
  service: getValidPrimaryIndustryIncome,
  valueKey: 'primaryIndustryIncomeId',
  labelKey: 'primaryIndustryIncomeName',
  showSearch: true,
  placeholderOnSearching: '按一级行业名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 销售机会的可关注产品列表，只需要3级
 */
export const opportunityProducts: CommonSelectorProps<(Parameters<typeof getIndustryProduct>[0]), IndustryProduct> = {
  id: 'getOpportunityProducts',
  filter: { level: 3, pageSize: 1000 },
  name: '关注产品',
  service: getIndustryProduct,
  valueKey: 'code',
  labelKey: 'name',
  fetchMore: (codes) => ({ code: get(codes, '[0]') }),
  showSearch: true,
  placeholderOnSearching: '按产品名称模糊搜索...',
  // searchKey: searchVal => ({ name: searchVal, count: 30, level: 3 }),
}

// 全量的销售机会的可关注产品列表组件配置
export const opportunityProductsAll: CommonSelectorProps<(Parameters<typeof getIndustryProduct>[0]), IndustryProduct> = {
  ...opportunityProducts,
  labelKey: (item) => `${item ? `${item?.name}` : ''} ${item?.status === ProductStatus.IN_VALID ? '(失效)' : ''}`,
  filter: { level: 3, status: ProductStatus.BOTH, pageSize: 1000 },
}

/**
 * 销售机会的可关注产品列表，只需要3级
 */
export const leadIntentionLevel: CommonSelectorProps<{}, LeadIntentionLevel> = {
  id: 'leadIntentionLevel',
  name: '意向度',
  service: getLeadIntentionLevel,
  valueKey: 'code',
  labelKey: 'name',
  showSearch: true,
  placeholderOnSearching: '按意向度名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 销售机会的应用场景
 */
export const opportunityScene: CommonSelectorProps<{}, OpportunityScene> = {
  id: 'opportunityScene',
  name: '应用场景',
  service: getOpportunityScene,
  valueKey: 'id',
  labelKey: 'sceneDesc',
  showSearch: true,
  placeholderOnSearching: '按应用场景名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 单独省份选择，不含城市
 */
export const provinceCode: CommonSelectorProps<{}, Omit<AreaItem, 'children'>> = {
  id: 'provinceCode',
  name: '省份',
  service: () => Promise.resolve([areaTree.map(province => omit(province, 'children')), null]),
  valueKey: 'value',
  labelKey: 'label',
  showSearch: true,
  placeholderOnSearching: '按省份名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 单独城市选择，不含省份
 */
export const cityCode: CommonSelectorProps<{}, (Omit<AreaItem, 'children'> & {parentLabel: string})> = {
  id: 'cityCode',
  name: '城市',
  service: () => Promise.resolve([flatten(areaTree.map(province => province.children.map(city => ({ ...city, parentLabel: province.label })))), null]),
  valueKey: 'value',
  labelKey: (item) => `${item.parentLabel}-${item.label}`,
  showSearch: true,
  placeholderOnSearching: '按省市名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 后付费订单商品码
 */
export const postOrderProductCode: CommonSelectorProps<{}, PostOrderProductCode> = {
  id: 'postOrderProductCode',
  name: '商品码',
  service: getPostOrderProductList,
  valueKey: 'productCode',
  labelKey: 'productCodeName',
  showSearch: true,
  placeholderOnSearching: '按商品码名称模糊搜索...',
  filterOption: (input, option) => {
    // @ts-ignore
    return option.children.indexOf?.(input) >= 0
  },
}

/**
 * 机器人列表
 */
export const robotList: CommonSelectorProps<{}, RobotItem> = {
  id: 'robotList',
  name: '机器人列表',
  service: getListRobot,
  labelKey: 'name',
  valueKey: 'id',
  filter: { page: 1, pageSize: 1000 },
}

/**
 * 机器人任务列表
 */
export const robotTaskList: CommonSelectorProps<{}, RobotTaskItem> = {
  id: 'robotTaskList',
  name: '任务列表',
  service: getListRobotTask,
  labelKey: 'taskName',
  valueKey: 'taskId',
  filter: { page: 1, pageSize: 1000 },
  disableItem: (item) => {
    const case1 = [3, 8].includes(item?.status) && item?.executeType === 4 // 4-长期
    const case2 = item?.status === 8 && item?.executeType === 0 // 0-单次执行
    return !(case1 || case2) // 8-未就绪，3-已完成
  },
}

/**
 * 机器人名单名称列表
 */
export const ListCallRollTitle: CommonSelectorProps<ListCallRollTitleFilter, ListCallRollTitleItem> = {
  id: 'listCallRollTitle',
  name: '机器人名单名称列表',
  service: listCallRollTitle,
  valueKey: 'id',
  labelKey: 'title',
  showSearch: true,
  placeholderOnSearching: '机器人名单名称模糊搜索...',
  searchKey: 'rollTitle',
}

/**
 * 机器人外呼通话标签列表
 */
export const ListCallTag: CommonSelectorProps<ListCallTagFilter, any> = {
  id: 'ListCallTag',
  name: '机器人外呼通话标签列表',
  service: listCallTag,
  labelKey: (item) => item,
  showSearch: true,
  placeholderOnSearching: '机器人外呼通话标签模糊搜索...',
  searchKey: 'tag',
  filter: { page: 1, pageSize: 1000 },
}
// 机会类型
export const opportunityType: CommonSelectorProps<{}, OpportunityTypeItem> = {
  id: 'opportunityType',
  name: '机会类型列表',
  service: getOpportunityType,
  valueKey: 'typeId',
  labelKey: 'typeName',
}

// 进行中阶段
export const stageInProgressType: CommonSelectorProps<{}, StageInProgressTypeItem> = {
  id: 'stageInProgressType',
  name: '进行中阶段列表',
  service: getOpportunityStageInProgress,
  valueKey: 'stageKey',
  labelKey: 'stageName',
}
