/**
 * 线索列表
 * @author tylerzzheng
 */
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Card, Descriptions, message, Popover, Table, Tooltip, Modal } from 'antd'
import { TableProps } from 'antd/lib/table'
import { AuthChecker, CommonFilters, PageHeader, TableDownload, UserAvatar, CallRobot } from '@components'
import { TableColumnProps, useTableColumn } from '@hooks'
import {
  AssignLeadsFilter,
  BatchActionFormValues,
  ILeadPoolItem,
  LeadExceedPeriodEnum,
  LeadExceedSlaEnum,
  LeadPoolFilter,
  LeadPoolQueryTypeEnum,
  LeadStatusEnum,
  needTotalEnum,
  UpdateLeadsFilter,
  RobotFilterTypeEnum,
  CallRobotSourceEnum,
} from '@types'
import { assignLeads, exportLeadList, getLeadPoolList, updateLeads } from '@services/lead'
import { antdSortMap, leadStatusMap, payTypeMap } from '@configs/map'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import BatchAssign, { FormDataType } from '../../../components/Batch/BatchAssign'
import BatchAction from '../../../components/Batch/BatchAction'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import moment from 'moment'
import { formatTime, getArea, isEmptyWithCustom, trimStr } from '@library'
import {
  claimedPageFilterItems,
  discardPageFilterItems,
  unclaimedPageFilterItems,
} from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
import { assignCustomer } from '@services/customer'
import { TableRowSelection } from 'antd/lib/table/interface'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { clone, get, isEmpty, uniqBy } from 'lodash'

export type LeadPoolItem = ILeadPoolItem & {
  payTypeText: string
  intentionLevelText: string
  getPublicTagsText: string
  getLeadTagNamesText: string
  getPrivateTagsText: string
  getClaimedGroupsText: string
  getIntentionText: string
  getProvinceCode: string
  getCityCode: string
  getArea: string
  other: ReactNode
}

// 线索池实体对象
export const getLeadPoolItem = (lead: ILeadPoolItem): LeadPoolItem => {
  const item: any = clone(lead)
  item.payTypeText = payTypeMap[lead.payType] ?? ''
  item.getPublicTagsText = lead.publicTags?.map?.(tag => tag.publicTagName)?.join('; ') ?? ''
  item.intentionLevelText = `${lead.intentionLevelName}-${lead.intentionSecondLevelName}`
  item.productNameText = `${lead.smallProductName}-${lead.subProductName}`
  item.getPrivateTagsText = lead.privateTags?.map?.(tag => tag.privateTagName)?.join('; ') ?? ''
  item.getClaimedGroupsText = lead.groups?.map?.(group => group.groupName).join('; ') ?? ''
  item.getLeadTagNamesText = lead.leadTagNames?.map?.(value => value).join('; ') ?? ''
  item.getIntentionText = `${lead.intentionName}-${lead.subIntentionName}`
  // item.getProvinceCode = lead.leadExtend?.find?.(extend => extend.key === 'provinceCode')?.keyValue || undefined
  // item.getCityCode = lead.leadExtend?.find?.(extend => extend.key === 'cityCode')?.keyValue || undefined
  item.getArea = getArea(lead.cityCode, lead.provinceCode)
  item.other = (
    <Descriptions column={2} size="small" style={{ width: '600px' }}>
      {lead.leadExtend?.map?.(item => {
        if (['provinceCode', 'cityCode', 'countryCode'].includes(item.key)) return null // 这里点石下发会自带省市，所以这里不需要展示
        return <Descriptions.Item key={item.key} label={item.keyName}>{item.keyValue === 'undefined' ? '-' : item.keyValue}</Descriptions.Item>
      })}
    </Descriptions>
  )
  return item
}

const LEAD_PAGE_UNCLAIMED = 'unclaimed'
const LEAD_PAGE_CLAIMED = 'claimed'
const LEAD_PAGE_DISCARD = 'discard'

const leadStatusTitleMap = new Map([
  [LEAD_PAGE_UNCLAIMED, '线索公海池'],
  [LEAD_PAGE_CLAIMED, '已派发线索'],
  [LEAD_PAGE_DISCARD, '废弃线索池'],
])
const leadTableIdMap = new Map([
  [LEAD_PAGE_UNCLAIMED, TableIdEnum.LEAD_UNCLAIMED],
  [LEAD_PAGE_CLAIMED, TableIdEnum.LEAD_CLAIMED],
  [LEAD_PAGE_DISCARD, TableIdEnum.LEAD_DISCARD],
])
const leadFilterItemsMap = new Map([
  [LEAD_PAGE_UNCLAIMED, unclaimedPageFilterItems],
  [LEAD_PAGE_CLAIMED, claimedPageFilterItems],
  [LEAD_PAGE_DISCARD, discardPageFilterItems],
])
const leadStatusMapNumber = new Map([
  [LEAD_PAGE_UNCLAIMED, [LeadStatusEnum.UNHANDLE]],
  [LEAD_PAGE_CLAIMED, [LeadStatusEnum.ASSIGNED, LeadStatusEnum.FOLLOWED]],
  [LEAD_PAGE_DISCARD, [LeadStatusEnum.DISCARDED]],
])

const robotBtn = [LEAD_PAGE_UNCLAIMED, LEAD_PAGE_CLAIMED]

// 控制页面按钮的显示配置
const AssignCustomerBtn = [LEAD_PAGE_CLAIMED]
const claimLeadBtn = [LEAD_PAGE_UNCLAIMED]
const tagLeadBtn = [LEAD_PAGE_UNCLAIMED, LEAD_PAGE_CLAIMED]
const downloadBtn = [LEAD_PAGE_UNCLAIMED, LEAD_PAGE_CLAIMED]

const initFilterValue = { leadTime: [moment().subtract(1, 'month'), moment()] }

export default function LeadListPage () {
  /* 处理参数 */
  const { pathname } = useLocation()
  const statusParam = useMemo(() => pathname.replace('/lead/list/', ''), [pathname])

  const initFilter = useMemo<LeadPoolFilter>(() => {
    return {
      page: 1,
      pageSize: 10,
      needTotal: needTotalEnum.LIST,
      status: leadStatusMapNumber.get(statusParam),
      leadBeginTime: formatTime(initFilterValue.leadTime[0], 'start'),
      leadEndTime: formatTime(initFilterValue.leadTime[1], 'end'),
      queryType: LeadPoolQueryTypeEnum.DEFAULT,
      // sort: 'leadCreateTime',
      // sortType: sortTypeEnum.DESC,
    }
  }, [statusParam])
  const {
    loading,
    list,
    reload,
    pagination,
    filter,
    setFilter, // 在使用SetFilter进行拉取数据的时候，要注意手动设置queryType为你想要的方式
    resetFilter,
    indexList,
    rowSelection: _rowSelection,
    selectedRowText,
    rowKey,
  } = useTableService(getLeadPoolList, initFilter, '获取线索列表失败')
  const [modal, contextHolder] = Modal.useModal()

  const onTableChange: TableProps<LeadPoolItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<LeadPoolFilter>(sorter)
    setFilter({ ...sort, queryType: LeadPoolQueryTypeEnum.DEFAULT })
  }
  // 这里是table的list
  const leadList: LeadPoolItem[] = useMemo(() => list.map(lead => getLeadPoolItem(lead)), [list])
  // 线索公海池columns
  const leadPoolColumnsUnclaimed: TableColumnProps<LeadPoolItem>[] = useMemo(() => [
    { title: '线索id', dataIndex: 'leadId', width: 120, fixed: true },
    { title: '线索名称', dataIndex: 'leadName', width: 180, fixed: true },
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 180,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: 'uin', dataIndex: 'uin', width: 120 },
    { title: 'cid', dataIndex: 'cid', width: 120 },
    { title: '一级来源', dataIndex: 'sourcePrimaryTagName', width: 120 },
    { title: '二级来源', dataIndex: 'sourceSecondaryTagName', width: 120 },
    { title: '公共标签', dataIndex: 'getPublicTagsText', width: 120 },
    { title: '个人标签', dataIndex: 'getPrivateTagsText', width: 120 },
    { title: '客户意向', dataIndex: 'getIntentionText', width: 200 },
    { title: '地域', dataIndex: 'getArea', width: 120 },
    { title: '批次id', dataIndex: 'batchId', width: 120 },
    { title: '批次名称', dataIndex: 'batchName', width: 120 },
    { title: '原线索意向', dataIndex: 'intentionLevelText', width: 120 },
    { title: '关注产品', dataIndex: 'productNameText', width: 120 },
    { title: '原线索标签', dataIndex: 'getLeadTagNamesText', width: 120 },
    { title: '付费类型', dataIndex: 'payTypeText', width: 120 },
    { title: '当前归属业务组', dataIndex: 'getClaimedGroupsText', width: 220 },
    { title: '当前跟进人', dataIndex: 'follower', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '线索创建时间',
      dataIndex: 'leadCreateTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'leadCreateTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '认证时间', dataIndex: 'authTime', width: 180 },
    { title: '注册时间', dataIndex: 'regTime', width: 180 },
    {
      title: '线索过期时间',
      dataIndex: 'leadPeriodTime',
      width: 180,
      render: (value, item) => item.exceedPeriod === LeadExceedPeriodEnum.YES ? <Tooltip className="text-danger mr5" title="已过期">{value}</Tooltip> : value,
    },
    {
      title: '线索最近变更时间',
      dataIndex: 'leadAssignTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'leadAssignTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '来源方线索ID', dataIndex: 'leadReleaseSourceId', width: 120 },
    {
      title: '其它信息',
      dataIndex: 'other',
      width: 120,
      render: other => (
        <Popover content={other || '-'} title="其它信息"><a style={{ cursor: 'default' }}>详情</a></Popover>
      ),
    },
  ], [filter.sort, filter.sortType, statusParam])
  // 废弃线索池columns
  const leadPoolColumnsDisCard: TableColumnProps<LeadPoolItem>[] = useMemo(() => [
    { title: '线索id', dataIndex: 'leadId', width: 120, fixed: true },
    { title: '线索名称', dataIndex: 'leadName', width: 180, fixed: true },
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 180,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: 'uin', dataIndex: 'uin', width: 120 },
    { title: '一级来源', dataIndex: 'sourcePrimaryTagName', width: 120 },
    { title: '二级来源', dataIndex: 'sourceSecondaryTagName', width: 120 },
    { title: '公共标签', dataIndex: 'getPublicTagsText', width: 120 },
    { title: '个人标签', dataIndex: 'getPrivateTagsText', width: 120 },
    { title: '客户意向', dataIndex: 'getIntentionText', width: 200 },
    { title: '地域', dataIndex: 'getArea', width: 120 },
    { title: '批次id', dataIndex: 'batchId', width: 120 },
    { title: '批次名称', dataIndex: 'batchName', width: 120 },
    { title: '原线索意向', dataIndex: 'intentionLevelText', width: 120 },
    { title: '关注产品', dataIndex: 'productNameText', width: 120 },
    { title: '原线索标签', dataIndex: 'getLeadTagNamesText', width: 120 },
    { title: '付费类型', dataIndex: 'payTypeText', width: 120 },
    // { title: '当前归属业务组', dataIndex: 'getClaimedGroupsText', width: 220 },
    // { title: '当前跟进人', dataIndex: 'follower', width: 120 },
    {
      title: '线索创建时间',
      dataIndex: 'leadCreateTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'leadCreateTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '认证时间', dataIndex: 'authTime', width: 180 },
    { title: '注册时间', dataIndex: 'regTime', width: 180 },
    {
      title: '线索最近变更时间',
      dataIndex: 'leadAssignTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'leadAssignTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '来源方线索ID', dataIndex: 'leadReleaseSourceId', width: 120 },
    {
      title: '其它信息',
      dataIndex: 'other',
      width: 120,
      render: other => (
        <Popover content={other || '-'} title="其它信息"><a style={{ cursor: 'default' }}>详情</a></Popover>
      ),
    },
  ], [filter.sort, filter.sortType, statusParam])
  // 已派发线索columns
  const leadPoolColumnsClaimed: TableColumnProps<LeadPoolItem>[] = useMemo(() => [
    { title: '线索id', dataIndex: 'leadId', width: 120, fixed: true },
    { title: '线索名称', dataIndex: 'leadName', width: 180, fixed: true },
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 180,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: 'uin', dataIndex: 'uin', width: 120 },
    { title: 'cid', dataIndex: 'cid', width: 120 },
    { title: '一级来源', dataIndex: 'sourcePrimaryTagName', width: 120 },
    { title: '二级来源', dataIndex: 'sourceSecondaryTagName', width: 120 },
    { title: '公共标签', dataIndex: 'getPublicTagsText', width: 120 },
    { title: '个人标签', dataIndex: 'getPrivateTagsText', width: 120 },
    { title: '客户意向', dataIndex: 'getIntentionText', width: 200 },
    { title: '地域', dataIndex: 'getArea', width: 120 },
    { title: '批次id', dataIndex: 'batchId', width: 120 },
    { title: '批次名称', dataIndex: 'batchName', width: 120 },
    { title: '原线索意向', dataIndex: 'intentionLevelText', width: 120 },
    { title: '关注产品', dataIndex: 'productNameText', width: 120 },
    { title: '原线索标签', dataIndex: 'getLeadTagNamesText', width: 120 },
    { title: '付费类型', dataIndex: 'payTypeText', width: 120 },
    { title: '当前归属业务组', dataIndex: 'getClaimedGroupsText', width: 220 },
    { title: '当前跟进人', dataIndex: 'follower', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '线索创建时间',
      dataIndex: 'leadCreateTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'leadCreateTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '认证时间', dataIndex: 'authTime', width: 180 },
    { title: '注册时间', dataIndex: 'regTime', width: 180 },
    {
      title: '线索SLA时间',
      dataIndex: 'leadSlaTime',
      width: 180,
      render: (value, item) => item.exceedSla === LeadExceedSlaEnum.YES ? <Tooltip className="text-danger mr5" title="已超SLA">{value}</Tooltip> : value,
    },
    {
      title: '线索过期时间',
      dataIndex: 'leadPeriodTime',
      width: 180,
      render: (value, item) => item.exceedPeriod === LeadExceedPeriodEnum.YES ? <Tooltip className="text-danger mr5" title="已过期">{value}</Tooltip> : value,
    },
    {
      title: '线索最近变更时间',
      dataIndex: 'leadAssignTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'leadAssignTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '首次分配时间', dataIndex: 'firstAssignTime', width: 120 },
    { title: '首次分配人', dataIndex: 'firstAssigner', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '首次跟进时间', dataIndex: 'firstFollowTime', width: 120 },
    { title: '首次跟进人', dataIndex: 'firstFollower', width: 120 },
    { title: '线索状态', dataIndex: 'leadStatus', width: 120, render: leadStatus => leadStatusMap[leadStatus] || '-' },
    { title: '来源方线索ID', dataIndex: 'leadReleaseSourceId', width: 120 },
    {
      title: '其它信息',
      dataIndex: 'other',
      width: 120,
      render: other => (
        <Popover content={other || '-'} title="其它信息"><a style={{ cursor: 'default' }}>详情</a></Popover>
      ),
    },
  ], [filter.sort, filter.sortType, statusParam])
  const columns = useMemo(() => {
    if (statusParam === LEAD_PAGE_CLAIMED) return leadPoolColumnsClaimed
    if (statusParam === LEAD_PAGE_UNCLAIMED) return leadPoolColumnsUnclaimed
    if (statusParam === LEAD_PAGE_DISCARD) return leadPoolColumnsDisCard
  }, [leadPoolColumnsUnclaimed, leadPoolColumnsDisCard, leadPoolColumnsClaimed, statusParam])
  const rowSelection = useMemo<TableRowSelection<ILeadPoolItem>>(() => {
    if (statusParam === LEAD_PAGE_UNCLAIMED) {
      // 在线索公海池，当线索已超期的时候，不可以被勾选
      return { ..._rowSelection, getCheckboxProps: (item) => ({ disabled: item.exceedPeriod === LeadExceedPeriodEnum.YES }) }
    } else {
      return _rowSelection
    }
  }, [_rowSelection, statusParam])
  const [columnConfigs, columnProps, fr] = useTableColumn(leadTableIdMap.get(statusParam), columns, ['name'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  /* 列表选择相关 */
  const selectedLeadList = useMemo<ILeadPoolItem[]>(() => indexList?.map?.(index => get(list, index)) || [], [list, indexList])

  /* 打标线索 */
  const submitBatchTag = useCallback(async (formData: BatchActionFormValues) => {
    const { publicTagIds, privateTagIds } = formData

    if (isEmpty(publicTagIds) && isEmpty(privateTagIds)) {
      message.warn('请至少选择一个标签')
      return true
    }

    const payload: UpdateLeadsFilter = {
      filter: { leadIds: selectedLeadList?.map(lead => lead.leadId) },
      updateResult: {
        updateLeadPublicTags: {
          publicTagIds: isEmpty(publicTagIds) ? undefined : publicTagIds,
        },
        updateLeadPrivateTags: {
          privateTagIds: isEmpty(privateTagIds) ? undefined : privateTagIds,
        },
      },
    }
    const [, err] = await updateLeads(payload)
    if (err) {
      message.error(`打标线索失败${err.message}`)
      return true
    }
    message.success('打标线索成功')

    if (filter.queryType === LeadPoolQueryTypeEnum.AFTER_UPDATE) {
      reload()
    } else {
      setFilter({ queryType: LeadPoolQueryTypeEnum.AFTER_UPDATE })
    }
  }, [selectedLeadList, filter, reload, setFilter])

  /* 派发线索 */
  const [showClaimedLeadList, setShowClaimedLeadList] = useState(false)
  // 勾选的列表中，筛选出已跟进的线索列表
  const claimedLeadList = useMemo(() => selectedLeadList?.filter?.(lead => !isEmptyWithCustom(lead?.follower)), [selectedLeadList])
  const submitAssignAction = useCallback(async (formData: FormDataType) => {
    const {
      taskName,
      sales: salesNames,
      isAssignToOfflineSales: ifAssignToOfflineSales,
      extraFormId,
    } = formData

    const params: AssignLeadsFilter = {
      filter: { leadIds: selectedLeadList?.map(lead => lead.leadId) },
      salesNames,
      taskName,
      ifAssignToOfflineSales,
      extraFormId,
    }
    const [res, err] = await assignLeads(params)
    if (err) {
      message.error(`派发线索失败${err.message}`)
      return true
    }
    if (res && Array.isArray(res?.unAssignedLeadIds?.leadIds) && res?.unAssignedLeadIds?.leadIds?.length > 0) {
      modal.info({
        title: '派发失败',
        icon: <ExclamationCircleOutlined />,
        content: (
          <>线索{res?.unAssignedLeadIds?.leadIds?.map(o => `【${o}】`)}
            派发失败，“考察库”库满，请变更指定座席后再分配
          </>
        ),
        okText: '确认',
      })
      return true
    } else {
      message.success('派发线索成功')
    }

    if (filter.queryType === LeadPoolQueryTypeEnum.AFTER_UPDATE) {
      reload()
    } else {
      setFilter({ queryType: LeadPoolQueryTypeEnum.AFTER_UPDATE })
    }
  }, [selectedLeadList, filter, reload, setFilter])

  /* 分配客户 */
  // 根据cid去重后的线索列表
  const filteredLeadList = useMemo(() => uniqBy(selectedLeadList, 'cid'), [selectedLeadList])
  const submitAssignCustomer = useCallback(async (formData: FormDataType) => {
    const {
      taskName,
      sales: salesNames,
      isAssignToOfflineSales: ifAssignToOfflineSales,
      remark,
      notifySales,
      extraFormId,
    } = formData

    const payload = {
      filter: { cid: selectedLeadList.map(lead => lead.cid) },
      taskName,
      salesNames,
      ifAssignToOfflineSales,
      remark,
      notifySales,
      extraFormId,
    }
    const [, err] = await assignCustomer(payload)
    if (err) {
      message.error(`${err.message}`)
      return true
    }
    message.success('分配客户成功')

    if (filter.queryType === LeadPoolQueryTypeEnum.AFTER_UPDATE) {
      reload()
    } else {
      setFilter({ queryType: LeadPoolQueryTypeEnum.AFTER_UPDATE })
    }
  }, [selectedLeadList, filter, reload, setFilter])

  return (
    <>
      <PageHeader title={leadStatusTitleMap.get(statusParam)} />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          initValue={initFilterValue}
          itemList={leadFilterItemsMap.get(statusParam)}
          onSubmit={(filter) => {
            const status = filter.status || initFilter.status
            setFilter({
              ...filter,
              page: 1,
              queryType: LeadPoolQueryTypeEnum.DEFAULT,
              status: Array.isArray(status) ? status : [status],
              leadIds: filter?.leadIds && [filter?.leadIds],
            })
          }}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={leadTableIdMap.get(statusParam)} onSuccess={() => fr()} />
        <span className="ml20 mr15">{selectedRowText}</span>
        {claimLeadBtn.includes(statusParam) && (
          <AuthChecker points="reassignLead">
            <BatchAssign
              title="派发线索"
              banner={
                <span>
                  当前已选择{selectedLeadList.length}条线索，其中{selectedLeadList.length - claimedLeadList.length}条线索无跟进人，将按下面指定方式派发。
                  {claimedLeadList.length > 0 && (
                    <>
                      <span>另{claimedLeadList.length}条线索已有跟进人，将派发给原跟进人。</span>
                      <a onClick={() => setShowClaimedLeadList(val => !val)}>{showClaimedLeadList ? '隐藏' : '展开'}已有跟进人的线索</a>
                    </>
                  )}
                </span>
              }
              claimedLeadList={showClaimedLeadList ? claimedLeadList : undefined}
              className="ml10"
              onSubmit={submitAssignAction}
            >
              <Button type="primary" disabled={selectedLeadList?.length <= 0}>派发线索</Button>
            </BatchAssign>
          </AuthChecker>
        )}
        {AssignCustomerBtn.includes(statusParam) && (
          <AuthChecker points="assignCustomer">
            <BatchAssign
              title="分配线索对应的客户"
              banner={<span>当前已选择{selectedLeadList.length}条线索，其中筛选出{filteredLeadList.length}个客户，将按下面指定方式分配。</span>}
              className="ml10"
              onSubmit={submitAssignCustomer}
              needRemark
              needNotifySales
            >
              <Button type="primary" disabled={selectedLeadList?.length <= 0 || loading}>分配客户</Button>
            </BatchAssign>
          </AuthChecker>
        )}
        {tagLeadBtn.includes(statusParam) && (
          <BatchAction
            title="打标线索"
            type="tag"
            className="ml10"
            banner={<span>当前已选择{selectedLeadList.length}条线索。</span>}
            onSubmit={submitBatchTag}
          >
            <Button disabled={selectedLeadList?.length <= 0 || loading}>打标线索</Button>
          </BatchAction>
        )}
        {downloadBtn.includes(statusParam) && (
          <TableDownload className="ml10" exportMethod={exportLeadList} filter={filter} />
        )}
        {robotBtn.includes(statusParam) && (
          <CallRobot
            sourceType={statusParam === LEAD_PAGE_UNCLAIMED
              ? CallRobotSourceEnum.LEAD_LIST : CallRobotSourceEnum.LEAD_LIST_SENT}
            filterType={RobotFilterTypeEnum.LEAD}
            filteredLeadList={filteredLeadList}
            selectedLeadList={selectedLeadList}
            filterCondition={JSON.stringify({ ...filter, ...(selectedLeadList?.length > 0 ? { leadIds: selectedLeadList?.map(o => o?.leadId) } : {}) })}
          />
        )}
        <Table
          style={{ maxWidth: 50 + tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          dataSource={leadList}
          onChange={onTableChange}
          pagination={pagination}
          rowSelection={rowSelection}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          columns={columnProps}
        />
        {contextHolder}
      </Card>
    </>
  )
};
