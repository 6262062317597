/**
 * 公用自动补全
 * 注意： children只能用来自定义输入框，不能设置options，这是和原antd不同的地方
 */
import { SearchedCustomer, SearchedCustomerFilter } from '@types'
import { searchCustomerName } from '@services/customer'
import { CommonAutoCompleteProps } from './index'
import { Input, message } from 'antd'
import React from 'react'
import { isEmptyWithCustom } from '@library'

// 搜索企业名称, 只能点击自定义的按钮进行搜索，不会自动触发自动补全拉取
export const searchCustomer: CommonAutoCompleteProps<SearchedCustomerFilter, SearchedCustomer> = {
  name: '企业名称',
  filter: { page: 1, pageSize: 30 },
  service: searchCustomerName,
  valueKey: 'name',
  searchKey: 'name',
  startSearch: -1,
  labelKey: 'name',
  allowClear: false,
  children: (loading, inputVal, fetch) => (
    <Input.Search
      loading={loading}
      enterButton="查询客户"
      onSearch={() => {
        if (isEmptyWithCustom(inputVal)) {
          message.error('请输入名称')
          return
        }
        fetch(inputVal)
      }}
    />),

}
