import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Card, Input, InputNumber, Button, Collapse, Space, message, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { createForm, Form } from '@library'
import { createSubForm } from '@tencent/meta-form'
import { v4 as uuid } from 'uuid'
import { get, pick, cloneDeep, set } from 'lodash'
import { getUserAuthPoint } from '@services/session'
import {
  GetQualityCheckPlanListAPI,
  CreateQualityCheckPlanAPI,
  UpdateQualityCheckPlanAPI,
} from '@services/quality-inspection'
import {
  DimensionsItemType,
  DimensionsItemsItemType,
  CreateOrUpdateualityCheckPlanParamsType,
} from '@types'
import AUTH_POINT_MAP from '@configs/auth-point'
import './style.less'

const { menuTaskCallEvaluateU } = AUTH_POINT_MAP

interface FormValues {
  /** 方案名称 */
  name: string;
  /** 合格分数标准 */
  qualifiedScore: number | string;
  /** 评分项 */
  dimensions: DimensionsItemType[];
}

// 获取详情后，对详情数据做处理，保留必要的字段[方案名称, 合格分数标准, 评分项]，其余删除
const mustKeys = ['name', 'qualifiedScore', 'dimensions']

/**
 * 获取评分项[完整/质检维度/质检项]默认值
 * @param type [质检维度/质检项]
 * @returns 评分项默认值[完整表单/质检维度/质检项]
 */
const getScoringItem = (type?: 'one' | 'two') => {
  /** 质检项 */
  const sub = {
    name: '',
    score: '',
    requirement: '',
    subId: uuid(),
  }
  if (type === 'two') return sub
  /** 质检维度 */
  const scoreItem = {
    name: '',
    id: uuid(),
    items: [{ ...sub }],
    itemsTotalScore: 0,
  }
  if (type === 'one') return scoreItem
  const full = {
    name: '',
    qualifiedScore: '',
    dimensions: [{ ...scoreItem }],
  }
  return full
}

/** 质检项表单控件配置项 */
// const markItemOptions = [
//   { key: 'name', el: <Input maxLength={50} placeholder="质检项名称" /> },
//   { key: 'score', el: <InputNumber min={0} max={100} precision={0} placeholder="请输入分值" /> },
//   { key: 'requirement', el: <Input maxLength={50} placeholder="质检项要求，非必填" /> },
// ]

const formStore = createForm(getScoringItem() as FormValues)
createSubForm(formStore, 'dimensions')
const { useValues, useErrors, useValidate, Field, withForm } = formStore

const QualityInspectionDetail: React.FC = () => {
  const { validateAndScroll } = useValidate()
  const { values, resetValues, setValues } = useValues()
  const { setError, removeError } = useErrors()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') || ''
  const type = searchParams.get('type') || ''
  // 是否为更新方案
  const isUpdate = useMemo(() => (id && type === 'view'), [id, type])
  const [loading, setLoading] = useState(false)
  /** 折叠面板激活key */
  const [activeKey, setActiveKey] = useState([])
  const [authPoint, setAuthPoint] = useState(null)
  const [hasAuth, setHasAuth] = useState(false)
  const markItemOptions = useMemo(() => {
    return ([
      { key: 'name', width: '70%', el: <Input maxLength={50} placeholder="质检项名称" /> },
      { key: 'score', width: '10%', el: <InputNumber min={0} max={100} precision={0} placeholder="请输入分值" /> },
      { key: 'requirement', width: '20%', el: <Input maxLength={128} placeholder="质检项要求，非必填" /> },
    ])
  }, [authPoint])

  /**
   * 获取权限点
   */
  const getAuthPoint = async () => {
    const [res] = await getUserAuthPoint([{ object: 'tcc.menu.task.call.evaluate', action: 'u' }])
    const pointList = get(res, 'authPoints', [])
    const obj = {}
    pointList.forEach(item => {
      const key = `${item.object}-${item.action}`
      obj[key] = item.result
    })
    // const obj1 = { 'tcc.menu.task.call.evaluate-u': false }
    setHasAuth(obj[menuTaskCallEvaluateU])
    setAuthPoint(obj)
  }

  useEffect(() => {
    getAuthPoint()
  }, [])

  /** 获取质检方案详情 */
  const getQualityCheckPlanDetail = async () => {
    const params = {
      page: 1,
      pageSize: 1,
      id: Number(id),
    }
    const [res, err] = await GetQualityCheckPlanListAPI(params)
    if (err) {
      message.error('方案不存在！')
      return
    }
    const list = [pick<FormValues>(get(res, 'list.0', {}), mustKeys)]
    list.forEach((item) => {
      const { dimensions } = item
      if (type === 'copy') {
        item.name += '- 副本'
      }
      dimensions.forEach((item: DimensionsItemType) => {
        const { items } = item
        item.id = uuid()
        item.itemsTotalScore = items.reduce((pre, cur) => (pre + (Number(cur.score) ?? 0)), 0)
        items.forEach((i: DimensionsItemsItemType) => {
          i.subId = uuid()
        })
      })
    })
    setValues(list[0])
  }

  useEffect(() => {
    if (id && type) {
      getQualityCheckPlanDetail()
    }
  }, [id, type])

  /**
   * 评分项onClick - 新增[一级/二级]/删除
   * @param lv 评分项等级 [一级/二级]
   * @param type 按钮类型 [新增/删除]
   */
  const handleScoreOnClick = (lv: 'one' | 'two', type: 'add' | 'del', index?: number | string, e?) => {
    e && e.stopPropagation()
    const dimensions = cloneDeep(values.dimensions)
    const totalScore = dimensions.reduce((pre, cur) => (pre + cur.itemsTotalScore), 0)
    if (lv === 'one') {
      if (type === 'add') {
        // DESC 质检维度最多可增加50个，并且分数总和不能超过100，若已新增10个，分数已达到100，则不能新增
        const isAdd = totalScore < 100 && dimensions.length < 50
        if (!isAdd) {
          message.warning('质检维度最多可添加50个 并且 各质检维度分值总和不能超过100')
          return
        }
        const scoreItem = getScoringItem(lv) as DimensionsItemType
        setActiveKey([...activeKey, scoreItem.id])
        dimensions.push(scoreItem)
      } else {
        const id = dimensions[index].id
        setActiveKey(v => v.filter(i => i !== id))
        dimensions.splice(index as number, 1)
      }
    } else {
      if (type === 'add') {
        // DESC 质检项最多可增加20个，并且质检维度下质检项分数总和不能超过100，若已新增10个，分数已达到100，则不能新增
        const subTotalScore = dimensions[index].itemsTotalScore
        const isAdd = subTotalScore < 100 && dimensions[index].items.length < 20
        if (!isAdd || totalScore === 100) {
          const warnMsg = totalScore === 100 ? '质检维度总分值已达到100，不能再添加' : '质检项最多可添加20个 并且 各质检项分值总和不能超过100'
          message.warning(warnMsg)
          return
        }
        dimensions[index].items.push(getScoringItem(lv) as DimensionsItemsItemType)
      } else {
        const indexArr = (index as string).split('-').map(i => Number(i))
        dimensions[indexArr[0]].items.splice(indexArr[1], 1)
      }
    }
    // 更新质检项总分值
    dimensions.forEach(item => {
      item.itemsTotalScore = item.items.reduce((pre, cur) => (pre + (Number(cur.score) ?? 0)), 0)
    })
    setValues({ dimensions })
  }

  /**
   * 处理表单onChange事件
   * @param path 修改属性路径
   * @param val 值
   */
  const handleFieldOnChange = (path: string, val: string | number) => {
    const dimensions = cloneDeep(values.dimensions)
    set({ dimensions }, path, val)
    // 生成总分数
    if (path.includes('items')) {
      const pathArr = path.split('.')
      const itemsTotalScorePath = pathArr.slice(0, 2).join('.')
      const currentDetail = get(dimensions, `${[pathArr[1]]}.items`, [])
      const itemsTotalScore = currentDetail.reduce((pre, cur) => {
        return pre + (cur?.score ?? 0)
      }, 0)
      set({ dimensions }, `${itemsTotalScorePath}.itemsTotalScore`, itemsTotalScore)
    }
    setValues({ dimensions })
  }

  /** 提交方案 */
  const handleSubmit = async () => {
    const validateRes = await validateAndScroll()
    // 校验，如果不通过直接 return
    if (!validateRes.isPass) {
      message.error('未填写完整')
      return
    }
    // 合格分数标准：上限100，或 <= 多质检维度分值之和
    const totalScore = values.dimensions.reduce((pre, cur) => (pre + cur.itemsTotalScore), 0)
    if (Number(values.qualifiedScore) > totalScore) {
      setError({ qualifiedScore: ' ' })
      return message.error('合格分数标准需 <= 多质检维度分值之和')
    } else {
      removeError('qualifiedScore')
    }
    setLoading(true)
    const API = isUpdate ? UpdateQualityCheckPlanAPI : CreateQualityCheckPlanAPI
    const dimensions = cloneDeep(values.dimensions)
    dimensions.forEach(item => {
      delete item.id
      delete item.itemsTotalScore
      item.items.forEach(subItem => {
        delete subItem.subId
      })
    })
    const params: CreateOrUpdateualityCheckPlanParamsType = {
      ...values,
      dimensions,
      remark: '',
    }
    if (isUpdate) {
      params.id = Number(id)
    }
    const [res, err] = await API(params)
    setLoading(false)
    if (res) {
      message.success('保存成功')
      resetValues()
      history.push('/task/quality-inspection')
    } else {
      message.error(err.message)
    }
  }

  return (
    <Card
      className="quality-inspection-detail-page"
      title={
        <Link to="/task/quality-inspection">
          <ArrowLeftOutlined style={{ marginRight: 10 }} />
          {hasAuth && <span>{isUpdate ? '编辑' : '新建'}方案</span>}
          {!hasAuth && <span>查看方案</span>}
        </Link>
      }
    >
      <Form colon={false}>
        <Row>
          <Col span={8}>
            {hasAuth ? (
              <Field
                field="name"
                label="方案名称"
                required
              >
                <Input disabled={!hasAuth} bordered={hasAuth} placeholder="请输入质检方案名称" maxLength={20} />
              </Field>)
              : <span>方案名称：{values?.name || '-'}</span>}
          </Col>
          <Col span={10} offset={4} className="col-flex">
            {hasAuth ? (
              <Field
                field="qualifiedScore"
                label="合格分数标准"
                required
              >
                <InputNumber disabled={!hasAuth} bordered={hasAuth} min={0} max={100} precision={0} />
              </Field>)
              : <span>合格分数标准：{values?.qualifiedScore ?? '-'}</span>}
            <span className="embellish-text mb24 ml10">分及以上为及格</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: 14 }}>
          <Col>
            <div className="ant-form-item-label">
              <label className={`${hasAuth && 'ant-form-item-required'} ant-form-item-no-colon`} title="评分项">评分项</label>
              {hasAuth && <Button type="primary" size="small" onClick={() => handleScoreOnClick('one', 'add')}>新建</Button>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Collapse className="mark-collapse" activeKey={activeKey} onChange={(v: string[]) => setActiveKey(v)}>
              {/* 渲染 - 质检维度 */}
              {values.dimensions.map((item, index) => (
                <Collapse.Panel
                  header={
                    <>
                      {hasAuth ? (
                        <Field
                          field={`dimensions.${item.id}.name`}
                          label={<></>}
                          onChange={(v: string) => handleFieldOnChange(`dimensions.${index}.name`, v)}
                          value={item.name}
                          required
                        >
                          <Input maxLength={20} placeholder="质检维度名称" onClick={e => e.stopPropagation()} />
                        </Field>)
                        : <p style={{ width: 336 }}>{item.name}</p>}
                      <span className="embellish-text ml10">{item.itemsTotalScore}分</span>
                    </>
                  }
                  key={item.id}
                  extra={
                    <>
                      {hasAuth && (
                        <Space>
                          <Button type="link" size="small" onClick={e => handleScoreOnClick('two', 'add', index, e)}>新增</Button>
                          {values.dimensions.length !== 1
                            && <Button type="link" size="small" onClick={e => handleScoreOnClick('one', 'del', index, e)}>删除</Button>}
                        </Space>)}
                    </>
                  }
                >
                  {/* 渲染 - 质检项 */}
                  {item.items.map((items, dIndex) => (
                    <div className="mark-item" key={items.subId}>
                      <div className="input-container">
                        {/* 渲染 - 质检项 - 表单控件 */}
                        {markItemOptions.map(({ key, el, width }) => (
                          hasAuth ? (
                            <Field
                              key={`${items.subId}_${key}`}
                              field={`dimensions.${item.id}.items.${items.subId}.${key}`}
                              label={<></>}
                              onChange={(v :string) => handleFieldOnChange(`dimensions.${index}.items.${dIndex}.${key}`, v)}
                              value={items[key]}
                              required={key !== 'requirement'}
                            >
                              {el}
                            </Field>)
                            : (
                              <Typography.Paragraph ellipsis={{ rows: 2, tooltip: items[key] }} style={{ marginRight: 16, width, marginBottom: 0 }}>{items[key]}</Typography.Paragraph>
                            )
                        ))}
                      </div>
                      {(item.items.length > 1 && hasAuth) && (
                        <Button
                          size="small"
                          type="link"
                          onClick={() => handleScoreOnClick('two', 'del', `${index}-${dIndex}`)}
                        >
                          删除
                        </Button>
                      )}
                    </div>
                  ))}
                </Collapse.Panel>
              ))}
            </Collapse>
          </Col>
        </Row>
        {hasAuth && (
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            <Button className="mr15" loading={loading} disabled={loading} onClick={() => resetValues()}>
              重置
            </Button>
            <Button type="primary" loading={loading} disabled={loading} onClick={handleSubmit}>
              提交
            </Button>
          </div>)}
      </Form>
    </Card>
  )
}

export default withForm(QualityInspectionDetail)
