import React from 'react'
import classNames from 'classnames'
import Icons from './svgs'
import PhoneListIcon from './PhoneList'
const sizeMap = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 32,
}

type IconProps = {
  name: keyof typeof Icons;
  size?: keyof typeof sizeMap | number;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const Icon = (props: IconProps) => {
  const size
    = typeof props.size === 'number' ? props.size : sizeMap[props.size || 'sm']
  const { className, name, style, onClick } = props
  return (
    <span
      className={classNames(className, `icon-${name}`)}
      style={{ width: size, height: size, ...style }}
      onClick={onClick}
    >
      {Icons[name]}
    </span>
  )
}

export type IconName = keyof typeof Icons;
export { PhoneListIcon }
