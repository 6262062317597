import { useState } from 'react'
import moment from 'moment'
import { message } from 'antd'
import { GetT3CCallRecordDetailAPI } from '@services/quality-inspection'
import { CallEventTextEnum, CallEventEnum, T3CCallRecordDetailType, SpeechTextItemType } from '@types'
import { getStaffName } from '@library'
import { cosGetObject } from '../../../library/cosUtils'
import { cloneDeep, get } from 'lodash'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/

export const useCallRecordDetail = () => {
  const [callRecordDetail, setCallRecordDetail] = useState<Partial<T3CCallRecordDetailType>>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  /**
   * 将blob转换为文本的格式
   * @param blob Blob对象
   */
  const blobToText = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject(new Error('Failed to read blob as text'))
        }
      }
      reader.onerror = reject
      reader.readAsText(blob)
    })
  }

  /**
   * 获取语音转文本信息
   * @param key cos文件key
   * @param startTime 通话开始时间 - 振铃时间
   */
  const getText = async (key: string, startTime: string) => {
    let data: SpeechTextItemType[] = null
    try {
      const [blob] = await cosGetObject(key)
      const text = await blobToText(blob)
      data = JSON.parse(text)
    } catch (error) {
    }
    if (!data) return []
    data.forEach((item: SpeechTextItemType) => {
      const sTime = moment(item.Start)
      const eTime = moment(item.End)
      item.isCustomerService = emailRegex.test(item.User)
      item.startStandardTime = sTime.format('YYYY-MM-DD HH:mm:ss')
      item.endStandardTime = eTime.format('YYYY-MM-DD HH:mm:ss')
      item.startDiffSeconds = sTime.diff(startTime, 'seconds')
      item.endDiffSeconds = eTime.diff(startTime, 'seconds')
      const duration = moment.duration(sTime.diff(startTime))
      item.diffTime = [duration.hours(), duration.minutes(), duration.seconds()].reduce((pre, cur, index) => {
        return pre + String(cur).padStart(2, '0') + (index === 2 ? '' : ':')
      }, '')
    })
    return data ?? []
  }

  /**
   * 获取T3C通话记录详情
   * @param recordId 通话记录ID
   * @param detail 通话记录详情 - 包含一些简单信息。通过列表接口获取到的
   * @param isGetText 是否获取语音转文本信息
   */
  const getT3CCallRecordDetail = async (recordId, detail: any = {}, isGetText: boolean = false) => {
    setLoading(true)
    const [res, err] = await GetT3CCallRecordDetailAPI(Number(recordId))
    const newDetail = cloneDeep({ ...detail, ...(res || {}) })
    setLoading(false)
    if (err) {
      setCallRecordDetail(newDetail)
      message.error(err.message || '请稍后刷新重试！')
      return
    }
    // 对时间轴数据做处理
    let startTime = get(newDetail, 'callEventList.0.timestamp', 0)
    startTime = moment(Number(startTime) * 1000).format('YYYY-MM-DD HH:mm:ss')
    /* eslint-disable */
    newDetail?.callEventList?.forEach((item) => {
      let eventText = `${CallEventTextEnum[item.event]}`
      if ([CallEventEnum.Start, CallEventEnum.StaffEnded].includes(item.event)) {
        eventText = `${getStaffName(newDetail.salesName, newDetail.salesNameCh)} ${eventText}`
      } else if ([CallEventEnum.ClientUserRinging, CallEventEnum.ClientUserAccepted].includes(item.event)) {
        eventText = `${newDetail.phone} ${eventText}`
      }
      item.eventText = eventText
      // 标准时间
      const standardTime = moment(Number(item?.timestamp ?? 0) * 1000).format('YYYY-MM-DD HH:mm:ss')
      item.standardTime = standardTime
      const endTime = moment(standardTime)
      // 和 呼出时间 的 时间差
      const duration = moment.duration(endTime.diff(startTime))
      if (item.event === CallEventEnum.End) {
        newDetail.audioMaxS = duration.hours() * 3600 + duration.minutes() * 60 + duration.seconds()
      }
      item.diffTime = [duration.hours(), duration.minutes(), duration.seconds()].reduce((pre, cur, index) => {
        return pre + String(cur).padStart(2, '0') + (index === 2 ? '' : ':')
      }, '')
    })
    /* eslint-enable */
    if (newDetail.asrUrl && isGetText) {
      try {
        let callStartTime = get(newDetail, 'callEventList.1.timestamp', 0)
        callStartTime = moment(Number(callStartTime) * 1000).format('YYYY-MM-DD HH:mm:ss')
        newDetail.speechTextList = await getText(newDetail.asrUrl, callStartTime)
      } catch (error) {
        console.error('语音转文本失败！')
      }
    }
    setCallRecordDetail(newDetail)
    setOpen(true)
  }

  return ({ callRecordDetail, setCallRecordDetail, getT3CCallRecordDetail, open, setOpen, loading })
}
