/**
 * 批量分配
 * @author tylerzzheng
 */
import React, { Children, cloneElement, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Input, Modal, Radio, Table } from 'antd'
import { CommonSelector } from '@components'
import { AssignToOfflineSales, AssignType, ILeadPoolItem, IOrgGroup, IsNotifySales } from '@types'
import { createForm, Form, notEmptyArray } from '@library'
import { groupId, salesName } from '../CommonSelector/configs'
import { ColumnProps as AntdColumnProps } from 'antd/lib/table'
import { getLeadPoolItem, LeadPoolItem } from '@pages/lead/list'
import moment from 'moment'
import store from '@store'
import { flatten, uniqBy, uniq, omit } from 'lodash'

interface BatchAssignProps {
  title?: string,
  banner?: React.ReactNode
  // 已有跟进人的列表, 会用table组件去展示该列表
  claimedLeadList?: ILeadPoolItem[]
  onSubmit: (formData: FormDataType) => Promise<boolean | void>, // 当返回为true时，弹窗不会被关闭
  needRemark?: boolean, // 表单项是否需要分配说明项， 默认false
  needNotifySales?: boolean, // 表单项是否需要【执行后提醒给座席发送企点消息】， 默认false
  [propName: string]: any,
}

interface FormValues {
  assignType: AssignType,
  taskName: string, // 任务名称
  selectedGroup: IOrgGroup[], // 暂时用的，根据所选群组id再拿到所有成员，submit的时候要omit掉
  sales: string[],
  remark?: string,
  notifySales?: IsNotifySales,
  isAssignToOfflineSales: AssignToOfflineSales,
  extraFormId?: number
}
export type FormDataType = Omit<FormValues, 'selectedGroup'>

const formStore = createForm<FormValues>()
const { useValues, useValidate, Field, withForm } = formStore

function BatchAssign (props: PropsWithChildren<BatchAssignProps>) {
  const {
    title = '批量分配',
    banner,
    onSubmit,
    claimedLeadList = [],
    needRemark = false,
    needNotifySales = false,
    children,
    ...otherProps
  } = props

  const { validate } = useValidate()
  const { values, setValues } = useValues()

  const { assignType, selectedGroup, sales } = values

  const [showAssignModal, setShowAssignModal] = useState(false)
  const [loading, setLoading] = useState(false)

  // 入口渲染
  const renderEntrance = () => {
    if (!children) {
      return (<Button {...otherProps} onClick={() => setShowAssignModal(true)}>{title}</Button>)
    } else {
      // 只能有一个子元素
      Children.only(children)

      return cloneElement(children as React.ReactElement, { ...otherProps, onClick: () => setShowAssignModal(true) })
    }
  }

  const leadList: LeadPoolItem[] = useMemo(() => claimedLeadList.map(lead => getLeadPoolItem(lead)), [claimedLeadList])
  const columnProps = useMemo<AntdColumnProps<LeadPoolItem>[]>(() => [
    { title: '线索id', dataIndex: 'leadId', width: 120 },
    { title: '当前归属业务组', dataIndex: 'getClaimedGroupsText', width: 220 },
    { title: '当前跟进人', dataIndex: 'follower', width: 120 },
  ], [])

  // 表单初始化
  const { userInfo } = store.useSession()
  useEffect(() => {
    setValues({
      taskName: userInfo.salesNameCh + moment().format('YYYYMMDD'),
      assignType: AssignType.ALL,
      selectedGroup: [],
      sales: [],
      isAssignToOfflineSales: AssignToOfflineSales.YES,
      remark: '',
      notifySales: IsNotifySales.NO,
      extraFormId: undefined,
    })
  }, [showAssignModal, userInfo])

  // 当选择的群组变化时，自动置空表单的座席sales, 这里默认全不选。 且分配方式变为全部座席
  useEffect(() => {
    setValues({
      sales: [],
      assignType: AssignType.ALL,
    })
  }, [selectedGroup])

  // 当分配方式时，自动置空表单的座席sales，这里默认全不选。且当分配方式为指定成员时，异步获取所选的群组成员
  useEffect(() => {
    setValues({ sales: [] })

    if (assignType !== AssignType.CERTAIN) return // 当分配方式不是指定成员的时候，直接return

    if (notEmptyArray(selectedGroup)) {
      const temp = selectedGroup.map(group => group.groupMemberInfoList) ?? []
      const salesForSelect = uniqBy(flatten(temp), 'id')?.map(sale => sale?.salesName)
      setValues('sales', salesForSelect)
    }
  }, [assignType, selectedGroup])

  // 提交
  const submitAssignAction = useCallback(async () => {
    const validateRes = await validate()
    if (!validateRes.isPass) return

    setLoading(true)

    // 如果是全部，则要根据所选组获得所有成员
    const salesParam = assignType === AssignType.ALL ? uniq(flatten(selectedGroup?.map(group => group?.groupMember)))
      : Array.from(sales)

    const formData: FormDataType = {
      ...omit(values, ['selectedGroup', 'sales']),
      sales: salesParam,
    }

    const isErr = await onSubmit(formData)
    setLoading(false)
    if (!isErr) setShowAssignModal(false)
  }, [values, sales, onSubmit, selectedGroup])

  return (
    <>
      {renderEntrance()}
      {showAssignModal && (
        <Modal
          open
          width={650}
          title={title}
          confirmLoading={loading}
          onCancel={() => setShowAssignModal(false)}
          onOk={submitAssignAction}
          okText="提交"
        >
          {banner && (<Alert message={banner} type="info" showIcon />)}
          {notEmptyArray(claimedLeadList) && (
            <Table
              title={() => '已有跟进人线索列表'}
              size="small"
              scroll={{ y: '200px' }}
              tableLayout="fixed"
              className="mt20"
              dataSource={leadList}
              pagination={{ pageSize: leadList.length, hideOnSinglePage: true }}
              columns={columnProps}
              rowKey="leadId"
            />
          )}
          <Form className="mt20" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Field
              field="taskName"
              label="任务名称"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              required
            >
              <Input placeholder="任务名称必填" />
            </Field>
            <Field
              field="selectedGroup"
              label="将对应的客户分配给"
              required={!Array.from(sales).length}
            >
              {({ value, onChange }) => (
                <CommonSelector
                  {...groupId}
                  value={value?.map?.(group => group?.groupId)}
                  onChanges={(groupIds, groupList) => onChange(groupList)}
                  mode="multiple"
                />)}
            </Field>
            <Field field="assignType" label="分配方式">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={AssignType.ALL}>全部座席</Radio.Button>
                <Radio.Button value={AssignType.CERTAIN}>指定座席
                </Radio.Button>
              </Radio.Group>
            </Field>
            {assignType === AssignType.CERTAIN && (
              <Field field="sales" label="指定座席" required>
                <CommonSelector {...salesName} mode="multiple" />
              </Field>
            )}
            <Field field="isAssignToOfflineSales" label="座席状态">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={AssignToOfflineSales.YES}>无视座席签到状态</Radio.Button>
                <Radio.Button value={AssignToOfflineSales.NO}>仅分配给已签到的座席</Radio.Button>
              </Radio.Group>
            </Field>
            {needRemark && (
              <Field
                field="remark"
                label="分配说明"
                required
              >
                <Input />
              </Field>
            )}
            {needNotifySales && (
              <Field
                field="notifySales"
                label="执行后提醒"
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={IsNotifySales.NO}>不通知座席</Radio.Button>
                  <Radio.Button value={IsNotifySales.YES}>给座席发企点消息</Radio.Button>
                </Radio.Group>
              </Field>
            )}
          </Form>
        </Modal>
      )}
    </>
  )
}

export default withForm(BatchAssign)
