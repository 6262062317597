import { requestApiV2 } from '@library'
import { T3CConfig } from '@types'
export function getT3cSdkUrl (payload?: { refresh: boolean }) {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/CreateLoginToken', payload)
}

export function addT3CConfig (payload: T3CConfig) {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/CreateBizT3CApp', payload)
}

export function getT3CConfig () {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/GetBizT3CApp')
}

export function editT3CConfig (payload: T3CConfig) {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/UpdateBizT3CApp', payload)
}

export function getCallDuration (payload?: { startTime?: string; endTime?: string }) {
  return requestApiV2<{ callDuration: number; callTime: number }>('/trpc.tcc.tcc_main.Call/GetT3CSalesCallInfo', payload)
}
