/**
 * @author tylerzzheng
 */

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useObject } from '@hooks/useObject'
import { AsyncTotal, needTotalEnum, Pagination, StaffisAdmin } from '@types'
import { useShowError } from '@hooks/useShowError'
import { useService } from '@hooks/useService'
import { commonTablePaginationConfig, errAsyncTotalText, loadingAsyncTotalText } from '@configs/table'
import { getResList, isNullOrUndefined, ServiceType } from '@library'
import store from '@store'
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface'
import { set, isEqual } from 'lodash'

/**
 * 当使用Table的数据服务，分页，筛选，行选择时，可选择本hooks。返回的数据按需使用
 *
 * @param service 接口请求
 * @param initFilter 初始筛选参数 并且根据needTotal的类型为只获取list时，会自动异步获取total
 * @param errMsg 当接口报错的时候提示信息
 * @param rowKey table组件的rowKey,传给table组件的rowKey必须和本函数的入参相同。会给T类型添加一个名为rowKey值的字段（默认_index），其字段值为list数组的下标
 */
export function useTableService<F extends object | Pagination | AsyncTotal, T extends object> (service: ServiceType<F, T>, initFilter: F, errMsg: string = '获取数据失败', rowKey: string = '_index') {
  /* 筛选 */
  const [filter, setFilter, resetFilter] = useObject(initFilter)

  /* 数据 */
  const [loading, res, err, forceRequest] = useService(service, filter)
  useShowError(errMsg, err)
  const [list, serviceTotal] = useMemo(() => {
    const [list, total] = getResList(res, [])

    /**
     * 这里给listItem加一个_index字段，主要目的是作为antd的table组件的rowKey和rowSelection的标记,后端返回的字段无法信任
     （之前采用的是自带的ID字段，后端说ID也不能保证不重复的，\摔），
     而ID重复后table组件渲染以及rowSelection会出现严重bug(见：http://tapd.oa.com/telesalescrm/prong/stories/view/1020419919864531635#)，
     所以给每个item一个_index字段
     */
    list.map((item, index) => set(item, rowKey, index))

    return [list, total]
  }, [res])

  /**
   * 重新加载数据，reset默认为false，为true时重新加载数据并重置filter，false时只重新加载本页数据，其它项不变
   * 注意！！ resetFilter需手动重置页面筛选项，不是受控的，这里只会重置上送数据
   */
  const reload = useCallback((reset: boolean = false) => {
    if (reset) {
      isEqual(initFilter, filter) ? forceRequest() : resetFilter(initFilter)
    } else {
      forceRequest()
    }
  }, [forceRequest, filter, initFilter])

  // 系统管理员，只请求list的方式下， 首页不加载异步的total
  const { userInfo } = store.useSession()
  const notFetchTotal = useMemo(() => userInfo.isAdmin === StaffisAdmin.ISADMIN
    && (filter as Pagination)?.page === 1
    && (filter as AsyncTotal)?.needTotal === needTotalEnum.LIST, [filter, userInfo])

  /* 分页 */
  const [asyncTotal, setAsyncTotal] = useState<number>(-1) // 异步total，单独去取total
  const [asyncTotalLoading, setAsyncTotalLoading] = useState(false)
  const [asyncTotalErr, setAsyncTotalErr] = useState<Error>(undefined)
  useEffect(() => {
    // 首先设置一个假的total，总比当前页多一页，在请求返回前，不影响用户翻页
    setAsyncTotal(((filter as Pagination)?.page ?? 0) * ((filter as Pagination)?.pageSize ?? 0) + 1)

    // 管理员首页不请求total
    if (notFetchTotal) return

    // 如果是只请求list的方式，那么在这里获取异步的total。
    if ((initFilter as AsyncTotal)?.needTotal === needTotalEnum.LIST) {
      ;(async () => {
        setAsyncTotalErr(undefined)
        setAsyncTotalLoading(true)
        const [res, err] = await service({ ...filter, needTotal: needTotalEnum.TOTAL })
        if (err) {
          setAsyncTotalErr(err)
        } else {
          setAsyncTotal(getResList(res)[1])
        }
        setAsyncTotalLoading(false)
      })()
    }
  }, [filter])

  // 默认取中原service的total。如果是只请求list的方式，那么取异步的total
  const total = useMemo(() => (filter as AsyncTotal)?.needTotal === needTotalEnum.LIST ? asyncTotal : serviceTotal, [filter, serviceTotal, asyncTotal])

  const pagination: TablePaginationConfig = useMemo(() => {
    // 默认取默认配置的showTotal, 如果异步total加载中，设置为加载中的提示，异步失败是失败的提示，如果不获取total，则不显示
    const showTotal = notFetchTotal ? () => ''
      : !isNullOrUndefined(asyncTotalErr) ? () => errAsyncTotalText
        : asyncTotalLoading ? () => loadingAsyncTotalText : commonTablePaginationConfig.showTotal
    return ({
      ...commonTablePaginationConfig,
      showTotal,
      current: (filter as Pagination)?.page ?? 1,
      pageSize: (filter as Pagination)?.pageSize ?? 10,
      total,
      onShowSizeChange: (current, pageSize) => {
        setFilter({ pageSize, page: 1 } as F)
      },
      onChange: (page: number) => setFilter({ page } as F),
    })
  }, [filter, asyncTotalErr, total, asyncTotalLoading, notFetchTotal])

  /* 行选择 */
  const [indexList, setIndexList] = useState<number[]>([])
  useEffect(() => setIndexList([]), [res])
  const rowSelection: TableRowSelection<T> = useMemo(() => ({
    selectedRowKeys: indexList,
    onChange: (idxs) => setIndexList(idxs as number[]),
  }), [indexList, setIndexList])
  const selectedRowText: string = useMemo(() => `(${indexList.length}/${pagination?.pageSize})`, [indexList, pagination])

  return {
    /* 数据 */
    loading,
    list,
    // 非分页的情况下，返回list的length
    total,
    reload,

    /* 分页 */
    // 前端分页的情况下请勿使用
    pagination,

    /* 筛选 */
    filter,
    setFilter,
    resetFilter,

    /* 行选择 */
    // 这里保存选择的行的集合，值是list的下标。当翻页或筛选数据时，indexList会自动重置
    indexList,
    // 设置勾选的items，值为list的下标的集合
    setIndexList,
    rowSelection,
    // 客户行选择的文案
    selectedRowText,
    // table组件的rowKey,传给table组件的rowKey必须和本函数的入参相同 // T[rowKey] = 就是对象在原list中的下标
    rowKey,
  }
}
