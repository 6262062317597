/**
 * 通用table的配置
 * @author tylerzzheng
 */
import { ColumnProps } from 'antd/lib/table'
import { sortMap } from '@configs/map'
import { ISortOrder } from '@types'
import { isArray, isNullOrUndefined } from '@library'
import { CloseCircleOutlined, SyncOutlined } from '@ant-design/icons'
import React from 'react'
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { sum } from 'lodash'

export const PAGESIZE_OPTIONS = ['10', '20', '30', '40', '50', '100']

/**
 * 通用分页设置
 */
export const commonTablePaginationConfig: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  showTotal: total => `共${total}条数据`,
  showQuickJumper: true,
  showSizeChanger: true,
  // hideOnSinglePage: true,
  pageSizeOptions: PAGESIZE_OPTIONS,
}

export const loadingAsyncTotalText = <><SyncOutlined spin className="text-primary mr5" />总条数加载中...</>
export const errAsyncTotalText = <><CloseCircleOutlined className="text-danger mr5" />总条数加载超时</>

/**
 * 计算根据行配置计算列表宽带，可滚动的宽度
 * @param columnProps 行配置
 */
export function getTableXwidth<T> (columnProps: ColumnProps<T>[]) {
  return sum(columnProps.map(column => column.width ?? 100))
}

/**
 * 根据antd的table组件的onChange时的sorter对象，转成需要格式的sorter对象
 *
 * @warn antd4可以支持多列排序，也就是说sorter可以是数组，我们的业务暂不支持。所以这里不实现
 * @param sorter
 */
export function getSortOrder<T extends ISortOrder, E = any> (sorter: SorterResult<E> | SorterResult<E>[]): Pick<T, 'sort' | 'sortType'> {
  if (isArray(sorter)) {
    console.warn('暂不支持多列排序！！')
  }

  const sorterObj = isArray(sorter) ? sorter[0] : sorter
  const sortType = sortMap[sorterObj.order]
  const sort = isNullOrUndefined(sortType) ? undefined : sorterObj.field as T['sort']
  return { sortType, sort }
}

// 列表表头配置属性
export interface ColumnHeader {
  title: string
  dataIndex: string // 同antd的antd/lib/table中ColumnProps, 是唯一id
  hide?: boolean // 在列表上是否显示， 默认false显示. 此配置要从后端获取更新
  disabled?: boolean // 是否可以编辑，默认false可以
  index?: number // 排序
  defHide?: boolean // 是否默认隐藏，默认false不隐藏
}

/**
 * 每个table登记的id
 */
export enum TableIdEnum {
  TASK = 'TODO_TASK', // 待处理任务列表
  ALL_TASK = 'All_TASK', // 所有任务列席
  FOLLOW_RECORD = 'FOLLOW_RECORD', // 跟进记录列表
  SMS_RECORD = 'SMS_RECORD', // 短信记录列表
  CALL_RECORD = 'CALL_RECORD', // 电话记录列表
  TCCC_CALL_RECORD = 'TCCC_CALL_RECORD', // TCCC电话记录列表
  MEETING_RECORD = 'MEETING_RECORD', // 会议记录列表
  MEETING_CALL_RECORD = 'MEETING_CALL_RECORD', // 会议通话记录列表
  LEAD_CLAIMED = 'LEAD_CLAIMED', // 已分配线索
  LEAD_UNCLAIMED = 'LEAD_UNCLAIMED', // 线索公海池
  LEAD_DISCARD = 'LEAD_DISCARD', // 废弃线索池
  CUSTOMER_RESOURCE = 'CUSTOMER_RESOURCE', // 客户资源池，关联，考察池共用
  CUSTOMER_PURE_POOL = 'CUSTOMER_PURE_POOL', // 公海池
  CUSTOMER_ALTER = 'CUSTOMER_ALTER', // 客户转出池
  CUSTOMER_ASSIGN = 'CUSTOMER_ASSIGN', // 客户分配记录
  SALEOPPORTUNITY_PAGE = 'SALEOPPORTUNITY_PAGE', // 销售机会列表
  RETAIN_RECORD = 'RETAIN_RECORD', // 客户分配记录
  LEAD_CIRCULATE = 'LEAD_CIRCULATE', // 线索流转记录
  CUSTOMER_UNBIND = 'CUSTOMER_UNBIND', // 解绑客户
}
