/**
 *销售机会
 */

/**
 * 机会类型:1新购机会2复购机会3SMB合作机会4其他
 */
import { Pagination, ISortOrder } from './common'
import { IOrgGroup } from './organazition'
import { CustomerStatusEnum } from './customer'
export enum OpportunityTypeEnum {
  NEW_PURCHASE = 1,
  RE_PURCHASE = 2,
  COUNTINE_BUY = 5, // 续费机会
  SMB = 3,
  OTHER = 4,
  HIGH_POOTENTIAL = 6, // 高潜专项
}

/**
 * 机会状态:1进行中2已赢单3已关闭
 */
export enum OpportunityStatusEnum {
  PROCESSING = 1,
  SUCCEED = 2,
  CLOSED = 3,
}

/**
 * 机会状态:1进行中2已赢单3已关闭
 */
export enum OpportunityIsNewEnum {
  NO = 0,
  YES = 1,
}
/**
 * 客户预估类型，1企业、2个人、3伪个人 0未知
 */
export enum EstimateCustomerTypeEnum {
  COMPANY = 1,
  PERSON = 2,
  LIKE_PERSON = 3,
  UNKNOWN = 0,
}

/**
 * 赢单率:0 25% 50% 75% 90% 100%
 */
export enum OpportunityWinRateEnum {
  ZERO = '0',
  QUARTER = '0.25',
  HALF = '0.5',
  THREE_QUARTER = '0.75',
  NINETY_PERCENT = '0.9',
  HUNDRED_PERCENT = '1.0',
}

/**
 * 客户类别：0未知，1EU，2ISV
 */
export enum customerCategoryTypeEnum {
  UNKNOWN = 0,
  EU = 1,
  ISV = 2,
}

/**
 * 友商竞争：0其他，1阿里，2华为
 */
export enum competitorTypeEunm {
  OTHER = 0,
  ALI = 1,
  HUWEI = 2,
}

export enum stageInProgressTypeEnum {
  IDENTIFY = 0, // 识别商机
  CONFIRM = 1, // 确认商机
  PROJECT_APPROVAL = 2, // 确认立项
  NEGOTIATE = 3, // 商业谈判
}

// 获取销售机会的可关注产品列表 返回的对象
export interface FocusProduct {
  productDesc: string // "文件存储、对象存储，日志存储服务等",
  creator: string // "guorongjin"
  productId: number //  1,
  productName: string //  "加速产品"
}

// 获取销售机会应用场景 返回的对象
export interface OpportunityScene {
  id: number // 必须
  belongModule: number // 必须
  sceneDesc: string // 必须 应用场景描述
  createtime: string // 必须
  lastUpdateTime: string // 必须
  creator: string // 必须
  updator: string // 必须
}

// 获取销售机会列表 上送参数对象
export interface SaleOpportunityFilter extends Pagination, ISortOrder{
  sort?: 'createTime' | 'estimatedOrderTime' | 'updateTime' | 'money' | 'estimateOrderDuration'
  opportunityId?: number // 否 int 机会id
  name?: string // 否 int 机会名称
  cid?: string | string[] // 否 array|string 客户cid
  uin?: string | string[] // 否 array|string 客户uin
  type?: OpportunityTypeEnum // 非必须 机会类型1新购机会2复购机会3SMB合作机会4其他5续费机会
  status?: OpportunityStatusEnum // 否 int 机会状态:1进行中2已赢单3已关闭
  customerStatus?: CustomerStatusEnum // 客户是否被转出
  moneyFrom?: number // 否 int 机会金额，开始范围
  moneyTo?: number // 否 int 机会金额，结束范围
  isNew?: OpportunityIsNewEnum // 否 int是否新客:0非新客1新客
  focusProduct?: number // 否int关注产品
  winRate?: OpportunityWinRateEnum // 否 string 赢单率
  creator?: string | string[] // 否 array|string创建者
  lastModifier?: string | string[] // 否 array|string最后修改者
  createTimeFrom?: string // 否 string创建时间，开始时间（格式为2020-04-17 16:46:45）
  createTimeTo?: string // 否 string创建时间，结束时间（格式为2020-04-17 16:46:45）
  updateTimeFrom?: string // 否 string更新时间，开始时间（格式为2020-04-17 16:46:45）
  updateTimeTo?: string // 否 string更新时间，结束时间（格式为2020-04-17 16:46:45）
  estimatedOrderTimeFrom?: string // 否 string预计下单时间，开始时间（格式为2020-04-17 16:46:45）
  estimatedOrderTimeTo?: string // 否 string预计下单时间，结束时间（格式为2020-04-17 16:46:45）
  follower?: string[]
  groupId?: number

  estimateCustomerType?: EstimateCustomerTypeEnum // 非必须 客户预估类型，1企业、2个人、3伪个人
  customerPredictFollowTimeFrom?: string // 非必须 下次跟进时间开始时间
  customerPredictFollowTimeTo?: string // 非必须 下次跟进时间结束时间
  sceneId?: number // 非必须 应用场景ID
  customerCategory?: customerCategoryTypeEnum // 客户类别(EU/ISV)
  competitor?: competitorTypeEunm // 友商竞争
  competitorMigrateProductIds?: string[] // 友商迁移产品
  uinIncomeIndustryIds?: number[] // uin收入行业ID
  customerProvinceCode?: string // 省份code
}

// 获取销售机会列表 返回对象
export interface SaleOpportunityItem {
  opportunityId: number // 否 int 机会id
  name: string// "test5", // 机会名称
  customerId: number
  cid: string// "1c8dfe16688f2cf5f68872d2965ee697",
  customerName: string// "客户名称",
  belongModule: string// 7,
  uin: string// "432423",
  type: OpportunityTypeEnum// 2,
  typeCHName: string// "复购机会",
  status: OpportunityStatusEnum// 1,
  statusCHName: string// "进行中",
  money: number// "3000",
  isNew: OpportunityIsNewEnum// 1,
  isNewCHName: string// "是",
  // focusProducts: string// "1;2",
  winRate: OpportunityWinRateEnum// "0.25",
  estimatedOrderTime: string// "2020-01-01 19:00:00",
  creator: string// "cloudway",
  createTime: string// "2021-08-06 10:12:54",
  lastModifier: string// "cloudway",
  updateTime: string// "2021-08-06 10:12:54",
  contactPerson: string// "clou22",
  comment: string// "",
  desc: string// "describe",
  focusProductsObj: Pick<FocusProduct, 'productId' | 'productName'>[]
  focusFourthProductsObj: Pick<FocusProduct, 'productId' | 'productName'>[]
  customerFollower: string // 当前跟进人
  groups: IOrgGroup[] // 所属业务组

  estimateCustomerType: EstimateCustomerTypeEnum // 必须 客户预估类型，1企业、2个人、3伪个人
  sceneId: number // 必须 应用场景ID
  sceneDesc: string // 必须 应用场景描述
  customerStatus: CustomerStatusEnum // 必须
  customerPredictFollowTime: string // 必须 预计下次跟进时间
  customerCategory: string // 客户类别(EU/ISV)
  competitor: string // 友商竞争
  competitorMigrateProducts: Pick<FocusProduct, 'productId' | 'productName'>[] // 友商迁移产品
  customerProvinceCode: string // 省份code
}

export interface SaleOpportunityByCidFilter extends ISortOrder {
  sort?: 'createTime' | 'estimatedOrderTime' | 'updateTime'
  cid: string
}

export interface AddOpportunityCommon {
  name: string // 是 string 机会名称
  cid: string // 是 array|string 客户cid
  uin?: string // 否 array|string 客户uin
  type: OpportunityTypeEnum // 是 int 机会类型:1新购机会2复购机会3SMB合作机会4其他
  status: OpportunityStatusEnum // 是 int 机会状态:1进行中2已赢单3已关闭
  money?: number // 否 int 机会金额，开始范围
  contactPerson?: string // 否 string 联系人
  isNew: OpportunityIsNewEnum // 是 int 是否新客:0非新客1新客
  winRate: OpportunityWinRateEnum // 是 string 赢单率
  estimatedOrderTime: string // 是 string 预计下单时间（格式为2020-04-17 16:46:45）
  estimateCustomerType: EstimateCustomerTypeEnum // 必须 客户预估类型，1企业、2个人、3伪个人
  sceneId: number // 必须 应用场景ID
  desc: string // 是 string 机会描述
  comment?: string // 是 string 评论
  customerCategory?: number // 客户类别(EU/ISV)，0未知，1EU，2ISV
  competitor?: number // 友商竞争，0其他，1阿里，2华为
}

// 新增销售机会 上送
export interface AddOpportunityFilter extends AddOpportunityCommon {
  focusProductIds?: number[] // 否 array 三级关注产品
  focusFourthProductIds?: { productId: number[] | string[] } // 否 array 四级关注产品
  competitorMigrateProductIds?: number[] // 友商可迁移产品
}

export interface AddOpportunityParams extends AddOpportunityCommon {
  focusProductIds?: { productId: number[] } // 否 array 三级关注产品
  focusFourthProductIds?: { productId: number[] | string[] } // 否 array 四级关注产品
  competitorMigrateProductIds?: { productId: number[] } // 友商可迁移产品
}

// 编辑销售机会 上送
export type UpdateOpportunityFilter = { opportunityId: number } & Partial<AddOpportunityFilter>
