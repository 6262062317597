import React, { useState } from 'react'
import { Button } from 'antd'

const ErrorDescription = ({ detail, onToggle }: { detail: string; onToggle: (show: boolean) => void }) => {
  const [showDetail, setShowDetail] = useState(false)

  const handleToggle = () => {
    const newState = !showDetail
    setShowDetail(newState)
    onToggle(newState)
  }

  return (
    <div>
      <Button onClick={handleToggle}>{showDetail ? '收起' : '展开'}详情</Button>
      <div>
        {showDetail ? detail : ''}
      </div>
    </div>
  )
}

export default ErrorDescription
