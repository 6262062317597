/**
 * 回流临时池
 * @author tylerzzheng
 */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Alert, Button, Card, message, Modal, Select, Table, Tooltip } from 'antd'
import { AuthChecker, CommonFilters, PageHeader } from '@components'
import { BackflowStatus, CustomerFlowItem, FlowFilter, ImportCustomerBackflowItem, sortTypeEnum } from '@types'
import { ColumnProps, TableProps } from 'antd/lib/table'
import { getSortOrder, getTableXwidth } from '@configs/table'
import { transTempFilterItems } from '../../../components/CommonFilters/configs'
import { getCustomerBackflowList, importCustomerBackflowList } from '@services/customer'
import { antdSortMap, customerTypeMap } from '@configs/map'
import { Link } from 'react-router-dom'
import { useTableService } from '@hooks/useTableService'
import classNames from 'classnames'
import { trimStr } from '@library'
import { get } from 'lodash'

type ImportUinResult = ImportCustomerBackflowItem & {
  type: 'failed' | 'successful'
}

const initFilter: FlowFilter = {
  page: 1,
  pageSize: 10,
  status: BackflowStatus.STAND_BY,
  sort: 'createTime',
  sortType: sortTypeEnum.DESC,
}

const CustomerTransTempPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getCustomerBackflowList, initFilter, '获取回流临时池列表失败')

  const onTableChange: TableProps<CustomerFlowItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<FlowFilter>(sorter)
    setFilter(sort)
  }

  const columns: ColumnProps<CustomerFlowItem>[] = useMemo(() => [
    {
      title: '进入时间',
      dataIndex: 'createTime',
      width: 120,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 120,
      render: (customerName, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(customerName, '-')}
        </Link>),
    },
    { title: 'CID', dataIndex: 'cid', width: 180 },
    { title: '客户类型', dataIndex: 'customerType', width: 120, render: customerType => customerTypeMap[customerType] || '' },
    { title: '备注', dataIndex: 'remark', width: 180 },
    {
      title: (<>批次ID<Tooltip title="回流成功后，可以在线索页面按照批次ID筛选"><QuestionCircleOutlined /></Tooltip></>),
      dataIndex: 'batchId',
      width: 80,
    },
    { title: '批次名称', dataIndex: 'batchName', width: 180 },
  ], [filter.sort, filter.sortType])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  /* 导入功能 */
  const [open, setOpen] = useState(false)
  const [submitImportLoading, setSubmitImportLoading] = useState(false)
  const [importUins, setImportUins] = useState<string[]>([])
  const importUinsLength = useMemo(() => get(importUins, 'length', 0), [importUins])
  const disableImportUin = useMemo(() => importUinsLength <= 0 || importUinsLength > 50, [importUinsLength])
  const [showImportUinResult, setShowImportUinResult] = useState(false)
  const [importUinResults, setImportUinResults] = useState<ImportUinResult[]>([])
  const [importUinErr, setImportErr] = useState<Error>(undefined)

  const submitImportUins = useCallback(async () => {
    if (disableImportUin) return

    setSubmitImportLoading(true)
    setShowImportUinResult(false)
    const [importRes, importErr] = await importCustomerBackflowList({ uins: importUins })
    setSubmitImportLoading(false)
    if (importErr) {
      message.error(`UIN导入失败${importErr.message}`)
      return
    }
    const failedUins = Array.from(importRes?.failList || [])
    const successfulUins = Array.from(importRes?.successList || [])
    if (successfulUins.length <= 0) {
      setImportErr(new Error('全部UIN导入失败！'))
    } else if (failedUins.length > 0) {
      setImportErr(new Error('部分UIN导入失败！'))
    } else {
      setImportErr(undefined)
    }
    const failedResults = failedUins.map<ImportUinResult>(r => ({ ...r, type: 'failed' }))
    const successfulResults = successfulUins.map<ImportUinResult>(r => ({ ...r, type: 'successful', msg: r.msg || '导入成功' }))
    setImportUinResults([].concat(failedResults, successfulResults))
    setShowImportUinResult(true)

    // 去掉输入框成功导入的uin
    setImportUins(failedUins.map(f => f.uin))
  }, [importUins, disableImportUin])

  // 每次打开和关闭窗口的时候，置空输入数据
  useEffect(() => {
    setImportUins([])
    setImportErr(undefined)
    setShowImportUinResult(false)
    setImportUinResults([])
  }, [open])

  const FailedUinColumns: ColumnProps<ImportUinResult>[] = useMemo(() => [
    { title: '序号', dataIndex: 'index', width: 80 },
    { title: 'UIN', dataIndex: 'uin', width: 120 },
    {
      title: '状态',
      dataIndex: 'type',
      width: 120,
      render: (type, result) => {
        if (result.type === 'successful') {
          return <><CheckCircleOutlined className="text-success mr5" />导入成功</>
        } else {
          return <><CloseCircleOutlined className="text-danger mr5" />导入失败</>
        }
      },
    },
    { title: '原因', dataIndex: 'msg', width: 120 },
  ], [])

  /* 执行失败的回流 */
  const {
    loading: FlowErrLoading,
    list: FlowErrList,
    pagination: FlowErrPagination,
    rowKey: FlowErrKey,
  } = useTableService(getCustomerBackflowList, { ...initFilter, status: BackflowStatus.ERROR }, '获取执行回流失败列表失败')

  const [FlowErrOpen, setFlowErrOpen] = useState(false)
  const FlowErrColumns: ColumnProps<CustomerFlowItem>[] = useMemo(() => [
    { title: '进入时间', dataIndex: 'createTime' },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      render: (customerName, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(customerName, '-')}
        </Link>),
    },
    { title: 'CID', dataIndex: 'cid' },
    { title: '客户类型', dataIndex: 'customerType', render: customerType => customerTypeMap[customerType] || '' },
    { title: '备注', dataIndex: 'remark' },
    {
      title: '失败原因',
      dataIndex: 'failReason',
      render: failReason => <><CloseCircleOutlined className="text-danger mr5" />{failReason}</>,
    },
  ], [filter.sort, filter.sortType])
  // const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])
  return (
    <>
      <PageHeader title="回流临时池" />
      <Alert
        banner
        className="mt20"
        message={
          <span>
            转出客户进入临时池后每隔5分钟执行回流，回流成功后可以正常跟进客户。
            详细说明见<Button type="link" icon={<LinkOutlined />} target="_blank" href="https://iwiki.woa.com/pages/viewpage.action?pageId=814090236">转出客户回流说明</Button>
          </span>
        }
        type="info"
        closable
        showIcon={false}
      />
      <Card className="mt20">
        <CommonFilters
          className="custom-Label"
          itemList={transTempFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        {FlowErrList.length > 0 && (
          <Alert
            banner
            message={<span>当前有{FlowErrList.length}个客户回流失败。<Button type="link" onClick={() => setFlowErrOpen(true)}>点击查看详情</Button></span>}
            type="error"
          />
        )}
        <AuthChecker points="importReflux">
          <Button className="mt20" onClick={() => setOpen(true)}>导入</Button>
        </AuthChecker>
        <Table
          className="mt20"
          loading={loading}
          columns={columns}
          rowKey={rowKey}
          dataSource={list}
          pagination={pagination}
          onChange={onTableChange}
          scroll={{ x: tableXWidth }}
        />
      </Card>
      <Modal
        maskClosable={false}
        open={open}
        width={650}
        title="批量导入到临时池"
        onCancel={() => setOpen(false)}
        onOk={submitImportUins}
        confirmLoading={submitImportLoading}
        okButtonProps={{ disabled: disableImportUin }}
        okText="提交"
      >
        <Alert
          message={<span>一次最多导入50条UIN，已输入<span className={classNames({ 'text-danger': disableImportUin })}>{importUinsLength}条</span>UIN</span>}
          type="info"
        />
        <Select
          className="mt20"
          style={{ width: '100%' }}
          value={importUins}
          onChange={setImportUins}
          allowClear
          mode="tags"
          placeholder="请输入或直接粘贴多条UIN数据，会以空格、换行或逗号自动分割"
          tokenSeparators={[',', '，', ' ', '\t', '\n', '\r']}
        />
        {showImportUinResult && (
          <>
            <p className={classNames('mt20', { 'text-danger': importUinErr, 'text-success': !importUinErr })}>{importUinErr?.message || '全部导入成功！'}</p>
            <Table
              size="small"
              className="mt20"
              columns={FailedUinColumns}
              dataSource={importUinResults}
            />
          </>
        )}
      </Modal>
      <Modal
        open={FlowErrOpen}
        width="65%"
        title="执行回流失败列表"
        onCancel={() => setFlowErrOpen(false)}
        onOk={() => setFlowErrOpen(false)}
      >
        <Table
          loading={FlowErrLoading}
          columns={FlowErrColumns}
          rowKey={FlowErrKey}
          dataSource={FlowErrList}
          pagination={FlowErrPagination}
        />
      </Modal>
    </>)
}
export default CustomerTransTempPage
