import React, { useState, useEffect, useMemo, createContext } from 'react'
import { PageHeader } from '@components'
import { Card, Tabs, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import KeyWord from './KeyWord'
import QualityInspectionOption from './QualityInspectionOption'
import { getUserAuthPoint } from '@services/session'
import AUTH_POINT_MAP from '@configs/auth-point'
import './style.less'
import { get } from 'lodash'

const {
  menuTaskCallEvaluateR,
  menuTaskCallEvaluateU,
  menuTaskCallKeyWordsR,
} = AUTH_POINT_MAP

export const AuthPoint = createContext({})

const defTabsOptions = [
  {
    label: '质检方案管理',
    key: 'qualityInspectionOption',
    children: <QualityInspectionOption />,
  },
  {
    label: '关键词管理',
    key: 'keyWord',
    children: <KeyWord />,
  },
]

const QualityInspectionList = () => {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState('qualityInspectionOption')
  const [authPoint, setAuthPoint] = useState(null)
  const authPointList = useMemo(() => {
    const list = [
      menuTaskCallEvaluateR,
      menuTaskCallEvaluateU,
      menuTaskCallKeyWordsR,
    ]
    return list.map(item => {
      const [object, action] = item?.split?.('-') ?? []
      return { object, action }
    })
  }, [])
  const tabsOptions = useMemo(() => {
    if (Object.values(authPoint ?? {}).every(Boolean)) {
      return defTabsOptions
    }
    const arr = []
    if (authPoint?.[menuTaskCallEvaluateR] || authPoint?.[menuTaskCallEvaluateU]) {
      arr.push(defTabsOptions[0])
    }
    if (authPoint?.[menuTaskCallKeyWordsR]) {
      arr.push(defTabsOptions[1])
    }
    return arr
  }, [authPoint])
  const handleTabsOnChange = (key: string) => {
    setActiveKey(key)
  }

  /**
   * 获取权限点
   */
  const getAuthPoint = async () => {
    const [res, err] = await getUserAuthPoint(authPointList)
    const obj = {}
    if (err) {
      setAuthPoint(obj)
      return
    }
    const pointList = get(res, 'authPoints', [])
    pointList.forEach(item => {
      const key = `${item.object}-${item.action}`
      obj[key] = item.result
    })
    setActiveKey(obj[menuTaskCallEvaluateR] || obj[menuTaskCallEvaluateU] ? 'qualityInspectionOption' : 'keyWord')
    setAuthPoint(obj)
  }

  useEffect(() => {
    getAuthPoint()
  }, [])

  return (
    <div className="quality-inspection-list-page">
      <PageHeader title="质检方案" />
      <AuthPoint.Provider value={authPoint}>
        <Card className="quality-inspection-list-page-card">
          <Tabs
            activeKey={activeKey}
            onChange={handleTabsOnChange}
            items={tabsOptions}
            tabBarExtraContent={{
              right: (activeKey === 'qualityInspectionOption' && authPoint?.[menuTaskCallEvaluateU]) && <Button type="primary" onClick={() => history.push('/task/quality-inspection-detail')}>新增</Button>,
            }}
          />
        </Card>
      </AuthPoint.Provider>
    </div>
  )
}

export default QualityInspectionList
