import React from 'react'
import KeyWordItem from './KeyWordItem'
import { KeyWordTypeEnum } from '@types'
import { useKeyWordsList } from '@hooks'

const subComponentOptions = [
  { key: 'customerService', type: KeyWordTypeEnum.customerService },
  { key: 'client', type: KeyWordTypeEnum.client },
]

const KeyWord: React.FC = () => {
  const { keyWordsList, getKeyWordList, maxKeyWordCnt } = useKeyWordsList()

  return (
    <div className="key-word-page">
      {subComponentOptions.map(({ key, type }) => (
        <KeyWordItem
          key={key}
          type={type}
          onUpdate={() => getKeyWordList()}
          keyWordList={keyWordsList[type]}
          maxKeyWordCnt={maxKeyWordCnt}
        />
      ))}
    </div>
  )
}

export default KeyWord
