/**
 * 跟进记录
 * @author wzwwu
 */
import React, { useCallback, useMemo } from 'react'
import { CommonFilters, PageHeader, ReplayRecord, ShowSMS, TableDownload, UserAvatar } from '@components'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Card, Descriptions, message, Popover, Table, Tag, Tooltip } from 'antd'
import { exportHistoryFollowList, getHistoryFollowList } from '@services/customer'
import { ExtendItem, HistoryFollowListFilter, HistoryFollowListItem, needTotalEnum } from '@types'
import { TableColumnProps, useService, useShowError, useTableColumn } from '@hooks'
import { Link } from 'react-router-dom'
import { formatTime, parseExtend, parseFollowExtend, trimStr } from '@library'
import { getTouchStatus } from '@services/lead'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { cosGetObjectUrl } from '../../../library/cosUtils'
import downloadByATag from '../../../library/downloadByATag'
import { followRecordFilterItems } from '../../../components/CommonFilters/configs'
import moment from 'moment'
import { useTableService } from '@hooks/useTableService'
import { antdSortMap } from '@configs/map'
import { TableProps } from 'antd/lib/table'
import { clone, isArray } from 'lodash'

type HistoryFollowData = HistoryFollowListItem & {
  extendObj?: {[key: string]: any},
  extendArr?: ExtendItem[],
  touchStatus?: string
  intention?: string
  followType?: string
  competingExpirationProductsObj?: { productId: string, productName: string }[]
}

const initFilterValue = { lastFollowTime: [moment().subtract(1, 'month'), moment()] }
const initFilter: HistoryFollowListFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.LIST,
  // sort: 'createTime',
  lastFollowBeginTime: formatTime(initFilterValue.lastFollowTime[0], 'start'),
  lastFollowEndTime: formatTime(initFilterValue.lastFollowTime[1], 'end'),
  // sortType: sortTypeEnum.DESC,
}

export default function FollowRecordPage () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getHistoryFollowList, initFilter, '获取跟进记录失败')

  const [touchLoading, touchRes, touchErr] = useService(getTouchStatus)
  useShowError('获取触达状态列表失败', touchErr)

  const historyFollowList: HistoryFollowData[] = useMemo(() => list.map(item => {
    const historyFollowData: any = clone(item)
    historyFollowData.extendObj = parseFollowExtend(item?.extend)
    historyFollowData.extendArr = parseExtend(item?.extend)
    historyFollowData.intention = `${item.intentionName}-${item.subIntentionName}`
    historyFollowData.touchStatus = touchRes?.list?.find?.(t => +t.key === +item.touchStatus)?.value
    historyFollowData.followType = item.followType.join?.(',')
    return historyFollowData
  }), [list, touchRes])

  const onTableChange: TableProps<HistoryFollowListItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<HistoryFollowListFilter>(sorter)
    setFilter(sort)
  }

  const downloadFile = useCallback(async (key: string) => {
    const [url, err] = await cosGetObjectUrl(key)
    if (err) {
      message.error(`下载失败: ${err.message}`)
    } else {
      downloadByATag(key, url)
    }
  }, [])

  const columns: TableColumnProps<HistoryFollowData>[] = useMemo(() => [
    {
      title: '跟进时间',
      dataIndex: 'createTime',
      width: 180, // 注意和时间相关都建议180
      fixed: true,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: 'cid', dataIndex: 'cid', width: 120 },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 180,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '跟进方式', dataIndex: 'followType', width: 120 },
    { title: '客户意向', dataIndex: 'intention', width: 200 },
    { title: '触达状态', dataIndex: 'touchStatus', width: 120 },
    {
      title: '附件',
      dataIndex: 'fileUpload',
      width: 200,
      render: (fileUpload, record) => {
        const keys = record.extendObj?.fileUpload as unknown as string[]
        if (Array.isArray(keys)) {
          return keys?.map((key, index) => <a key={key} onClick={() => downloadFile(key)}>附件{index + 1};</a>)
        } else {
          return '-'
        }
      },
    },
    { title: '是否有效沟通', dataIndex: 'communicateResult', width: 120 },
    {
      title: '跟进内容',
      dataIndex: 'remark',
      ellipsis: true,
      width: 120,
      render: remark => <Tooltip placement="topLeft" title={remark}>{remark}</Tooltip>,
    },
    { title: '历史跟进人', dataIndex: 'creator', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '任务ID', dataIndex: 'taskIds', width: 120 },
    { title: '针对线索ID', dataIndex: 'leadIds', width: 120 },
    {
      title: (<>下次跟进时间<Tooltip title="代表当时跟进填写的下次跟进时间，最近一次跟进记录的下次跟进时间会更新到对应任务的下次跟进时间"><QuestionCircleOutlined /></Tooltip></>),
      _title: '下次跟进时间',
      dataIndex: 'nextFollowTime',
      width: 180,
    },
    {
      title: '竞对厂商',
      dataIndex: 'competingFirm',
      width: 120,
    },
    {
      title: '竞对产品到期时间',
      dataIndex: 'competingExpirationTime',
      width: 170,
    },
    {
      title: '竞对到期产品',
      dataIndex: 'competingExpirationProductsObj',
      width: 120,
      render: (extend) => extend?.map?.(product => <Tag key={product.productId}>{product.productName}</Tag>) || '-',
    },
    {
      title: '详情',
      dataIndex: 'extend',
      width: 180,
      render: (extend, record) => (
        <Popover
          content={
            <Descriptions column={2} size="small" style={{ width: '600px' }}>
              {isArray(record.extendArr) && record.extendArr?.map(item => {
                if (item.key === 'competingExpirationProducts') {
                  return <Descriptions.Item key={item.key} label={item.keyName}>{record?.competingExpirationProductsObj?.length ? record?.competingExpirationProductsObj?.map(product => product.productName).join('、') : '-'}</Descriptions.Item>
                }
                return <Descriptions.Item key={item.key} label={item.keyName}>{item.keyValue === 'undefined' ? '-' : item.keyValue}</Descriptions.Item>
              })}
            </Descriptions>
          } title="详情"
        ><a style={{ cursor: 'default' }}>详情</a>
        </Popover>
      ),
    },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 180,
      render: (actions, record) => {
        return [].concat(
          (record?.extendObj?.phoneLogIds?.split(',') ?? []).map(id => <ReplayRecord className="mr5" logId={id} title={`与${record?.customerName}的通话录音`} key={id} />),
          (record?.extendObj?.msgLogIds?.split(',') ?? []).map(id => <ShowSMS logId={id} className="mr5" key={id} />),
        )
      },
    },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.FOLLOW_RECORD, columns, ['customerName', 'actions'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  return (
    <>
      <PageHeader title="跟进记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          initValue={initFilterValue}
          itemList={followRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1, followType: filter?.followType && [filter?.followType] })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.FOLLOW_RECORD} onSuccess={() => forceRequest()} />
        <TableDownload className="ml20" exportMethod={exportHistoryFollowList} filter={filter} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={touchLoading || loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={historyFollowList}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
