/**
 * 一些偏向于解决业务上的utils
 * @author tylerzzheng
 */
import { pattern } from '@configs/pattern'
import { AsyncTotal, CommonList, needTotalEnum } from '@types'
import { handleNumber, isArray, isEmptyWithCustom, isEmptyArray, isObject, isString } from './isTypeOf'
import { mapValues } from 'lodash'

/**
 * 去除string前后空格，以及其中的不可见字符
 * @param str 目标字符串
 * @param fallback 目标字符串为空时默认返回
 */
export const trimStr = (str: string, fallback: string = ''): string => {
  return ''.concat(str).trim().replace(pattern.NON_PRINTABLE_UNICODE, '') || fallback
}

/**
 * 后端返回的数组有有可能是CommonList类型，也可能直接是数组。这里统一做一个解析
 * @param res 后端返回的数据res
 * @param defaultList 包含list和total的数组，list默认值可由defaultList指定，total为list的长度
 */
export function getResList<T> (res: CommonList<T> | T[], defaultList?: T[]) {
  let list: T[] = defaultList
  let total = defaultList?.length
  if (isObject(res)) {
    list = (res as CommonList<T>).list || defaultList
    total = (res as CommonList<T>).total || defaultList?.length
  }
  if (isArray(res)) {
    list = (res as T[]) || defaultList
    total = (res as T[]).length
  }

  return [list, total] as const
}

/**
 * 根据参数计算出是否 仅获取分页接口的total字段
 * @param interfaceName 后端接口名，返回格式需符合CommonList的结构
 * @param filter 继承AsyncTotal， 根据needTotal字段判断是否仅获取total字段
 */
export const getInterfaceName = <F extends AsyncTotal>(interfaceName: string, filter: F) => {
  let name = interfaceName
  if (filter?.needTotal === needTotalEnum.TOTAL) {
    name += 'Total' // 在分页接口名称后加Total，代表仅获取其total字段
  }
  return name
}

/**
 * 系统展示用户名的方式为：LanCheng(兰铖)，避免展示成LanCheng(undefined)的情形
 * @param salesName 座席英文名
 * @param salesNameCh 座席中文名
 */
export const getStaffName = (salesName: string, salesNameCh: string): string => {
  const ch = isEmptyWithCustom(salesNameCh) ? '' : `(${salesNameCh})`
  return salesName + ch
}

/**
 * 在上送表单数据之前先用此方式筛选一遍
 *
 * 踩坑如下：
 * 1. 字段上传字符串，后端会报错， 因此空字符串解析成undefined
 * 2. 空数组同上
 * 3. 对string类型trim处理
 *
 * @param values 待处理的上送参数
 * @param expectNumber 某些字段如果希望值是number类型，可以通过expectNumber指定字段的集合
 */
export const parseParams = <T extends object>(values: T, expectNumber: string[] = []): T => {
  if (!isObject(values)) return undefined
  return mapValues(values, (val, key) => {
    // @ts-ignore
    if (expectNumber.includes(key)) return handleNumber(val)
    // @ts-ignore
    if (val === '') return undefined
    if (isString(val)) return trimStr(val)
    if (isEmptyArray(val)) return undefined
    return val
  }) as T
}

export function copyToClipboard (text: string) {
  // 创建一个临时的 textarea 元素
  const textarea = document.createElement('textarea')
  textarea.value = text
  // 防止滚动到页面底部
  textarea.style.position = 'fixed'
  document.body.appendChild(textarea)

  // 选中并复制文本
  textarea.select()
  document.execCommand('copy')

  // 移除临时元素
  document.body.removeChild(textarea)
}
