import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Tooltip, Typography, Tag, Button, message, Popconfirm } from 'antd'
import { TagOutlined } from '@ant-design/icons'
import { v4 as uuid } from 'uuid'
import type { ColumnsType } from 'antd/es/table'
import { GetQualityCheckPlanListAPI, DeleteQualityCheckPlanAPI } from '@services/quality-inspection'
import { QualityCheckPlanItemType, DimensionsItemType, DimensionsItemsItemType } from '@types'
import { AuthPoint } from './index'
import AUTH_POINT_MAP from '@configs/auth-point'
import { get } from 'lodash'

const {
  menuTaskCallEvaluateR,
  menuTaskCallEvaluateU,
} = AUTH_POINT_MAP

const QualityInspectionOption: React.FC = () => {
  const history = useHistory()
  const authPoint = useContext(AuthPoint)
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pageSize: 10,
  })
  const [dataSource, setDataSource] = useState<QualityCheckPlanItemType[]>([])
  const columns: ColumnsType<QualityCheckPlanItemType> = [
    {
      title: '方案名称',
      dataIndex: 'name',
      key: 'name',
      width: '35%',
      ellipsis: {
        showTitle: false,
      },
      render: name => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: '合格分数标准',
      dataIndex: 'qualifiedScore',
      key: 'qualifiedScore',
      width: '15%',
    },
    {
      title: '评分项',
      key: 'dimensions',
      dataIndex: 'dimensions',
      width: '60%',
      render: (dimensions, record) => (
        <div className="mark-container">
          <div className="tag-container">
            {dimensions.map(({ items }) => (
              items.map((item: DimensionsItemsItemType) => {
                const { name, score, subId } = item
                return (
                  <Tag className="key-word-tag" icon={<TagOutlined rotate={-90} />} color="#e5e5e5" key={subId}>
                    <Typography.Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: `${name}：${score} 分` }}>{name}</Typography.Text>
                    {`：${score} 分`}
                  </Tag>
                )
              })
            ))}
          </div>
          <div className="button-container">
            {(authPoint?.[menuTaskCallEvaluateR] || authPoint?.[menuTaskCallEvaluateU]) && <Button type="link" onClick={() => handleTableRecordOnClick(record, 'view')}>查看</Button>}
            {authPoint?.[menuTaskCallEvaluateU] && (
              <>
                <Button type="link" onClick={() => handleTableRecordOnClick(record, 'copy')}>复制</Button>
                <Popconfirm title="确定删除该质检方案？" okText="确定" cancelText="取消" onConfirm={() => handleTableRecordOnClick(record, 'delete')}>
                  <Button type="link">删除</Button>
                </Popconfirm>
              </>
            )}
          </div>
        </div>
      ),
    },
  ]

  /**
   * 获取质检方案列表
   */
  const getQualityCheckPlanList = async (page: number = 1, pageSize: number = 10) => {
    const [res, err] = await GetQualityCheckPlanListAPI({ page, pageSize })
    const total = get(res, 'total', 0)
    setPagination({ page, pageSize, total })
    if (err) {
      message.error(err.message)
    }
    const list = get(res, 'list', [])
    list.forEach(({ dimensions }) => {
      dimensions.forEach((item: DimensionsItemType) => {
        item.id = uuid()
        item.items.forEach((i: DimensionsItemsItemType) => {
          i.subId = uuid()
        })
      })
    })
    setDataSource(list)
  }

  useEffect(() => {
    getQualityCheckPlanList()
  }, [])

  /**
   * 表格操作列点击事件
   * @param record 表格行数据
   * @param type 操作类型
   */
  const handleTableRecordOnClick = async (record: QualityCheckPlanItemType, type: string) => {
    if (type === 'delete') {
      const [res, err] = await DeleteQualityCheckPlanAPI(record.id)
      if (res) {
        message.success('删除成功')
        getQualityCheckPlanList()
      } else {
        message.error(err.message)
      }
      return
    }
    // 查看/复制
    history.push(`/task/quality-inspection-detail?id=${record.id}&type=${type}`)
  }

  /**
   * 分页器change事件回调
   * @param page 页数
   * @param pageSize 每页条数
   */
  const handlePaginationOnChange = (page: number, pageSize: number) => {
    getQualityCheckPlanList(page, pageSize)
  }

  return (
    <div className="quality-inspection-option-page">
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{ ...pagination, onChange: handlePaginationOnChange }}
      />
    </div>
  )
}

export default QualityInspectionOption
