/**
 * 机器人通话记录页面
 */
import React, { useMemo, useCallback } from 'react'
import { Card, Table, message, TableProps } from 'antd'
import { Link } from 'react-router-dom'
import { getTableXwidth, TableIdEnum, getSortOrder } from '@configs/table'
import { CommonFilters, PageHeader, UserAvatar, TableDownload } from '@components'
import { FieldItem } from '../../../components/CommonFilters/configs'
import { trimStr, formatTime, copyToClipboard, getStaffName, parseDuration } from '@library'
import { useTableService } from '@hooks/useTableService'
import { MeetingRecordItem, needTotalEnum, sortTypeEnum, MeetingRecordFilter } from '@types'
import { TableColumnProps, useTableColumn } from '@hooks'
import { getMeetingRecordList, exportMeetingRecordList, getMeetingSharingUrl } from '@services/customer'
import { LinkOutlined } from '@ant-design/icons'
import { ConfigTableHeader } from 'src/components/ConfigTableHeader'
import { antdSortMap } from '@configs/map'
import { clone } from 'lodash'
const handleCopyMeetingUrl = async (record: MeetingRecordItem) => {
  const [res, err] = await getMeetingSharingUrl({ recordId: record.recordId, meetingId: record.meetingId })
  if (err) {
    message.error(`复制会议详情失败:(${err.message})`)
    return
  }
  copyToClipboard(res.sharingURL)
  message.success('复制成功')
}
const meetingRecordFilterItems: FieldItem[] = [

  { fieldName: 'salesName', label: '座席姓名', type: 'staffSelect' },
  { fieldName: 'groupId', label: '服务组', type: 'groupId' },
  {
    fieldName: 'createTime',
    label: '会议时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  {
    fieldName: 'duration',
    label: '会议时长(秒)',
    type: 'inputNumberRange',
    handleValue: val => ({
      durationFrom: val?.[0],
      durationTo: val?.[1],
    }),
  },
]

// 通话时长为-1时表示话单还在生成中
const GENERATING_CALLDATA = -1
const handleMeetingRecord = (list: any[]) => list?.map(item => {
  const callRecordData: any = clone(item)
  const isGenerating = item?.duration === GENERATING_CALLDATA

  // 通话时间为-1时，通话时长 展示为空
  callRecordData.durationText = isGenerating ? '' : parseDuration(item?.duration, 's')
  return callRecordData
})
const MeetingRecordPage = () => {
  const initFilter = {
    page: 1,
    pageSize: 10,
    needTotal: needTotalEnum.ALL,
    sort: 'createTime' as 'createTime',
    sortType: sortTypeEnum.DESC,
  }

  const {
    loading,
    list,
    pagination,
    setFilter,
    filter,
    resetFilter,
    rowKey,
  } = useTableService(getMeetingRecordList, initFilter, '获取腾讯会议记录列表失败')
  const meetingRecordList = useMemo<MeetingRecordItem[]>(() => handleMeetingRecord(list), [list])
  const handleJumpToMeetingDetail = useCallback((record: MeetingRecordItem) => async () => {
    const [res, err] = await getMeetingSharingUrl({ recordId: record.recordId, meetingId: record.meetingId })
    if (err) {
      message.error(`跳转会议详情失败:(${err.message})`)
      return
    }
    window.open(res.sharingURL, '_blank')
  }, [])

  const columns: TableColumnProps<MeetingRecordItem>[] = useMemo(() => [
    {
      title: '会议时间',
      dataIndex: 'createTime',
      width: 180,
      fixed: true,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '会议名',
      dataIndex: 'meetingTitle',
      width: 280,
      render: (name, record) => (
        <a onClick={handleJumpToMeetingDetail(record)}>
          {getStaffName(record.salesName, record.salesNameCh)}{trimStr(name, '-')}
        </a>),
    },
    {
      title: '会议号',
      dataIndex: 'meetingCode',
      width: 180,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 280,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    {
      title: '会议时长',
      dataIndex: 'durationText',
      width: 180,
    },
    { title: '跟进人', dataIndex: 'salesName', width: 250, render: value => <UserAvatar salesName={value} /> },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 120,
      render: (formItem, record) => {
        return (
          <LinkOutlined onClick={() => handleCopyMeetingUrl(record)} />
        )
      },
    },
  ], [filter.sort, filter.sortType])
  const onTableChange: TableProps<MeetingRecordItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<MeetingRecordFilter>(sorter)
    setFilter(sort)
  }
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.MEETING_RECORD, columns, [])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columns])

  return (
    <>
      <section>
        <PageHeader title="腾讯会议记录" />
        <Card className="mt20">
          <CommonFilters
            className="custom-Label"
            itemList={meetingRecordFilterItems}
            onSubmit={filter => setFilter({ ...filter, page: 1 })}
            onReset={() => resetFilter(initFilter)}
          />
        </Card>
      </section>
      <Card className="mt20">
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.MEETING_RECORD} onSuccess={forceRequest} />
        <TableDownload className="ml20" exportMethod={exportMeetingRecordList} filter={filter} />
        <Table
          loading={loading}
          scroll={{ x: tableXWidth }}
          tableLayout="fixed"
          className="mt20"
          rowKey={rowKey}
          dataSource={meetingRecordList}
          columns={columnProps}
          pagination={pagination}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}

export default MeetingRecordPage
