import React, { useMemo, useCallback } from 'react'
import Card from 'antd/lib/card/Card'
import { CommonFilters, UserAvatar, TableDownload } from '@components'
import { getMeetingCallRecordList, getMeetingSharingUrl, exportMeetingCallRecordList } from '@services/customer'
import { Table, TableProps, message, Input } from 'antd'
import { MeetingCallRecordFilter, needTotalEnum, sortTypeEnum, MeetingCallRecordListItem } from '@types'
import { useTableService } from '@hooks/useTableService'
import { useTableColumn, TableColumnProps } from '@hooks'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { FieldItem } from 'src/components/CommonFilters/configs'
import { formatTime, trimStr, getStaffName, parseDuration } from '@library'
import { antdSortMap, MeetingCallEndReasonMap } from '@configs/map'
import { clone } from 'lodash'
import { Link } from 'react-router-dom'
import { ConfigTableHeader } from 'src/components/ConfigTableHeader'
const initFilter: MeetingCallRecordFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.ALL,
  sort: 'createTime',
  sortType: sortTypeEnum.DESC,
}

const meetingCallRecordFilterItems: FieldItem[] = [
  { fieldName: 'meetingCode', label: '关联会议号', type: 'multiTags' },
  { fieldName: 'callNumber', label: '主叫电话', type: 'input' },
  {
    fieldName: 'phone',
    label: '被叫电话',
    render: ({ value, onChange }) => <Input addonBefore="+86" value={value} onChange={e => onChange(e.target.value || undefined)} allowClear />,
    handleValue: val => ({ phone: val || undefined }),
  },
  { fieldName: 'salesName', label: '座席姓名', type: 'staffSelect' },
  { fieldName: 'groupId', label: '服务组', type: 'groupId' },
  {
    fieldName: 'createTime',
    label: '记录生成时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  {
    fieldName: 'duration',
    label: '通话时长(秒)',
    type: 'inputNumberRange',
    handleValue: val => ({
      durationFrom: val?.[0],
      durationTo: val?.[1],
    }),
  },
]

// 通话时长为-1时表示话单还在生成中
const GENERATING_CALLDATA = -1
const handleMeetingCallRecord = (list: any[]) => list?.map(item => {
  const callRecordData: any = clone(item)
  const isGenerating = item?.duration === GENERATING_CALLDATA

  // 通话时间为-1时，通话时长 展示为空
  callRecordData.durationText = isGenerating ? '' : parseDuration(item?.duration, 's')
  callRecordData.failReasonText = MeetingCallEndReasonMap[item?.failReason] || ''
  return callRecordData
})

// const download = async (record: MeetingCallRecordListItem) => {
//   const [url, err] = await getMeetingPstnDownloadUrl({ recordId: record.recordId, meetingId: record.meetingId })
//   if (err) {
//     message.error(`下载失败: ${err.message}`)
//   } else {
//     const tagName = `${getStaffName(record.salesName, record.salesNameCh)}${trimStr(name, '-')}`
//     downloadByATag(`${tagName}.xlsx`, url.downloadURL)
//   }
// }
export default function MeetingCallRecord () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getMeetingCallRecordList, initFilter, '获取会议记录失败')
  const handleJumpToMeetingDetail = useCallback((record: MeetingCallRecordListItem) => async () => {
    const [res, err] = await getMeetingSharingUrl({ recordId: record.recordId, meetingId: record.meetingId })
    if (err) {
      message.error(`跳转会议详情失败:(${err.message})`)
      return
    }
    window.open(res.sharingURL, '_blank')
  }, [])
  const meetingCallRecordList = useMemo<MeetingCallRecordListItem[]>(() => handleMeetingCallRecord(list), [list])
  const columns: TableColumnProps<MeetingCallRecordListItem>[] = useMemo(() => [
    {
      title: '记录生成时间',
      dataIndex: 'createTime',
      width: 180,
      fixed: true,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 280,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '主叫号码', dataIndex: 'callNumber', width: 180 },
    { title: '被叫号码', dataIndex: 'phone', width: 180 },
    { title: '通话结果', dataIndex: 'failReasonText', width: 180 },
    {
      title: '通话开始时间',
      dataIndex: 'callBeginTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callBeginTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '通话结束时间',
      dataIndex: 'callEndTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callEndTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '通话时长',
      dataIndex: 'durationText',
      width: 120,
    },
    { title: '跟进人', dataIndex: 'salesName', width: 240, render: value => <UserAvatar salesName={value} /> },
    {
      title: '关联会议名',
      dataIndex: 'meetingTitle',
      width: 280,
      render: (name, record) => (
        <a onClick={handleJumpToMeetingDetail(record)}>
          {getStaffName(record.salesName, record.salesNameCh)}{trimStr(name, '-')}
        </a>),
    },
    { title: '关联会议号', dataIndex: 'meetingCode', width: 120 },
    // {
    //   title: '操作',
    //   dataIndex: 'actions',
    //   width: '20%',
    //   render: (formItem, record) => {
    //     return (
    //       <Tooltip title={isToday(record.createTime) ? '通话详单生成中' : ''}>
    //         <Button disabled={isToday(record.createTime)} type="link" onClick={() => download(record)}>下载</Button>
    //       </Tooltip>
    //     )
    //   },
    // },

  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.MEETING_CALL_RECORD, columns, [])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])
  const onTableChange: TableProps<MeetingCallRecordListItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<MeetingCallRecordFilter>(sorter)
    setFilter(sort)
  }
  return (
    <>
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={meetingCallRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.MEETING_CALL_RECORD} onSuccess={forceRequest} />
        <TableDownload className="ml20" exportMethod={exportMeetingCallRecordList} filter={filter} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={meetingCallRecordList}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
