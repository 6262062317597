
import { isEmptyWithCustom, requestApi, requestApiV2 } from '@library'
import {
  AddOpportunityFilter,
  CommonList,
  FocusProduct, OpportunityScene,
  SaleOpportunityByCidFilter,
  SaleOpportunityFilter,
  SaleOpportunityItem, UpdateOpportunityFilter,
} from '@types'
import { get } from 'lodash'

// 获取销售机会列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002033489
export function getOpportunityList (filter: SaleOpportunityFilter) {
  return requestApiV2<CommonList<SaleOpportunityItem>>('/trpc.tcc.tcc_main.Opportunity/GetOpportunityList', filter)
}

// 获取销售机会列表 根据cid http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002035303
export function getOpportunityListByCid (filter: SaleOpportunityByCidFilter) {
  return requestApiV2<SaleOpportunityItem[]>('/trpc.tcc.tcc_main.Opportunity/GetOpportunityByCid', { ...filter, cid: [filter?.cid] })
}

// 和原方法相比， 会检查参数是否为空，为空直接返回
export function getOpportunityListByCidWithCheck (...params: Parameters<typeof getOpportunityListByCid>) {
  if (isEmptyWithCustom(get(params, '[0].cid'))) {
    return Promise.resolve([[], null]) as ReturnType<typeof getOpportunityListByCid>
  }
  return getOpportunityListByCid(...params)
}

// 获取销售机会的可关注产品列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002033621
// @deprecated 已废弃
export function getOpportunityProducts (filter: {}) {
  return requestApi<FocusProduct[]>('GetOpportunityProducts', filter)
}

// 获取销售机会应用场景 http://opc-mock.oa.com/project/530/interface/api/3032
export function getOpportunityScene (filter: {}) {
  return requestApiV2<OpportunityScene[]>('/trpc.tcc.tcc_main.Opportunity/GetOpportunityScene', filter)
}

// 新增销售机会 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002033587
export function addOpportunity (filter: AddOpportunityFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Opportunity/AddOpportunity', filter)
}

// 编辑销售机会 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002033593
export function updateOpportunity (filter: UpdateOpportunityFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Opportunity/UpdateOpportunity', filter)
}

// 下载销售机会列表数据
export function exportOpportunityList (params: SaleOpportunityFilter) {
  return requestApiV2<CommonList>('/trpc.tcc.tcc_main.Opportunity/ExportOpportunityList', params)
}
