/**
 * 商机列表
 * @author tylerzzheng
 */
import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Card, Table, Tag, Tooltip } from 'antd'
import { TableProps } from 'antd/lib/table'
import { AuthChecker, CommonFilters, PageHeader, TableDownload, UserAvatar } from '@components'
import { TableColumnProps, useTableColumn } from '@hooks'
import { CustomerStatusEnum, SaleOpportunityFilter, SaleOpportunityItem, sortTypeEnum } from '@types'
import {
  antdSortMap,
  customerStatusMap, estimateCustomerTypeMap,
  opportunityIsNewMap,
  opportunityStatusMap,
  opportunityWinRateMap,
  opportunityTypeMap,
} from '@configs/map'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { saleOpportunityItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
import { trimStr, getArea } from '@library'
import { exportOpportunityList, getOpportunityList } from '@services/sale-opportunity'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'

const initFilter: SaleOpportunityFilter = {
  customerStatus: CustomerStatusEnum.NORMAL,
  page: 1,
  pageSize: 10,
  sort: 'createTime',
  sortType: sortTypeEnum.DESC,
}

const SaleOpportunityPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getOpportunityList, initFilter, '获取商机列表失败')
  const initFilterValue = useMemo(() => ({ customerStatus: CustomerStatusEnum.NORMAL }), [])

  const onTableChange: TableProps<SaleOpportunityItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<SaleOpportunityFilter>(sorter)
    setFilter(sort)
  }

  const columns: TableColumnProps<SaleOpportunityItem>[] = useMemo(() => [
    { title: '商机ID', dataIndex: 'opportunityId' },
    { title: '商机名称', dataIndex: 'name', width: 120, ellipsis: true },
    { title: 'UIN', dataIndex: 'uin' },
    { title: 'CID', dataIndex: 'cid', width: 160 },
    { title: '客户预估类型', dataIndex: 'estimateCustomerType', render: estimateCustomerType => estimateCustomerTypeMap[estimateCustomerType] || '-' },
    { title: '应用场景', dataIndex: 'sceneDesc' },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 200,
      ellipsis: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '商机类型', dataIndex: 'typeCHName' },
    { title: '是否转出', dataIndex: 'customerStatus', render: customerStatus => customerStatusMap[customerStatus] || '-' },
    { title: '商机状态', dataIndex: 'status', render: status => opportunityStatusMap[status] || '-' },
    { title: '进行中阶段', dataIndex: 'stageInProgress', render: stageInProgress => opportunityTypeMap[stageInProgress] || '-' },
    {
      title: '商机金额(元)',
      dataIndex: 'money',
      sorter: true,
      sortOrder: filter.sort === 'money' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: 'EU/ISV', dataIndex: 'customerCategory' },
    { title: '友商竞争', dataIndex: 'competitor' },
    {
      title: '友商可迁移产品',
      dataIndex: 'competitorMigrateProducts',
      width: 200,
      render: (text, record) => record.competitorMigrateProducts?.map?.(product => <Tag key={product.productId}>{product.productName}</Tag>) || '-',
    },
    {
      title: 'UIN行业',
      dataIndex: 'uinIncomeIndustry',
      render: uinIncomeIndustry => uinIncomeIndustry
        ? `${uinIncomeIndustry?.first?.name ?? ' '} - ${uinIncomeIndustry?.second?.name ?? ' '}`
        : '-',
    },
    {
      title: '省份',
      dataIndex: 'customerProvinceCode',
      render: code => getArea(null, code),
    },
    { title: '联系人', dataIndex: 'contactPerson' },
    { title: '是否新客', dataIndex: 'isNew', render: isNew => opportunityIsNewMap[isNew] || '-' },
    {
      title: '关注产品',
      dataIndex: 'focusProductsObj',
      width: 200,
      render: (item, record) => (
        <>
          {record.focusProductsObj?.map?.(product => <Tag key={product.productId}>{product.productName}</Tag>) || '-'}
          {Boolean(record?.focusFourthProductsObj?.length) && record.focusFourthProductsObj?.map?.(product => <Tag key={product.productId}>{product.productName}</Tag>)}
        </>
      ),
    },
    { title: '赢单率', dataIndex: 'winRate', render: winRate => opportunityWinRateMap[winRate] || '-' },
    {
      title: '预计下单时间',
      dataIndex: 'estimatedOrderTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'estimatedOrderTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '预计订单时长(月)',
      dataIndex: 'estimateOrderDuration',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'estimateOrderDuration' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '说明', dataIndex: 'desc', ellipsis: true, render: desc => <Tooltip placement="topLeft" title={desc}>{desc}</Tooltip> },
    { title: '评论', dataIndex: 'comment', ellipsis: true, render: comment => <Tooltip placement="topLeft" title={comment}>{comment}</Tooltip> },
    { title: '当前跟进人', dataIndex: 'customerFollower', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '所属业务组',
      dataIndex: 'groups',
      width: 180,
      render: groups => groups?.map(item => item.groupName)?.join(';'),
    },
    { title: '创建人', dataIndex: 'creator', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '下次跟进时间',
      dataIndex: 'customerPredictFollowTime',
      width: 180,
    },
    { title: '更新人', dataIndex: 'lastModifier', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'updateTime' ? antdSortMap[filter.sortType] : undefined,
    },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.SALEOPPORTUNITY_PAGE, columns, ['customerName'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  return (
    <>
      <PageHeader title="商机列表" />
      <Card className="mt20">
        <CommonFilters
          standstill={7}
          initValue={initFilterValue}
          className="custom-Label"
          itemList={saleOpportunityItems}
          onSubmit={filter => {
            setFilter({
              ...filter,
              lastModifier: filter?.lastModifier && [filter?.lastModifier],
              creator: filter?.creator && [filter?.creator],
              page: 1,
            })
          }}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <ConfigTableHeader className="mr10" columnConfigs={columnConfigs} tableId={TableIdEnum.SALEOPPORTUNITY_PAGE} onSuccess={() => forceRequest()} />
        <AuthChecker points="exportOpportunity">
          <TableDownload exportMethod={exportOpportunityList} filter={filter} />
        </AuthChecker>
        <Table
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
export default SaleOpportunityPage
