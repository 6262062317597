/**
 * 路由配置
 * @author jasonelchen
 */
import { Key, ComponentType, lazy } from 'react'

// 页面
// import IndexPage from '@pages/index'
import LoginPage from '@pages/login'
import NotFoundPage from '@pages/not-found'
// import TaskListPage from '@pages/task/list'
import FollowRecordPage from '@pages/task/follow-record'
import QualityInspectionListPage from '@pages/task/quality-inspection-list'
import QualityInspectionDetailPage from '@pages/task/quality-inspection-detail'
import ScoreDetailPage from '@pages/task/score-detail'
import SMSRecordPage from '@pages/task/sms-record'
// import CustomerDetailPage from '@pages/customer/detail'
// import ConfigOrganizationPage from '@pages/config/organization'
// import LeadRulePage from '@pages/config/lead-rule'
// import ConfigTagsPage from '@pages/config/tags'
// import ConfigSmsPage from '@pages/config/sms'
// import FollowRulePage from '@pages/config/follow'
// import ReatinResourceTmpList from '@pages/config/add-retain-resource/AddReatinResource'
import LeadAddPage from '@pages/lead/add'
import LeadListPage from '@pages/lead/list'
import LeadHotlinePage from '@pages/lead/hotline/home'
import LeadHotlineClueCollect400Page from '@pages/lead/hotline/clue-collect-400'
import LeadHotlineClueCollectIMPage from '@pages/lead/hotline/clue-collect-im'
import LeadHotlineCallIMPage from '@pages/lead/hotline/call-im'
import LeadCirculateRecordPage from '@pages/lead/circulate-record'
import CustomerAlterPage from '@pages/customer/alter'
import CustomerResourcePage from '@pages/customer/resource'
import CustomerAssignRecordPage from '@pages/customer/assign-record'
import LinkLogPage from '@pages/customer/link-log'
import ScrmFlowLogPage from '@pages/customer/scrm-flow-log'
import OrderDetailPage from '@pages/customer/order-detail'
import CustomerTransTempPage from '@pages/customer/trans-temp'
import CallRecordPage from '@pages/task/call-record'
import ConfigRuleTypePage from '@pages/config/rule-type'
import ConfigTaskLogPage from '@pages/config/task-log'
import AllTaskPage from '@pages/task/all-task'
import RetainRecordPage from '@pages/task/retain-record'
import ConfigBatchInfoPage from '@pages/config/batch-info'
import ConfigOperationLogPage from '@pages/config/operation-log'
import AgentTask from '@pages/report/agent-task'
import CustomerUnbindPage from '@pages/customer/unbind'
import CustomerBindFailurePage from '@pages/customer/bind-failure'
import { moduleId } from '@configs/index'
import SaleOpportunityPage from '@pages/customer/opportunity'
import { AuthPointType } from '@configs/auth-point'
import GoalPage from '@pages/task/goal'
import CustomerPoolPage from '@pages/customer/customer-pool'
// import KanbanPage from '@pages/report/kanban'
import MyApplyPage from '@pages/config/my-apply'
import WorkspacePage from '@pages/task/Workspace'
import ConflictRecordPage from '@pages/customer/conflict'
import ConfigRobotTaskLogPage from '@pages/config/robot-task-log'
import RobotCallRecordPage from '@pages/task/robot-call-record'
import MeetingRecordPage from '@pages/task/meeting-record'
import CopBuy from '@pages/cop-buy'
import CallOutCutInPage from '@pages/config/call-out-cut-in'

// 通用路由配置
export interface RouteConfig {
  key: Key
  path?: string
  exact?: boolean
  needLogin?: boolean // 是否需要登录态
  authPoints?: AuthPointType[] // 权限点 或的关系，只要有一个满足就显示
  belongModule?: number[] // 仅在所列出的模块显示
  standalone?: boolean // 是否不需要侧边栏 默认false
  component?: ComponentType
}

const routes: RouteConfig[] = [
  {
    key: 'index',
    path: '/',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/index')),
  },
  {
    key: 'performance',
    path: '/performance',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/report/performance/index')),
    authPoints: ['menuTaskList'],
  },
  {
    key: 'kanban',
    path: '/kanban',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/report/kanban/index-salesName')),
    authPoints: ['menuReportKanban'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'kanban-admin',
    path: '/kanban-admin',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/report/kanban/index-group')),
    authPoints: ['menuReportKanbanAdmin'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'login',
    path: '/login',
    exact: true,
    standalone: true,
    needLogin: false,
    component: LoginPage,
  },
  // {
  //   key: 'task.list',
  //   path: '/task/list',
  //   exact: true,
  //   needLogin: true,
  //   component: TaskListPage,
  //   authPoints: ['menuTaskList'],
  // },
  {
    key: 'task.workspace',
    path: '/task/workspace',
    exact: true,
    needLogin: true,
    component: WorkspacePage,
    authPoints: ['menuTaskList'],
  },
  {
    key: 'task.all.task',
    path: '/task/all-task',
    exact: true,
    needLogin: true,
    component: AllTaskPage,
    authPoints: ['menuTaskAllTask'],
  },
  {
    key: 'task.goal',
    path: '/task/goal',
    exact: true,
    needLogin: true,
    component: GoalPage,
    authPoints: ['menuTaskGoal'],
  },
  {
    key: 'task.follow.record',
    path: '/task/follow-record',
    exact: true,
    needLogin: true,
    component: FollowRecordPage,
    authPoints: ['menuTaskFollowRecord'],
  },
  {
    key: 'task.quality.inspection',
    path: '/task/quality-inspection',
    exact: true,
    needLogin: true,
    component: QualityInspectionListPage,
    authPoints: ['menuTaskCallEvaluateR', 'menuTaskCallEvaluateU', 'menuTaskCallKeyWordsR'],
  },
  {
    key: 'task.quality.inspection.detail',
    path: '/task/quality-inspection-detail',
    exact: true,
    needLogin: true,
    component: QualityInspectionDetailPage as ComponentType,
    authPoints: ['menuTaskCallEvaluateU', 'menuTaskCallEvaluateR'],
  },
  {
    key: 'task.score.detail',
    path: '/task/score-detail',
    exact: true,
    needLogin: true,
    component: ScoreDetailPage,
    authPoints: ['menuTaskCallScoreR'],
  },
  {
    key: 'task.call.record',
    path: '/task/call-record',
    exact: true,
    needLogin: true,
    component: CallRecordPage,
    authPoints: ['menuTaskCallRecord'],
  },
  {
    key: 'task.sms.record',
    path: '/task/sms-record',
    exact: true,
    needLogin: true,
    component: SMSRecordPage,
    authPoints: ['menuTaskSmsRecord'],
  },
  {
    key: 'task.retain.record',
    path: '/task/retain-record',
    exact: true,
    needLogin: true,
    component: RetainRecordPage,
    authPoints: ['menuTaskRetainRecord'],
  },
  {
    key: 'customer.detail',
    path: '/customer/:customerId',
    exact: true,
    needLogin: true,
    // standalone: true,
    component: lazy(() => import('@pages/customer/detail')),
    authPoints: ['menuCustomerDetail'],
  },
  {
    key: 'customer.opportunity',
    path: '/customers/opportunity',
    exact: true,
    needLogin: true,
    component: SaleOpportunityPage,
    authPoints: ['menuCustomerOpportunity'],
  },
  {
    key: 'customer.transfer',
    path: '/customers/transfer',
    exact: true,
    needLogin: true,
    component: CustomerAlterPage,
    authPoints: ['menuCustomerTransfer'],
  },
  {
    key: 'customer.scrm.flow.log',
    path: '/customers/scrm-flow-log',
    exact: true,
    needLogin: true,
    component: ScrmFlowLogPage,
    authPoints: ['menuCustomerFlowLog'],
  },
  {
    key: 'customer.link.log',
    path: '/customers/link-log',
    exact: true,
    needLogin: true,
    component: LinkLogPage,
    authPoints: ['menuCustomerLinkLog'],
  },
  {
    key: 'customer.order.detail',
    path: '/customers/order-detail',
    exact: true,
    needLogin: true,
    component: OrderDetailPage,
    authPoints: ['menuCustomerOrderDetail'],
  },
  {
    key: 'customer.assign.record',
    path: '/customers/assign-record',
    exact: true,
    needLogin: true,
    component: CustomerAssignRecordPage,
    authPoints: ['menuCustomerAssignRecord'],
  },
  {
    key: 'customer.conflict.record',
    path: '/customers/conflict-record',
    exact: true,
    needLogin: true,
    component: ConflictRecordPage,
    authPoints: ['menuCustomerConflictRecord'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'customer.investigate',
    path: '/customers/investigate',
    exact: true,
    needLogin: true,
    component: CustomerPoolPage,
    authPoints: ['menuCustomerInvestigate'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'customer.linked',
    path: '/customers/linked',
    exact: true,
    needLogin: true,
    component: CustomerPoolPage,
    authPoints: ['menuCustomerLinked'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'customer.resource',
    path: '/customers/resource',
    exact: true,
    needLogin: true,
    component: CustomerResourcePage,
    authPoints: ['menuCustomerResource'],
  },
  {
    key: 'customer.pure',
    path: '/customers/pure-pool',
    exact: true,
    needLogin: true,
    component: CustomerResourcePage,
    authPoints: ['menuCustomerPurePool'],
  },
  {
    key: 'customer.company.pool',
    path: '/customers/company-pool',
    exact: true,
    needLogin: true,
    component: CustomerPoolPage,
    authPoints: ['menuCustomerCompanyPool'],
  },
  {
    key: 'customer.trans.temp',
    path: '/customers/trans-temp',
    exact: true,
    needLogin: true,
    component: CustomerTransTempPage,
    authPoints: ['menuCustomerTransTemp'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'customer.unbind',
    path: '/customers/unbind',
    exact: true,
    needLogin: true,
    component: CustomerUnbindPage,
    authPoints: ['menuUnbind'],
    belongModule: [moduleId.REBATE_ID],
  },
  {
    key: 'customer.bind-failure',
    path: '/customers/bind-failure',
    exact: true,
    needLogin: true,
    component: CustomerBindFailurePage,
    authPoints: ['menuBindFailure'],
    belongModule: [moduleId.REBATE_ID],
  },
  {
    key: 'config.organization',
    path: '/config/organization',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/config/organization')),
    authPoints: ['menuConfigOrganization'],
  },
  {
    key: 'config.my.apply',
    path: '/config/my-apply',
    exact: true,
    needLogin: true,
    component: MyApplyPage,
  },
  {
    key: 'config.tccc',
    path: '/config/tccc',
    exact: true,
    needLogin: true,
    component: CallOutCutInPage,
    authPoints: ['menuConfigTCCC'],
  },
  {
    key: 'config.lead.rule',
    path: '/config/lead-rule',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/config/lead-rule')),
    authPoints: ['menuConfigLeadRule'],
  },
  {
    key: 'config.tags',
    path: '/config/tags',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/config/tags')),
    authPoints: ['menuConfigTags'],
  },
  {
    key: 'config.rule.type',
    path: '/config/rule-type',
    exact: true,
    needLogin: true,
    component: ConfigRuleTypePage,
    authPoints: ['menuConfigRuleType'],
  },
  {
    key: 'config.sms',
    path: '/config/sms',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/config/sms')),
    authPoints: ['menuConfigSms'],
  },
  {
    key: 'config.follow',
    path: '/config/follow',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/config/follow')),
    authPoints: ['menuConfigFollow'],
  },
  {
    key: 'config.task.log',
    path: '/config/task-log',
    exact: true,
    needLogin: true,
    component: ConfigTaskLogPage,
    authPoints: ['menuConfigTaskLog'],
  },
  {
    key: 'config.retain.resource.list',
    path: '/config/retain-resource-list',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/config/add-retain-resource/AddReatinResource')),
    authPoints: ['menuConfigRetainResourceList'],
  },
  {
    key: 'config.retain.resource.edit',
    path: '/config/retain-resource-edit/:id',
    exact: true,
    needLogin: true,
    component: lazy(() => import('@pages/config/follow')),
    authPoints: ['menuConfigRetainResourceEdit'],
  },
  {
    key: 'config.batch.info',
    path: '/config/batch-info',
    exact: true,
    needLogin: true,
    component: ConfigBatchInfoPage,
    authPoints: ['menuConfigBatchInfo'],
  },
  {
    key: 'config.operation.Log',
    path: '/config/operation-log',
    exact: true,
    needLogin: true,
    component: ConfigOperationLogPage,
    authPoints: ['menuConfigOperationLog'],
  },
  {
    key: 'lead.hotline',
    path: '/lead/hotline',
    exact: true,
    needLogin: true,
    component: LeadHotlinePage,
    authPoints: ['menuLeadHotline'],
  },
  {
    key: 'lead.hotline.clueCollect.400',
    path: '/lead/hotline/clue-collect/400',
    exact: true,
    needLogin: true,
    component: LeadHotlineClueCollect400Page,
    authPoints: ['menuLeadHotlineClueCollect400'],
  },
  {
    key: 'lead.hotline.clueCollect.im',
    path: '/lead/hotline/clue-collect/im',
    exact: true,
    needLogin: true,
    component: LeadHotlineClueCollectIMPage,
    authPoints: ['menuLeadHotlineClueCollectIM'],
  },
  {
    key: 'lead.hotline.callIm',
    path: '/lead/hotline/call-im',
    exact: true,
    needLogin: true,
    component: LeadHotlineCallIMPage,
    authPoints: ['menuLeadHotlineCallIM'],
  },
  {
    key: 'lead.list.unclaimed',
    path: '/lead/list/unclaimed',
    exact: true,
    needLogin: true,
    component: LeadListPage,
    authPoints: ['menuLeadListUnclaimed'],
  },
  {
    key: 'lead.list.claimed',
    path: '/lead/list/claimed',
    exact: true,
    needLogin: true,
    component: LeadListPage,
    authPoints: ['menuLeadListClaimed'],
  },
  {
    key: 'lead.list.discard',
    path: '/lead/list/discard',
    exact: true,
    needLogin: true,
    component: LeadListPage,
    authPoints: ['menuLeadListDiscard'],
  },
  {
    key: 'lead.add',
    path: '/lead/add',
    exact: true,
    needLogin: true,
    component: LeadAddPage,
    authPoints: ['menuLeadAdd'],
  },
  {
    key: 'lead.circulate.record',
    path: '/lead/circulate-record',
    exact: true,
    needLogin: true,
    component: LeadCirculateRecordPage,
    authPoints: ['menuLeadCirculateRecord'],
  },
  {
    key: 'report.agent.task',
    path: '/report/agent-task',
    exact: true,
    needLogin: true,
    component: AgentTask,
    authPoints: ['menuReportAgentTask'],
  },
  {
    key: 'config.task.robot',
    path: '/config/robot-task-log',
    exact: true,
    needLogin: true,
    component: ConfigRobotTaskLogPage,
    authPoints: ['robotOutBound'],
  },
  {
    key: 'ask.call.robot',
    path: '/task/robot-call-record',
    exact: true,
    needLogin: true,
    component: RobotCallRecordPage,
    authPoints: ['robotOutBound'],
  },
  {
    key: 'task.call.meeting',
    path: '/task/meeting-record',
    exact: true,
    needLogin: true,
    component: MeetingRecordPage,
    authPoints: ['menuMeetingRecord'],
  },
  {
    key: 'customer.saas.config',
    path: '/customers/saas-config',
    needLogin: true,
    exact: true,
    component: CopBuy,
    authPoints: ['menuSaasConfig'],
  },
  {
    key: 'not-found',
    component: NotFoundPage,
  },
]

export default routes
