// 用户操作模块
import React, { useCallback, useMemo, useState } from 'react'
import { UpOutlined } from '@ant-design/icons'
import { Dropdown, Modal, Alert, Row, Col, message, Menu as AntdMenu, Avatar, Tooltip } from 'antd'
import { CommonSelector, getThumbAvatar } from '@components'
import { salesNameNoVirtual } from '../CommonSelector/configs'
import { get, set, remove } from 'js-cookie'
import { ORIGIN_TCC_TOKEN } from '@configs'
import { simulatedLogin, simulatedLogOut } from '@services/session'
import './style'
import store from '@store'
import { isEmptyWithCustom } from '@library'
import SwitchModule from './SwitchModule'
import { getStaffName } from '../../library/utils'

export function User () {
  const { userInfo, logout } = store.useSession()
  const { authPointMap } = store.useGlobal()

  /* 切换模块 */
  const [switchModuleModel, setSwitchModuleModel] = useState(false)

  /* 模拟用户 */
  const [switchUserModel, setSwitchUserModel] = useState(false)
  const [simulatedUser, setSimulatedUser] = useState<string>()
  const submitSwitchUser = useCallback(async () => {
    const [res, err] = await simulatedLogin({ salesName: simulatedUser })
    if (err) {
      message.error(`模拟用户失败${err.message}`)
      return
    }
    setSwitchUserModel(false)
    set(ORIGIN_TCC_TOKEN, res?.originTccToken ?? '') // 当切换请求成功时，将此自动保存起来，之后模拟状态的每次请求中，header都要有此字段
    message.success('模拟用户成功，请等待页面自动刷新')
    setTimeout(() => window.location.reload(), 1000)
  }, [simulatedUser])
  const switchBack = useCallback(async () => {
    const [, err] = await simulatedLogOut()
    if (err) {
      message.error(`退出失败${err.message}`)
      return
    }
    remove(ORIGIN_TCC_TOKEN)
    message.success('退出模拟成功，请等待页面自动刷新')
    setTimeout(() => window.location.reload(), 1000)
  }, [])

  /* 弹出菜单 */
  const atSimOtherUserStatus = useMemo(() => !isEmptyWithCustom(get(ORIGIN_TCC_TOKEN)), []) // 当存在时认为处于模拟其他用户的状态
  const onClickUserMenu = useCallback(async (key: string) => {
    if (key === 'SWITCH_TO') {
      setSwitchUserModel(true)
    }
    if (key === 'SWITCH_BACK') {
      await switchBack()
    }
    if (key === 'SIGN_OUT') {
      await logout()
    }
    if (key === 'SWITCH_MODULE') {
      setSwitchModuleModel(true)
    }
  }, [])

  const userMenu = (
    <AntdMenu onClick={param => onClickUserMenu(param?.key)} className="mb20">
      {authPointMap.switchModule?.result && <AntdMenu.Item key="SWITCH_MODULE">切换其它模块</AntdMenu.Item>}
      {(!atSimOtherUserStatus && authPointMap.simulatedUser?.result) && (<AntdMenu.Item key="SWITCH_TO">模拟其他用户</AntdMenu.Item>)}
      {atSimOtherUserStatus && <AntdMenu.Item key="SWITCH_BACK">退出模拟用户</AntdMenu.Item>}
      <AntdMenu.Item key="SIGN_OUT">退出登录</AntdMenu.Item>
    </AntdMenu>
  )
  return (
    <>
      <div className="user-info">
        <Tooltip title={getStaffName(userInfo.salesName, userInfo.salesNameCh)} placement="topLeft">
          <Avatar src={getThumbAvatar(userInfo.avatar)} />
        </Tooltip>
        <span className="user-name">
          {userInfo.salesNameCh}
        </span>
        <Dropdown overlay={userMenu} trigger={['click']} placement="top">
          <a className="ant-dropdown-link logout" onClick={e => e.preventDefault()}>
            <UpOutlined className="ml10" />
          </a>
        </Dropdown>
      </div>
      {switchModuleModel && <SwitchModule open onCancel={() => setSwitchModuleModel(false)} onSuccess={() => setSwitchModuleModel(false)} />}
      {switchUserModel && (
        <Modal
          open
          width={650}
          title="模拟用户"
          okButtonProps={{ disabled: isEmptyWithCustom(simulatedUser) }}
          onCancel={() => setSwitchUserModel(false)}
          onOk={() => submitSwitchUser()}
        >
          <Alert message="此功能可以让你切换不同的员工，从而体验在不同身份下的TCC功能是否正常，切换后你可以手动切换回本人身份。" type="info" showIcon />
          <Row className="mt15">
            <Col span={8}>请选择要切换的员工账号:</Col>
            <Col span={12}>
              <CommonSelector {...salesNameNoVirtual} value={simulatedUser} onChange={val => setSimulatedUser(val as string)} />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  )
}
