/**
 * useService hook
 * @author jasonelchen
 */
import { useState, useEffect, useRef } from 'react'
import { CommonError } from '@types'
import { cache, isFunction } from '@library'
const _prefix = 'USESERVICE-'

/**
 * 将普通的 Service Function 转换成 hook
 * @param {service}  service 请求方法
 * @param {...any[]} data    该 service 方法所需要的参数
 */
export function useService<T extends any, D extends any[]> (
  service: (...data: D) => Promise<[T, CommonError]>,
  ...data: D
) {
  const key = _prefix + `${service.name}${JSON.stringify(data)}`
  const [flag, setFlag] = useState(0)
  const [loading, setLoading] = useState(true)
  const [res, setRes] = useState((cache.get(key) || [null, null]) as unknown as [T, CommonError])
  // 添加请求标识符引用，用于跟踪最新的请求
  const requestIdRef = useRef(0)

  useEffect(() => {
    if (!isFunction(service)) return
    // loading
    setLoading(true)

    // 增加请求标识符
    const currentRequestId = ++requestIdRef.current

    ;(async () => {
      // 请求
      const res = await service(...data)

      // 只有当前请求是最新的请求时，才更新状态
      if (currentRequestId === requestIdRef.current) {
        setRes(res)
        cache.set(key, res)
        setLoading(false)
      }
    })()

    return () => {
      // 清理函数，可选
    }
  }, [...data, flag]) // 监听 data 变化，重新执行 effect

  // 某些时候，我们需要在数据不变更的情况下手动去触发请求
  // 可以调用该方法
  const forceRequest = () => setFlag(f => f + 1)

  return [loading, res[0], res[1], forceRequest] as const
}
